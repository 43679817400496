import { StoreModel } from './index'
import { action, Action, thunk, Thunk } from 'easy-peasy'
import GoogleClient from '@mortgage-pos/ui/services/GoogleClient'
import { graphQL } from '@mortgage-pos/ui/services/http'
import { getGraphQLString } from '@mortgage-pos/utils'
import { gql } from 'apollo-boost'
import incense from '@mortgage-pos/ui/incense'
import { UserLocation, StateResult } from '@mortgage-pos/types'

export interface AddressModel {
  isAutoCompletedAddress: boolean
  userLocation: UserLocation
  stateResult: StateResult
  autoCompleteAddressAnswers: Thunk<AddressModel, object, object, StoreModel>
  setIsAutoCompletedAddress: Action<AddressModel, boolean>
  setUserLocation: Action<AddressModel, object>
  getUserLocation: Thunk<AddressModel, null, object, StoreModel>
  getStateByZip: Thunk<AddressModel, string, object, StoreModel>
}

const address = (): AddressModel => {
  return {
    isAutoCompletedAddress: false,
    userLocation: {
      userLatitude: null,
      userLongitude: null,
    },
    stateResult: {
      state: null,
    },

    autoCompleteAddressAnswers: thunk(
      async (_, payload, { getStoreActions }) => {
        const addressPayload = Object.values(payload)[0]

        if (addressPayload) {
          const addressAnswers = await GoogleClient.getAddressDetails(
            addressPayload
          )

          const { street, apt, city, state, zipCode } = addressAnswers

          const { setAnswers } = getStoreActions().answers

          setAnswers({
            ...addressAnswers,
            realEstateOwned: [{ street, apt, city, state, zipCode }],
          })
        }
      }
    ),

    setIsAutoCompletedAddress: action((state, isAutoCompletedAddress) => {
      state.isAutoCompletedAddress = isAutoCompletedAddress
    }),

    setUserLocation: action((state, userLocation) => {
      state.userLocation = userLocation
    }),

    getUserLocation: thunk(
      async ({ setUserLocation }, __, { getStoreActions }) => {
        let userLocation
        try {
          userLocation = await graphQL({ query: getUserLocationQuery })
          setUserLocation(userLocation.data.data.getCohesionTipLocation)
        } catch (error) {
          incense(error)
            .details({
              name: 'GetUserLocationError',
              message: 'Failed to set latitude & longitude',
            })
            .sensitive({ userLocation })
            .error()
        }
      }
    ),

    getStateByZip: thunk(async (_, zipCode, { getStoreActions }) => {
      const { setAnswers } = getStoreActions().answers
      const { answer } = getStoreActions().questionnaire

      try {
        const {
          data: {
            data: { getAddressInfoByZipCode, errors },
          },
        } = await graphQL({
          query: getAddressInfoByZipQuery,
          variables: { zipCode: zipCode },
        })

        if (errors) {
          incense(errors)
            .details({
              name: 'getAddressByZip Error',
              message: 'graphQL error during getAddressInfoByZipCode Query',
            })
            .sensitive({
              zipCode,
            })
            .error()
          return
        }

        setAnswers({ state: getAddressInfoByZipCode.state, zipCode })
        await answer({
          state: getAddressInfoByZipCode.state,
          city: getAddressInfoByZipCode.city,
        })
      } catch (e) {
        incense(e)
          .details({
            name: 'onChange for ZipCode Input Error',
            message: 'Issue during try for getAddressInfoByZipCode Query',
          })
          .sensitive({
            zipCode,
          })
          .error()
      }
    }),
  }
}

export const getUserLocationQuery = getGraphQLString(gql`
  query getCohesionTipLocation {
    getCohesionTipLocation {
      userLatitude
      userLongitude
    }
  }
`)

const getAddressInfoByZipQuery = getGraphQLString(gql`
  query getAddressInfoByZipCode($zipCode: String!) {
    getAddressInfoByZipCode(zipCode: $zipCode) {
      state
      city
    }
  }
`)

export default address
