import { cheapUUID } from '@mortgage-pos/utils'

export const pricingAnswers = {
  ntl: {
    createAccount: [
      {
        email: `SkipToPricing.${cheapUUID()}@test.test`,
        password: 'Test123!@#',
      },
    ],
    apt: '',
    city: 'CROSSIANT',
    phone: '5555555555',
    state: 'CA',
    escrow: 'Yes',
    street: '9812 FEDORA AVE',
    zipCode: '93612',
    lastName: 'TESTCO',
    addresses: [
      {
        apt: '',
        city: 'CROSSIANT',
        type: 'current',
        state: 'CA',
        street: '9812 FEDORA AVE',
        zipCode: '93612',
        moveInDate: '2000-02-02',
      },
    ],
    firstName: 'COLE',
    moveInDate: '2000-02-02',
    rollInFees: 'Yes',
    'addresses+0': [
      {
        id: null,
        apt: '',
        city: '',
        type: 'current',
        state: '',
        street: '',
        zipCode: '',
        moveInDate: null,
      },
    ],
    'addresses+1': [
      {
        moveInDate: null,
        autoCompleteAddressFields: '',
      },
    ],
    'addresses+2': [
      {
        moveInDate: null,
        autoCompleteAddressFields: '',
      },
    ],
    dateOfBirth: '1990-02-02',
    forbearance: 'No',
    loanPurpose: 'Refinance',
    propertyUse: 'PrimaryResidence',
    demographics: [
      {
        sex: ['declineToAnswer'],
        race: ['declineToAnswer'],
        ethnicity: ['declineToAnswer'],
        language: ['declineToAnswer'],
        languageOtherDesc: '',
        asianSubtype: null,
        hispanicSubtype: null,
        asianOtherSubtypeDesc: '',
        pacificIslanderSubtype: null,
        americanIndianTribeDesc: '',
        hispanicOtherSubtypeDesc: '',
        pacificIslanderOtherSubtypeDesc: '',
      },
    ],
    homeBusiness: 'No',
    propertyType: 'SingleFamily',
    hasCoBorrower: 'No',
    incomeSources: [
      {
        startDate: '2000-02-02',
        currentJob: true,
        incomeType: 'retired',
        grossAnnualIncomeAmount: 1500000,
      },
    ],
    maritalStatus: 'Married',
    abadDisclosure: true,
    estimatedValue: 500000,
    expenseHOAdues: 10,
    haveDependents: 'No',
    tcpaDisclosure: true,
    isTakingCashOut: 'No',
    realEstateOwned: [
      {
        apt: '',
        city: 'CROSSIANT',
        state: 'CA',
        street: '9812 FEDORA AVE',
        zipCode: '93612',
        numberOfUnits: 1,
        propertyOwners: 'Primary',
      },
    ],
    additionalIncome: 'No',
    isCurrentAddress: 'Yes',
    otherLiabilities: 'No',
    remainingBalance: 300000,
    declarationLawsuit: 'No',
    expensePropertyTax: 150,
    hasResidedTwoYears: 'Yes',
    realEstateOwnedIds: [4740],
    declarationBankrupt: 'No',
    declarationMilitary: 'No',
    declarationNewCredit: 'No',
    declarationOwnership: 'No',
    expenseFirstMortgage: 1200,
    declarationForeclosed: 'No',
    expenseSecondMortgage: null,
    declarationFederalDebt: 'No',
    declarationEndorserNote: 'No',
    declarationCitizenStatus: 'USCitizen',
    declarationConveyedTitle: 'No',
    declarationSubjectToLien: 'No',
    expenseMortgageInsurance: 20,
    autoCompleteAddressFields: '',
    expenseHomeownerInsurance: 20,
    coBorrowerTwoYearsResidence: 'Yes',
    declarationForeclosedDirect: 'No',
    declarationPrimaryResidence: 'Yes',
    declarationDownPaymentBorrowed: 'No',
    declarationOutstandingJudgements: 'No',
    undisclosedBorrowedFundsIndicator: 'No',
    termsOfUsePrivacyPolicyAndElectronicConsentAgreement: true,
  },
  'pre-pricing-refinance': {
    createAccount: [
      {
        email: `SkipToPricing.${cheapUUID()}@test.test`,
        password: 'Test123!@#',
      },
    ],
    propertyType: 'SingleFamily',
    propertyUse: 'PrimaryResidence',
    apt: '',
    city: 'CROSSIANT',
    phone: '1111111111',
    state: 'TX',
    escrow: 'No',
    reoApt: '',
    reoZip: '77479',
    street: '527 HIGH MEADOWS DR',
    cashOut: 0,
    reoCity: 'CROSSIANT',
    zipCode: '77479',
    lastName: 'TESTCO',
    reoState: 'TX',
    addresses: [
      {
        id: 8245,
        apt: '',
        city: 'CROSSIANT',
        type: 'current',
        state: 'TX',
        street: '527 HIGH MEADOWS DR',
        zipCode: '77479',
      },
    ],
    firstName: 'COLE',
    reoStreet: '527 HIGH MEADOWS DR',
    refiReason: 'lowerPayment',
    rollInFees: 'Yes',
    dateOfBirth: '1980-01-01',
    forbearance: 'No',
    loanPurpose: 'Refinance',
    escrowRollIn: 'Yes',
    hasCoBorrower: 'No',
    incomeSources: [
      {
        startDate: '2000-02-02',
        currentJob: true,
        incomeType: 'retired',
        grossAnnualIncomeAmount: 1500000,
      },
    ],
    maritalStatus: 'Married',
    abadDisclosure: true,
    estimatedValue: 302800,
    isNtlQualified: true,
    tcpaDisclosure: true,
    isTakingCashOut: 'No',
    remainingBalance: 200000,
    autoCompleteAddressFields: '',
    termsOfUsePrivacyPolicyAndElectronicConsentAgreement: true,
  },
  'pre-pricing-purchase': {
    loanPurpose: 'Purchase',
    purchaseStage: 'contract',
    firstTimeHomeBuyer: 'Yes',
    autoCompleteAddressFields: '527 High Meadows Rd, Texas, USA',
    street: '527 High Meadows Dr',
    apt: '',
    city: 'Sugar Land',
    state: 'TX',
    zipCode: '77479',
    estimatedValue: 303200,
    propertyPurchasePrice: 400000,
    propertyDownPayment: 90000,
    propertyType: 'SingleFamily',
    propertyUse: 'PrimaryResidence',
    firstName: 'Cole',
    lastName: 'Testco',
    phone: '1234123412',
    abadDisclosure: true,
    tcpaDisclosure: true,
    addresses: [
      {
        type: 'current',
        street: '1234 yourein',
        apt: '',
        city: 'Sample',
        state: 'KY',
        zipCode: '67009',
      },
    ],
    hasResidedTwoYears: 'Yes',
    hasCoBorrower: 'No',
    grossAnnualIncome: 300000,
    monthlyDebtPayments: 2000,
    totalAssets: 50000,
    createAccount: [
      {
        email: `SkipToPricing.${cheapUUID()}@test.test`,
        password: 'Test123!@#',
      },
    ],
    termsOfUsePrivacyPolicyAndElectronicConsentAgreement: true,
  },
}

export default pricingAnswers
