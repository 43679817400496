import { StoreModel } from '.'
import { CookieName } from '@mortgage-pos/types'
import { environment } from '@mortgage-pos/ui/env'
import rollbar from '@mortgage-pos/ui/services/rollbar'
import incense from '@mortgage-pos/ui/services/error-handling/incense'
import { borrowerPortalRequest } from '@mortgage-pos/ui/services/http'
import { action, Action, thunk, Thunk } from 'easy-peasy'
import { CustomError } from '@mortgage-pos/error-handling'
import { borrowerPortalLoginUrl } from '@mortgage-pos/data'
import { deleteCookie, redirectTo } from '@mortgage-pos/utils'

export interface ApplicationInfo {
  applicationId: number
  applicationGuid: string
  bankrateLeadId?: string | null
  createdAt: Date
  completed: boolean
}
export interface AuthenticationModel {
  isCheckingAuthentication: boolean
  isAuthenticated: boolean
  applicationInfo: ApplicationInfo
  setIsCheckingAuthentication: Action<AuthenticationModel, boolean>
  setIsAuthenticated: Action<AuthenticationModel, boolean>
  setApplicationInfo: Action<AuthenticationModel, ApplicationInfo>
  checkAuthentication: Thunk<
    AuthenticationModel,
    boolean | void,
    null,
    StoreModel
  >
  borrowerPortalLogin: Thunk<AuthenticationModel, string, null, StoreModel>
  checkApplicationStatus: Thunk<AuthenticationModel, null, null, StoreModel>
  checkAccountByGuid: Thunk<
    AuthenticationModel,
    string,
    null,
    StoreModel,
    Promise<boolean>
  >
  logout: Thunk<AuthenticationModel, null, null, StoreModel>
}

const authentication = (): AuthenticationModel => ({
  isCheckingAuthentication: false,

  isAuthenticated: false,

  applicationInfo: null,

  setIsAuthenticated: action((state, payload) => {
    state.isAuthenticated = payload
  }),

  setIsCheckingAuthentication: action((state, payload) => {
    state.isCheckingAuthentication = payload
  }),

  setApplicationInfo: action((state, payload) => {
    state.applicationInfo = payload
  }),

  checkAuthentication: thunk(
    async (
      { setIsAuthenticated, setIsCheckingAuthentication },
      delayResponse
    ) => {
      setIsCheckingAuthentication(true)

      let response
      try {
        response = await borrowerPortalRequest('/check-authentication')
      } catch (e) {
        // Only set the isAuthenticated to false if the response is a 401
        if (e.response?.status === 401) {
          setIsAuthenticated(false)
          setIsCheckingAuthentication(false)
          return
        }

        // If the response is not a 401, report the error
        const errorMessage = 'Check authentication error'
        rollbar.error(errorMessage, new CustomError(errorMessage, e.message))
        setIsCheckingAuthentication(false)
        return
      }

      if (!delayResponse) {
        return _complete()
      }

      //delay to prevent flashing behavior when the loading gif is
      //quickly shown, due to fast connections
      setTimeout(_complete, 2000)

      function _complete() {
        if (response.status === 201) {
          setIsAuthenticated(true)
        }
        setIsCheckingAuthentication(false)
      }
    }
  ),

  borrowerPortalLogin: thunk(
    async (
      { checkAuthentication },
      accessToken,
      { getState, getStoreState }
    ) => {
      const { isAuthenticated } = getState()
      const { applicationId } = getStoreState().application

      if (accessToken && !isAuthenticated) {
        try {
          await borrowerPortalRequest(
            '/login',
            {},
            { Authorization: `Bearer ${accessToken}` }
          )
        } catch (error) {
          const response = error.response ? error.response.body : error

          const errorObject = new CustomError(
            'BorrowerPortalLoginError',
            'Failed to set session cookie'
          )

          const errorMetadata = {
            applicationId,
            sensitive: { error, response },
          }

          rollbar.error(errorObject, errorMetadata)
        }

        await checkAuthentication()
      }
    }
  ),

  checkApplicationStatus: thunk(async ({ setApplicationInfo }) => {
    let appStatusResponse
    try {
      appStatusResponse = await borrowerPortalRequest('/application-status')
    } catch (e) {
      rollbar.error('Failed to get application info by userId', e)
    }

    setApplicationInfo(appStatusResponse.data)
  }),

  checkAccountByGuid: thunk(async (_, applicationGuid): Promise<boolean> => {
    try {
      const response = await borrowerPortalRequest('/check-account', {
        applicationGuid,
      })

      return response?.data?.hasAccount
    } catch (e) {
      incense(e)
        .details({
          name: 'AuthenticationStoreError',
          message: 'error during checkAccountById',
        })
        .safe({
          applicationGuid,
        })
        .error()

      return false
    }
  }),

  logout: thunk(async ({ setIsAuthenticated }) => {
    await borrowerPortalRequest(`/logout`, {}, {}, 'post')
    deleteCookie(CookieName.AuthToken)
    setIsAuthenticated(false)
    if (
      environment.productName === 'sage' ||
      environment.productName === 'sage-home-loans'
    ) {
      redirectTo(borrowerPortalLoginUrl)
    }
  }),
})

export default authentication
