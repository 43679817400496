/* eslint-disable @typescript-eslint/no-explicit-any */
import Rollbar from 'rollbar'
import { environment } from '@mortgage-pos/ui/env'
import newRelic from '@mortgage-pos/ui/services/newRelic'
import { EncryptedSensitive } from '@mortgage-pos/types'
import { aggregateNewRelicErrors } from '@mortgage-pos/utils'
import CryptoClient from '@mortgage-pos/ui/services/CryptoClient'

import {
  decorateEncryptedPayload,
  stripSensitiveDataFromPayload,
} from '@mortgage-pos/rollbar-crypto'

const { appEnv } = environment

const checkIgnore = (
  isUncaught: boolean,
  args: Rollbar.LogArgument,
  payload: Record<string, any>
): boolean => {
  // captures bubbled up messages from window.onerror
  if (isUncaught) return false

  if (payload?.level === 'debug') {
    return true
  }
  if (
    payload.body.trace.exception.message.includes('Illegal invocation') &&
    payload.body.trace.frames.filename.includes(
      'https://cdn.cookielaw.org/scripttemplates'
    )
  ) {
    return true
  }
  if (aggregateNewRelicErrors(newRelic, payload)) return true
}

const ignoredMessages = [
  'ChunkLoadError',
  'Loading chunk',
  'Object Not Found Matching Id',
]

const client =
  appEnv !== 'local'
    ? new Rollbar({
        enabled: environment.rollbar.enabled === 'true',
        environment: `${environment.productName}-${environment.rollbar.environment}`,
        accessToken: environment.rollbar.token,
        captureUncaught: true,
        captureUnhandledRejections: true,
        autoInstrument: true,
        scrubTelemetryInputs: true,
        itemsPerMinute: 5,
        checkIgnore,
        ignoredMessages,
        payload: {
          context: 'mortgage-pos#alfie-ui',
          client: {
            javascript: {
              source_map_enabled: true,
              // Optionally have Rollbar guess which frames the error was thrown from
              // when the browser does not provide line and column numbers.
              guess_uncaught_frames: true,
              code_version: process.env.CIRCLE_SHA1,
            },
          },
        },
      })
    : (console as any)

if (appEnv !== 'local') {
  client.configure({
    transform: async (payload) => {
      let encryptedSensitive: EncryptedSensitive

      try {
        if (payload?.custom?.sensitive) {
          encryptedSensitive = await CryptoClient.encryptSensitiveData(
            payload.custom.sensitive
          )
        }

        newRelic.increment('tumbleweed.encryption_success')
      } catch (error) {
        newRelic.increment('tumbleweed.encryption_failure')
      }

      if (encryptedSensitive?.sensitive && encryptedSensitive?.sensitiveUuid) {
        const { sensitiveUuid } = encryptedSensitive
        decorateEncryptedPayload(sensitiveUuid, payload)
      }

      stripSensitiveDataFromPayload(payload)
    },
  })
}

;(window as any).rollbar = client

export default client
