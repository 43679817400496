import newRelic from '@mortgage-pos/ui/services/newRelic'
import incense from '@mortgage-pos/ui/incense'
import { BankrateRate } from '@mortgage-pos/types'
import { CustomError } from '@mortgage-pos/error-handling'
import { MortgageEstimate, ProductTerm } from '@mortgage-pos/types'

export class filterAndPositionOffers {
  private refiGoal: string
  private bankrateOffers: BankrateRate[]
  private currentMortgageDetails: MortgageEstimate
  private sortedOffers: BankrateRate[]
  private filteredOffersBasedOnRefiReason: BankrateRate[]
  private sortedOffersByHighestConvertingLender: BankrateRate[]

  constructor(
    refiGoal: string,
    bankrateOffers: BankrateRate[],
    currentMortgageDetails?: MortgageEstimate
  ) {
    this.refiGoal = refiGoal ?? ''
    this.bankrateOffers = bankrateOffers ?? []
    this.currentMortgageDetails = currentMortgageDetails ?? {}
    this.sortedOffers = this.sortBy8YearLoanCost()
    this.filteredOffersBasedOnRefiReason =
      this.filterOffersBasedOnRefiReason() ?? []
    this.sortedOffersByHighestConvertingLender =
      this.sortOffersByHighestConvertingLender()
  }

  private sortBy8YearLoanCost(): BankrateRate[] {
    return this.bankrateOffers.sort((offerA, offerB) => {
      return offerA.costOfLoan8Years - offerB.costOfLoan8Years
    })
  }

  private filterOffersBasedOnRefiReason(): BankrateRate[] {
    const currentMortgageDetails = this.currentMortgageDetails
    const refiGoal = this.refiGoal

    return this.sortedOffers.filter((offer) => {
      if (
        refiGoal === 'lowerInterestRate' &&
        currentMortgageDetails?.estimatedInterestRate
      ) {
        return offer.rate < Number(currentMortgageDetails.estimatedInterestRate)
      }
      if (
        (refiGoal === 'lowerPayment' || refiGoal === 'notSure') &&
        currentMortgageDetails?.paymentDueAmount
      ) {
        return (
          offer.principleAndInterestPayment <
          currentMortgageDetails.paymentDueAmount
        )
      }
      return true
    })
  }

  private sortOffersByHighestConvertingLender(): BankrateRate[] {
    newRelic.increment('br_compare_filtered_rates', [
      { key: 'refiGoal', value: this.refiGoal },
      {
        key: 'rates_count',
        value: this.filteredOffersBasedOnRefiReason?.length?.toString(),
      },
    ])

    const highestConvertingLenderOffers =
      this.filteredOffersBasedOnRefiReason.filter(
        (offer) => offer.bankrateAdvertiser?.id === 10689
      )

    const otherLenderOffers = this.filteredOffersBasedOnRefiReason.filter(
      (offer) => offer.bankrateAdvertiser?.id !== 10689
    )

    return [...highestConvertingLenderOffers, ...otherLenderOffers]
  }

  public getDisplayOffers(): BankrateRate[] {
    const displayOffers = []

    this.sortedOffersByHighestConvertingLender.every((offer) => {
      if (displayOffers.length === 2) return false

      if (
        displayOffers.length === 1 &&
        // second offer must be from a unique lender
        offer.bankrateAdvertiser?.id !==
          displayOffers?.[0].bankrateAdvertiser?.id
      ) {
        displayOffers.push(offer)
      }

      if (displayOffers.length === 0) {
        displayOffers.push(offer)
      }

      return true
    })

    displayOffers?.forEach((offer, index) => {
      newRelic.increment('br_compare_displayed_rates', [
        {
          key: 'lender',
          value: offer?.bankrateAdvertiser?.name,
        },
        {
          key: 'position',
          value: (index + 1).toString(),
        },
      ])
    })

    return displayOffers
  }

  public getCashOutOffers(): BankrateRate[] {
    const cashOutOffers = []

    this.sortedOffersByHighestConvertingLender.every((offer) => {
      if (cashOutOffers.length === 3) return false

      if (
        cashOutOffers.length >= 1 &&
        offer.bankrateAdvertiser?.id !==
          cashOutOffers?.[cashOutOffers.length - 1]?.bankrateAdvertiser?.id
      ) {
        cashOutOffers.push(offer)
      }
      if (cashOutOffers.length === 0) {
        cashOutOffers.push(offer)
      }
      return true
    })

    cashOutOffers?.forEach((offer, index) => {
      newRelic.increment('br_compare_cashout_rates', [
        {
          key: 'lender',
          value: offer?.bankrateAdvertiser?.name,
        },
        {
          key: 'position',
          value: (index + 1).toString(),
        },
      ])
    })

    return cashOutOffers
  }
}

export function determineLoanTerm(
  refiGoal: string,
  currentMortgageDetails: MortgageEstimate
) {
  const mortgageStateDate = currentMortgageDetails?.openedDate
  const mortgageMonths = currentMortgageDetails?.paymentScheduleMonthCount

  const remainingYearsLeftOnLoan = calculateRemainingYearsLeftOnLoan(
    mortgageStateDate,
    mortgageMonths
  )

  switch (refiGoal) {
    case 'cashOut':
      return [ProductTerm.F30]
    case 'fasterPayoff':
      return [ProductTerm.F15]
    case 'lowerPayment':
      if (remainingYearsLeftOnLoan <= 15) {
        return [ProductTerm.F15]
      } else {
        return [ProductTerm.F30]
      }
    case 'lowerInterestRate':
      if (remainingYearsLeftOnLoan <= 15) {
        return [ProductTerm.F15]
      } else {
        return [ProductTerm.F30]
      }
    case 'notSure':
    default:
      return [ProductTerm.F30]
  }
}

export function calculateRemainingYearsLeftOnLoan(
  openedDate: string,
  loanTermInMonths: number,
  refiGoal?: string
) {
  const mortgagePaidOffInYears =
    new Date().getFullYear() - new Date(openedDate)?.getFullYear()
  const loanTermInYears = loanTermInMonths / 12
  const remainingYears = loanTermInYears - mortgagePaidOffInYears

  if (remainingYears) {
    return remainingYears
  } else {
    const error = new CustomError(
      'BANKRATECOMPARE DetermineRemainingMortgageTermError',
      'Failed to determine remaining mortgage term'
    )

    incense(error)
      .details({
        name: 'DetermineRemainingMortgageTermError',
        message: 'Failed to determine remaining mortgage term',
      })
      .sensitive({
        openedDate: openedDate || 'Unknown',
        loanTermInMonths: loanTermInMonths || 'Unknown',
        refiGoal: refiGoal || 'Unknown',
      })
      .error()

    return 30
  }
}
