import { gql } from 'apollo-boost'
import { StoreModel } from './index'
import rollbar from '@mortgage-pos/ui/services/rollbar'
import newRelic from '@mortgage-pos/ui/services/newRelic'
import { graphQL } from '@mortgage-pos/ui/services/http'
import { getGraphQLString } from '@mortgage-pos/utils'
import { action, Action, thunk, Thunk } from 'easy-peasy'
import { LoanOfficerDisplayInfo } from '@mortgage-pos/types'

export interface LoanOfficerModel {
  loanOfficer: LoanOfficerDisplayInfo
  setLoanOfficer: Action<LoanOfficerModel, LoanOfficerDisplayInfo>
  getLoanOfficer: Thunk<LoanOfficerModel, null, object, StoreModel>
}

const loanOfficer = (): LoanOfficerModel => ({
  loanOfficer: null,

  setLoanOfficer: action((state, payload) => {
    state.loanOfficer = payload
  }),

  getLoanOfficer: thunk(async ({ setLoanOfficer }, __, { getStoreState }) => {
    const { bankrateLeadId } = getStoreState().questionnaire
    const { applicationId } = getStoreState().application
    const errorMsg = 'Get loan officer request failed'

    if (!bankrateLeadId && !applicationId) {
      return
    }

    let response

    try {
      response = await graphQL({
        query: getLoanOfficerQuery,
        variables: {
          bankrateLeadId,
        },
      })
    } catch (e) {
      rollbar.error(errorMsg, e)
      newRelic.increment('get_loan_officer.error')
      return
    }

    if (response.data.errors && response.data.errors.length) {
      rollbar.error(errorMsg, response.data.errors)
      newRelic.increment('get_loan_officer.error')
    }

    const loanOfficer: LoanOfficerDisplayInfo =
      response.data.data.getLoanOfficer

    if (!loanOfficer) {
      newRelic.increment('get_loan_officer.not_found')
      return
    }

    setLoanOfficer(loanOfficer)
    newRelic.increment('get_loan_officer.success', [
      { key: 'name', value: loanOfficer.name },
    ])
  }),
})

export default loanOfficer

export const getLoanOfficerQuery = getGraphQLString(gql`
  query getLoanOfficer($bankrateLeadId: String) {
    getLoanOfficer(bankrateLeadId: $bankrateLeadId) {
      name
      phone
      nmls
      headshot
      email
      calendly_link
    }
  }
`)
