import { EventTag } from '@mortgage-pos/types'

type NewRelicMessageAndTag = {
  message: string
  tags?: EventTag[]
}

// error messages implicitly surfaced from the client
enum ImplicitErrorKeys {
  NetworkError = 'Network Error',
  LDNetworkError = 'network error (Error)',
  RequestAborted = 'Request aborted',
  TimeoutExceeded = 'timeout exceeded',
}

// map New Relic messages to ignored rollbar errors
const newRelicRollbarErrorMap: Record<
  ImplicitErrorKeys,
  NewRelicMessageAndTag
> = {
  [ImplicitErrorKeys.NetworkError]: {
    message: 'network_error',
    tags: [{ key: 'type', value: 'general' }],
  },
  [ImplicitErrorKeys.LDNetworkError]: {
    message: 'network_error',
    tags: [{ key: 'type', value: 'launch_darkly' }],
  },
  [ImplicitErrorKeys.RequestAborted]: {
    message: 'network_error',
    tags: [{ key: 'type', value: 'request_aborted' }],
  },
  [ImplicitErrorKeys.TimeoutExceeded]: {
    message: 'network_error',
    tags: [{ key: 'type', value: 'timeout_exceeded' }],
  },
}

interface NewRelicParam {
  increment: (arg0: string, arg1: any) => {}
}

// Payload type: https://explorer.docs.rollbar.com/#operation/create-item
export const aggregateNewRelicErrors = (
  newRelic: NewRelicParam,
  payload: Record<string, any>
): boolean => {
  const message =
    payload?.trace?.exception?.message ??
    payload?.trace?.exception?.description ??
    payload?.body?.trace?.exception?.message ??
    payload?.body?.trace?.exception?.description

  if (!message) return false

  const messageTrimmed = message?.trim()

  if (Object.keys(newRelicRollbarErrorMap).includes(messageTrimmed)) {
    newRelic.increment(
      newRelicRollbarErrorMap[messageTrimmed].message,
      newRelicRollbarErrorMap[messageTrimmed].tags
    )

    return true
  }
  return false
}
