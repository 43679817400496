import { gql } from 'apollo-boost'
import { StoreModel } from './index'
import { graphQL } from '@mortgage-pos/ui/services/http'
import { environment } from '@mortgage-pos/ui/env'
import { alfalfaUtils } from '@mortgage-pos/utils'
import { getGraphQLString } from '@mortgage-pos/utils'
import {
  action,
  Action,
  thunk,
  Thunk,
  thunkOn,
  ThunkOn,
  computed,
  Computed,
} from 'easy-peasy'

import {
  AlfalfaInboundEvent,
  AlfalfaOutboundEvent,
  AlfalfaAcctPageEvent,
  AlfalfaAnswerSetsEvent,
  AlfalfaSaveConfigEvent,
  AlfalfaFetchConfigEvent,
  AlfalfaInitResponseEvent,
  AlfalfaExportAnswersEvent,
  AlfalfaSaveAnswerSetOutcomeEvent,
} from '@mortgage-pos/types'

// Ensures that we map an enum'd event type to an existing Alfalfa action
type AlfalfaKey = keyof AlfalfaChromeExtModel
type EventToActionMap = Partial<Record<AlfalfaOutboundEvent, AlfalfaKey>>

export interface AlfalfaChromeExtModel {
  isInTestingMode: boolean
  eventTypeActionMap: EventToActionMap
  setIsInTestingMode: Action<AlfalfaChromeExtModel, boolean>
  logAlfalfaEvents: Action<AlfalfaChromeExtModel>
  combinedEventTypeActionMap: Computed<
    AlfalfaChromeExtModel,
    Partial<Record<AlfalfaOutboundEvent, string>>,
    StoreModel
  >
  handleAlfalfaEvent: Thunk<
    AlfalfaChromeExtModel,
    MessageEvent,
    object,
    StoreModel
  >
  handleInitRequest: Thunk<
    AlfalfaChromeExtModel,
    MessageEvent,
    object,
    StoreModel
  >
  forwardAnswerSets: Thunk<AlfalfaChromeExtModel, null, object, StoreModel>
  saveAnswers: Thunk<AlfalfaChromeExtModel, MessageEvent, object, StoreModel>
  exportAnswers: Thunk<AlfalfaChromeExtModel, MessageEvent, object, StoreModel>
  saveAnswerSet: Thunk<AlfalfaChromeExtModel, MessageEvent>
  triggerAutoAcctSignUp: ThunkOn<AlfalfaChromeExtModel, null, StoreModel>
}

const origin = window.location.origin

const alfalfaChromeExt = (): AlfalfaChromeExtModel => {
  return {
    isInTestingMode: false,

    eventTypeActionMap: {
      [AlfalfaOutboundEvent.InitRequest]: 'handleInitRequest',
      [AlfalfaOutboundEvent.SaveAnswers]: 'saveAnswers',
      [AlfalfaOutboundEvent.ImportAnswers]: 'exportAnswers',
      [AlfalfaOutboundEvent.SaveAnswerSet]: 'saveAnswerSet',
    },

    setIsInTestingMode: action((state, isInTestingMode) => {
      state.isInTestingMode = isInTestingMode
    }),

    logAlfalfaEvents: action(() => {
      window.dispatchEvent(new CustomEvent('logAlfalfaEvents'))
    }),

    combinedEventTypeActionMap: computed(
      [
        (_, storeState) =>
          storeState.alfalfaExtendedFeatures.extendedEventTypeActionMap,
        (state) => state.eventTypeActionMap,
      ],
      (extendedEventTypeActionMap, eventTypeActionMap) => {
        return { ...eventTypeActionMap, ...extendedEventTypeActionMap }
      }
    ),

    handleAlfalfaEvent: thunk(
      (actions, event, { getState, getStoreState, getStoreActions }) => {
        try {
          // Security purposes: only handle same-origin messages
          if (event.origin !== origin) {
            throw new Error('Access denied')
          }

          const { combinedEventTypeActionMap } = getState()
          const eventType = event.data?.type as AlfalfaInboundEvent

          const { extendedEventTypeActionMap } =
            getStoreState().alfalfaExtendedFeatures

          const actionName = combinedEventTypeActionMap[eventType]

          if (!actionName) return

          if (extendedEventTypeActionMap?.[eventType]) {
            const extendedAlfalfaActions =
              getStoreActions().alfalfaExtendedFeatures

            extendedAlfalfaActions?.[actionName](event)
            return
          }

          actions[actionName](event)
        } catch (err) {
          console.error('Alfalfa Error: handleAlfalfaEvent', err)
          console.log({ errorMetadata: { event: event.data } })
        }
      }
    ),

    handleInitRequest: thunk(
      ({ forwardAnswerSets, setIsInTestingMode }, event, { getStoreState }) => {
        try {
          const { slug } = getStoreState().questionnaire
          const pluginConfiguration = alfalfaUtils.getConfiguration()

          // Enable testing mode when using Alfalfa
          setIsInTestingMode(true)

          window.postMessage(
            {
              type: AlfalfaOutboundEvent.SaveConfigReq,
              config: { isTestingMode: true },
            } as AlfalfaSaveConfigEvent,
            origin
          )

          window.postMessage(
            {
              type: AlfalfaInboundEvent.FetchConfig,
              config: { ...pluginConfiguration, ...{ isTestingMode: true } },
            } as AlfalfaFetchConfigEvent,
            origin
          )

          window.postMessage(
            {
              type: AlfalfaInboundEvent.InitResponse,
              questionnaireSlug: slug,
              productName: environment.productName,
            } as AlfalfaInitResponseEvent,
            origin
          )

          forwardAnswerSets()
        } catch (err) {
          console.error('Alfalfa Error: handleInitRequest', err)
          console.log({ errorMetadata: { event: event.data } })
        }
      }
    ),

    forwardAnswerSets: thunk(async (_actions, _, { getStoreState }) => {
      const { slug: questionnaireSlug } = getStoreState().questionnaire

      try {
        const response = await graphQL({
          query: answerSetQueries.getAnswerSets,
          variables: { questionnaireSlug },
        })

        const answerSets = response.data.data.getAnswerSets

        const event: AlfalfaAnswerSetsEvent = {
          type: AlfalfaInboundEvent.AnswerSets,
          answerSets,
        }

        window.postMessage(event, origin)
      } catch (err) {
        console.error('Alfalfa Error: forwardAnswerSets', err)
        console.log({ errorMetadata: { questionnaireSlug } })
      }
    }),

    saveAnswers: thunk((_, event, { getStoreActions }) => {
      try {
        const { setAnswers } = getStoreActions().answers

        setAnswers(event.data.answers)
      } catch (err) {
        console.error('Alfalfa Error: saveAnswers', err)
        console.log({ errorMetadata: { event: event.data } })
      }
    }),

    exportAnswers: thunk((_, event, { getStoreState }) => {
      try {
        const { answers } = getStoreState().answers

        const exportAnswersEvent: AlfalfaExportAnswersEvent = {
          type: AlfalfaInboundEvent.ExportAnswers,
          answers,
        }

        window.postMessage(exportAnswersEvent, origin)
      } catch (err) {
        console.error('Alfalfa Error: exportAnswers', err)
        console.log({ errorMetadata: { event: event.data } })
      }
    }),

    saveAnswerSet: thunk(async (_, event) => {
      try {
        await graphQL({
          query: answerSetQueries.saveAnswerSet,
          variables: { answerSet: event.data?.answerSet },
        })

        const successEvent: AlfalfaSaveAnswerSetOutcomeEvent = {
          type: AlfalfaInboundEvent.SaveAnswerSetOutcome,
          wasSuccessful: true,
        }

        window.postMessage(successEvent, origin)
      } catch (err) {
        const failureEvent: AlfalfaSaveAnswerSetOutcomeEvent = {
          type: AlfalfaInboundEvent.SaveAnswerSetOutcome,
          wasSuccessful: false,
          error: err,
        }

        window.postMessage(failureEvent, origin)
        console.error('Alfalfa Error: saveAnswerSet', err)
        console.log({ errorMetadata: { event: event.data } })
      }
    }),

    triggerAutoAcctSignUp: thunkOn(
      (_, storeActions) => storeActions.questionnaire.setPageIndex,
      async (_, __, { getStoreState }) => {
        const activePage = getStoreState().questionnaire?.active

        if (isCreateAccountPage(activePage?.questions)) {
          window.postMessage(
            {
              type: AlfalfaInboundEvent.AcctPage,
            } as AlfalfaAcctPageEvent,
            origin
          )
        }
      }
    ),
  }
}

export default alfalfaChromeExt

function isCreateAccountPage(questions: any[]): boolean {
  if (typeof questions?.some !== 'function') {
    return false
  }

  return questions.some((question) => {
    return question?.component === 'Question-CreateAccount'
  })
}

export const answerSetQueries = {
  getAnswerSets: getGraphQLString(gql`
    query getAnswerSets($questionnaireSlug: String!) {
      getAnswerSets(questionnaireSlug: $questionnaireSlug) {
        id
        product
        questionnaireSlug
        answerSetSlug
        answers
        description
      }
    }
  `),
  saveAnswerSet: getGraphQLString(gql`
    mutation saveAnswerSet($answerSet: GqlAnswerSetInput) {
      saveAnswerSet(answerSet: $answerSet)
    }
  `),
}
