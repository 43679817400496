import { StoreModel } from './index'
import { thunk, Thunk, Action, action } from 'easy-peasy'
import { v4 as uuidv4 } from 'uuid'
import { sendTagularEvent } from '@mortgage-pos/shared-frontend'
import { generateUserId, redactAll } from '@mortgage-pos/utils'
import { baseExternalIdData, formContext } from '@mortgage-pos/data'
import {
  EventName,
  ExternalIdType,
  FormBrand,
  FormName,
  IdentifyEvent,
  ProductViewedEvent,
  ProductClickedEvent,
} from '@mortgage-pos/types'
import ReactPixel from 'react-facebook-pixel'
import { environment } from '@mortgage-pos/ui/env'

if (environment.production) {
  ReactPixel.init('731659101060834', null, {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false,
  })
}

const getFormContext = () => {
  return {
    ...formContext,
    formName: FormName.AlfieApplication,
    formBrand: FormBrand.Sage,
  }
}

interface FieldInputtedPayload {
  questionName: string
  subTitle: string
  value: any
}

interface FormErroredPayload {
  errorDetails?: string
  errorMessage: string
  errorType: string
}

export interface ElementTrackingPayload {
  actionOutcome?: string
  elementType: string
  location?: string
  text?: string
  position?: string
  htmlId?: string
}

export interface TrackingModel {
  formId: string
  flowId: string
  setFlowID: Action<TrackingModel, string>
  identify: Thunk<TrackingModel, void, object, StoreModel>
  formStarted: Thunk<TrackingModel, void, object, StoreModel>
  formContinued: Thunk<TrackingModel, void, object, StoreModel>
  formSubmitted: Thunk<TrackingModel, void, object, StoreModel>
  formErrored: Thunk<TrackingModel, FormErroredPayload, object, StoreModel>
  fieldInputted: Thunk<TrackingModel, FieldInputtedPayload, object, StoreModel>
  elementClicked: Thunk<
    TrackingModel,
    ElementTrackingPayload,
    object,
    StoreModel
  >
  elementViewed: Thunk<
    TrackingModel,
    ElementTrackingPayload,
    object,
    StoreModel
  >
  productViewed: Thunk<TrackingModel, ProductViewedEvent, object, StoreModel>
  productClicked: Thunk<TrackingModel, ProductClickedEvent, object, StoreModel>
}

const tracking = (): TrackingModel => ({
  formId: uuidv4(),
  flowId: '0',
  setFlowID: action((state, payload) => {
    state.flowId = payload
  }),
  identify: thunk((_, __, { getStoreState }) => {
    const { bankrateLeadId } = getStoreState().questionnaire
    const { bankrateAnswers } = getStoreState().answers

    const event: IdentifyEvent = {
      userId: generateUserId(bankrateAnswers.email),
      traits: {
        address: {
          state: bankrateAnswers.state,
        },
        email: bankrateAnswers.email,
        firstName: bankrateAnswers.firstName,
        lastName: bankrateAnswers.lastName,
        phone: bankrateAnswers.phone,
      },
      externalIds: [
        {
          id: bankrateLeadId,
          type: ExternalIdType.Bankrate,
          ...baseExternalIdData,
        },
      ],
    }

    sendTagularEvent(EventName.Identify, event)
  }),

  formStarted: thunk((_, __, { getStoreState, getState }) => {
    const { active } = getStoreState().questionnaire
    const { formId, flowId } = getState()
    const event = {
      correlationId: formId,
      formContext: {
        ...getFormContext(),
        formVersion: flowId,
        formId,
      },
      stepContext: {
        stepId: formId,
        stepName: active.name,
        stepNumber: 0,
      },
    }

    if (environment.production) {
      ReactPixel.pageView() // For tracking page view
    }
    sendTagularEvent(EventName.FormStarted, event)
  }),

  formContinued: thunk((_, __, { getStoreState, getState }) => {
    const { active, pageIndex } = getStoreState().questionnaire
    const { formId, flowId } = getState()
    const event = {
      correlationId: formId,
      formContext: {
        ...getFormContext(),
        formVersion: flowId,
        formId,
      },
      stepContext: {
        stepId: formId,
        stepName: active.name,
        stepNumber: pageIndex,
      },
    }

    if (environment.production) {
      ReactPixel.pageView() // For tracking page view
    }
    sendTagularEvent(EventName.FormContinued, event)
  }),

  fieldInputted: thunk((_, payload, { getStoreState, getState }) => {
    const { active, pageIndex } = getStoreState().questionnaire
    const { questionName, subTitle, value } = payload
    const { formId, flowId } = getState()

    const redactedFields = {
      ...redactAll({ [questionName]: value }),
    }
    const event = {
      correlationId: formId,
      formContext: {
        ...getFormContext(),
        formVersion: flowId,
        formId,
      },
      stepContext: {
        stepId: formId,
        stepName: active?.name,
        stepNumber: pageIndex,
      },
      userInputField: {
        fieldType: active?.section ?? 'Unknown',
        fieldName: questionName,
        fieldLabel: subTitle,
        fieldValue: String(redactedFields[questionName]),
      },
    }

    sendTagularEvent(EventName.FieldInputted, event)
  }),

  formErrored: thunk(
    (
      _,
      { errorDetails = '', errorMessage, errorType },
      { getStoreState, getState }
    ) => {
      const { active, pageIndex } = getStoreState().questionnaire
      const { formId, flowId } = getState()
      const event = {
        formContext: {
          ...getFormContext(),
          formVersion: flowId,
          formId,
        },
        stepContext: {
          stepId: formId,
          stepName: active?.name,
          stepNumber: pageIndex,
        },
        errorDetails, //additional details
        errorMessage, // <error message shown to the user>,
        errorType, //<short description of error - aka "format error">,
      }

      sendTagularEvent(EventName.FormErrored, event)
    }
  ),

  formSubmitted: thunk((_, __, { getStoreState, getState }) => {
    const { pageIndex, fBLeadId } = getStoreState().questionnaire
    const { formId, flowId } = getState()
    const event = {
      correlationId: formId,
      formContext: {
        ...getFormContext(),
        formVersion: flowId,
        formId,
      },
      stepContext: {
        stepId: formId,
        stepName: 'rateSelection',
        stepNumber: pageIndex + 1, // increment one from last page in questionnaire
      },
    }

    if (environment.production && fBLeadId) {
      ReactPixel.track('Lead', event) // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
      ReactPixel.track('SubmitApplication', event)
    }
    sendTagularEvent(EventName.FormSubmitted, event)
  }),

  elementClicked: thunk((_, payload, { getStoreState, getState }) => {
    const {
      actionOutcome = '',
      elementType = '',
      location = '',
      text = '',
    } = payload

    const { formId } = getState()

    const event = {
      correlationId: formId,
      webElement: {
        elementType,
        location,
        text,
      },
      actionOutcome,
    }

    sendTagularEvent(EventName.ElementClicked, event)
  }),

  elementViewed: thunk((_, payload, { getStoreState, getState }) => {
    const {
      position = '',
      elementType = '',
      location = '',
      text = '',
      htmlId = '',
    } = payload

    const event = {
      webElement: {
        elementType,
        location,
        text,
        position,
        htmlId,
      },
    }

    sendTagularEvent(EventName.ElementViewed, event)
  }),

  productViewed: thunk((_, productViewedEvent, { getState }) => {
    sendTagularEvent(EventName.ProductViewed, productViewedEvent)
  }),

  productClicked: thunk((_, productClickedEvent, { getState }) => {
    sendTagularEvent(EventName.ProductClicked, productClickedEvent)
  }),
})

export default tracking
