import React, { Suspense, lazy } from 'react'
import store from './app/store'
import ReactDOM from 'react-dom'
import Loading from './app/components/Loading'
import { StoreProvider } from 'easy-peasy'
import { Auth0Provider } from '@auth0/auth0-react'
import { setLandingUrl } from './app/utils/landingUrl'
import { environment } from './environments/environment'
import { initializeLD } from './app/services/featureFlags'
import { Auth0ApiScopes, Routes as CustomRoutes } from '@mortgage-pos/types'
import {
  Route,
  Routes,
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom'
import { RedirectRoute } from '@mortgage-pos/ui/components/RedirectRoute'
import { importRetry } from './app/utils/importRetry'
import { history } from './app/history'

const Compare = lazy(() =>
  importRetry(() => import('@mortgage-pos/ui/components/compare/Compare'))
)

const AdvisorNetwork = lazy(() =>
  importRetry(
    () => import('@mortgage-pos/ui/components/advisorNetwork/AdvisorNetwork')
  )
)

const AppRouter = lazy(() => importRetry(() => import('./app/AppRouter')))

require('./app/services/rollbar')

initializeLD().catch(() => {})

setLandingUrl()

ReactDOM.render(
  <Auth0Provider
    domain={environment.auth0.domain}
    clientId={environment.auth0.clientId}
    redirectUri={window.location.origin}
    audience={environment.auth0.audience}
    scope={Auth0ApiScopes.ReadApplications}
  >
    <StoreProvider store={store}>
      <HistoryRouter history={history}>
        <Routes>
          <Route
            path="/compare"
            element={
              <Suspense fallback={<Loading fullPage={true} />}>
                <Compare />
              </Suspense>
            }
          />
          <Route
            path="/advisor-network"
            element={
              <Suspense fallback={<Loading fullPage={true} />}>
                <AdvisorNetwork />
              </Suspense>
            }
          />
          <Route
            path={`/${CustomRoutes.BASIC_APP}`}
            element={
              <RedirectRoute
                pathName={`/${CustomRoutes.APP_1003}`}
                withQuery
                withLogging
                referringRoute={`/${CustomRoutes.BASIC_APP}`}
              />
            }
          />
          <Route
            path={`/${CustomRoutes.APP_1003}/*`}
            element={
              <Suspense fallback={<Loading fullPage={true} />}>
                <AppRouter />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <RedirectRoute pathName={`/${CustomRoutes.APP_1003}`} withQuery />
            }
          />
        </Routes>
      </HistoryRouter>
    </StoreProvider>
  </Auth0Provider>,
  document.getElementById('root')
)
