/* eslint-disable no-throw-literal */
import { isValid, subYears, subMonths } from 'date-fns'
import { states, State } from '../../../../../db/data/states'
import {
  sortDates,
  isDateXYearsOld,
  formatNumberToDollarAmount,
} from '@mortgage-pos/utils'
import ApplicationClient from '@mortgage-pos/ui/services/ApplicationClient'
import { toCurrency } from './stringFormat'

import {
  ReoEntry,
  LoanPurpose,
  allowedReoOwnerTypes,
} from '@mortgage-pos/types'

export const customPageValidations = [
  'remainingBalanceMin',
  'cashOutMin',
  'hasTwoYearsResidence',
  'coBorrowerHasTwoYearsResidence',
  'hasTwoYearsEmployment',
  'coBorrowerHasTwoYearsEmployment',
  'reoAllOrNothing',
  'maxCashout',
  'maxCashoutAlfie',
  'verifyWithLastFourPhone',
] as const

export interface ResidenceValidatorKeys {
  addressGroup: 'addresses' | 'coBorrowerAddresses'
  moveInDate: 'moveInDate' | 'coBorrowerMoveInDate'
  isMainProperty: 'isCurrentAddress' | 'doesCoBorrowerHaveSameAddress'
  twoYears: 'hasResidedTwoYears' | 'coBorrowerTwoYearsResidence'
}

export type CustomPageValidation = typeof customPageValidations[number]

type ValidatePageConditionFn = (
  values: Record<string, any>,
  answers?: Record<string, any>
) => boolean | void | Promise<boolean | void>

type ValidatePageCondition = {
  [key in CustomPageValidation]: ValidatePageConditionFn
}

export const CUSTOM_PAGE_ERRORS = {
  TWO_YEARS_RESIDENCE:
    'Continue to add previous addresses until there is two years of residential history',
  TWO_YEARS_EMPLOYMENT:
    'Continue to add previous employers until there is two years of employment history',
  REO_REQUIRED:
    'You must enter at least ONE property that either you or your co-borrower own. Refinance loans require that you own the property you are refinancing',
  REO_COMPLETENESS:
    'You must complete ALL of the details for each real estate property that you own. Please recheck your info and try again as you may be missing info for one or more fields',
  REO_ZIP_REGEX:
    'You have one or more improperly formatted zip codes in your real estate properties. This can either be a zip code of the incorrect length (must be exactly 5 digits, no long form zip-codes allowed) or invalid characters such spaces, dashes or other non-numeric characters',
  REO_STATE_CODE:
    'You have entered an incorrect state. Please recheck your selection and make sure the name is entered correctly',
  REO_PROPERTY_OWNER:
    'You must choose one of the provided values for property owner. Make sure you do not type extra info into the field',
}

export type pageErrorKey = keyof typeof CUSTOM_PAGE_ERRORS

const primaryBorrowerKeys: ResidenceValidatorKeys = {
  addressGroup: 'addresses',
  moveInDate: 'moveInDate',
  isMainProperty: 'isCurrentAddress',
  twoYears: 'hasResidedTwoYears',
}

const coBorrowerKeys: ResidenceValidatorKeys = {
  addressGroup: 'coBorrowerAddresses',
  moveInDate: 'coBorrowerMoveInDate',
  isMainProperty: 'doesCoBorrowerHaveSameAddress',
  twoYears: 'coBorrowerTwoYearsResidence',
}

type EmploymentHistoryKey = 'incomeSources' | 'coBorrowerIncomeSources'

export const validatePageCondition: ValidatePageCondition = {
  remainingBalanceMin: ({
    // cashQuestion is the name of the bool that determines cash out or not
    values: { remainingBalance, cashQuestion },
  }) => {
    if (cashQuestion || remainingBalance >= 165000) return true
    throw {}
  },
  cashOutMin: ({ values: { remainingBalance, cashOut }, answers }) => {
    if ((remainingBalance || answers?.remainingBalance) + cashOut >= 165000) {
      return true
    }

    throw {}
  },
  hasTwoYearsResidence: residenceValidatorFactory(primaryBorrowerKeys),
  coBorrowerHasTwoYearsResidence: residenceValidatorFactory(coBorrowerKeys),
  hasTwoYearsEmployment: employHistoryValidatorFactory('incomeSources'),
  coBorrowerHasTwoYearsEmployment: employHistoryValidatorFactory(
    'coBorrowerIncomeSources'
  ),
  reoAllOrNothing: ({ values, answers }) => {
    const loanPurpose =
      answers.loanPurpose ?? (LoanPurpose.Refinance as LoanPurpose)

    let realEstateOwned = values.realEstateOwned as ReoEntry[]

    // Storyblok inserts an object with all empty string values even when not visible.
    // Filter the empty values object from the array before validating
    realEstateOwned = realEstateOwned.filter((reo) => !checkIsReoEmpty(reo))

    // Insert the first REO property to the array of REO
    // so that we can validate against all of the REO values together.
    if (values.reoStreet) {
      realEstateOwned.unshift({
        street: values.reoStreet,
        apt: values.reoApt,
        city: values.reoCity,
        state: values.reoState,
        zipCode: values.reoZip,
        propertyOwners: values.reoPropertyOwners,
      })
    }

    const entriesCount = Array.isArray(realEstateOwned)
      ? realEstateOwned.length
      : 0

    if (entriesCount === 0) {
      if (loanPurpose === LoanPurpose.Refinance) {
        throw { page: CUSTOM_PAGE_ERRORS.REO_REQUIRED }
      } else {
        // REO not req'd for purchase loans
        return true
      }
    }

    if (entriesCount === 1) {
      const reo = realEstateOwned[0]
      const isReoEmpty = checkIsReoEmpty(reo)

      if (loanPurpose === LoanPurpose.Refinance) {
        if (isReoEmpty) {
          throw { page: CUSTOM_PAGE_ERRORS.REO_REQUIRED }
        }

        return validateReo(reo)
      } else {
        // Must be either: completely empty or fully filled out for purchase
        return isReoEmpty || validateReo(realEstateOwned[0])
      }
    }

    return realEstateOwned.reduce(
      (_: boolean, reo: ReoEntry, index): boolean => {
        const isLastEntry = index === entriesCount - 1

        if (!isLastEntry) {
          return validateReo(reo)
        }

        return checkIsReoEmpty(reo) || validateReo(reo)
      },
      true
    )
  },
  //This logic is only catered for BR Compare flow.
  maxCashout: ({
    values: { estimatedValue, cashOut, refiReason, remainingBalance },
    answers,
  }) => {
    const loanAmount = answers.remainingBalance || remainingBalance
    const propertyValue = answers.estimatedValue || estimatedValue

    if (loanAmount && cashOut && propertyValue && refiReason === 'cashOut') {
      if ((loanAmount + cashOut) / propertyValue >= 0.8) {
        const maxCashoutAmount = calculateMaxCashoutAmount(
          cashOut,
          loanAmount,
          propertyValue
        )
        const dollarCashOutAmount =
          maxCashoutAmount > 0
            ? formatNumberToDollarAmount(maxCashoutAmount)
            : `-${formatNumberToDollarAmount(maxCashoutAmount)}`
        const error = `The cash out amount you request is not a guarantee. Most lenders like to see a loan-to-value (LTV) of 80% or less. Based on the information you’ve provided and for the use of this tool, we’ve calculated you could take up to ${dollarCashOutAmount} in cash out. Please enter an amount up to ${dollarCashOutAmount}.`
        throw {
          page: error,
        }
      }
    }
    return true
  },
  //this logic is specific to Alfie
  maxCashoutAlfie: ({ values: { cashOut }, answers }) => {
    const {
      estimatedValue,
      remainingBalance,
      propertyType,
      propertyUse,
      maxCashoutValue,
    } = answers

    if (cashOut > maxCashoutValue) {
      const dollarCashOutAmount =
        maxCashoutValue > 0
          ? toCurrency(maxCashoutValue)
          : `-${toCurrency(maxCashoutValue)}`
      const error = `The cash out amount you requested is not a guarantee. Most lenders like to see a loan-to-value (LTV) of 80% or less. Based on the information you’ve provided and for the use of this tool, we’ve calculated you could take up to ${dollarCashOutAmount} in cash out. Please enter an amount up to ${dollarCashOutAmount}.`
      throw {
        page: error,
      }
    }
    return true
  },
  verifyWithLastFourPhone: async ({
    values: { lastFourPhone, zipCodeVerify },
    answers,
  }) => {
    const applicationGuid = new URLSearchParams(window.location.search).get(
      'application-guid'
    )

    if (!applicationGuid || !lastFourPhone || !zipCodeVerify) {
      throw {
        page: "We couldn't verify your identity. Please reach out to your Loan Officer",
      }
    }

    const response = await ApplicationClient.verifyUserWithLastFourPhone(
      lastFourPhone,
      applicationGuid,
      zipCodeVerify
    )

    if (!response?.phoneVerified || !response?.zipCodeVerified) {
      throw {
        page: 'The phone number or zip code you provided does not match our records. Please try again.',
      }
    }

    return true
  },
}

function residenceValidatorFactory(keys: ResidenceValidatorKeys) {
  return ({ values, answers }, formikUtils) => {
    const isCoBorrower = keys.addressGroup === 'coBorrowerAddresses'

    // Dont validate co-borrower residences if there is no co-borrower
    if (isCoBorrower && values.hasCoBorrower === 'No') {
      return true
    }

    // subtract 2 years and 1 month from current
    const twentyFiveMonthsAgo: Date = subMonths(subYears(new Date(), 2), 1)
    const hasResidedCurrentAddressTwoYears: Date =
      values.hasResidedCurrentAddressTwoYears === 'Yes'
        ? twentyFiveMonthsAgo
        : new Date()

    const firstAddressGroup = values[`${keys.addressGroup}+0`]

    const moveInDateFromForm =
      firstAddressGroup &&
      firstAddressGroup.length > 0 &&
      (firstAddressGroup[0].moveInDate as Date)

    let repeatableAddresses = []
    const moveInDates: Array<Date> = []

    const addressGroupIndex = isCoBorrower ? 0 : 2
    const addressGroupKey = `${keys.addressGroup}+${addressGroupIndex}`

    repeatableAddresses = [...(values[addressGroupKey] || [])]
    moveInDates.push(moveInDateFromForm)

    if (values[keys.twoYears] === 'No') {
      repeatableAddresses.forEach(({ moveInDate }) => {
        if (moveInDate && isValid(new Date(moveInDate))) {
          moveInDates.push(moveInDate)
        }
      })
    }

    // Get earliest one move in date
    const sortedMoveInDates = sortDates([...moveInDates])
    const earliestMoveInDate = sortedMoveInDates[0]

    const hasTwoYearsHistory = isDateXYearsOld(earliestMoveInDate, 2)

    if (
      hasTwoYearsHistory &&
      values.hasResidedCurrentAddressTwoYears === 'Yes'
    ) {
      formikUtils.setFieldValue(
        keys.moveInDate,
        hasResidedCurrentAddressTwoYears
      )
    }

    formikUtils.setFieldValue(keys.twoYears, hasTwoYearsHistory ? 'Yes' : 'No')

    if (!hasTwoYearsHistory) {
      throw { page: CUSTOM_PAGE_ERRORS.TWO_YEARS_RESIDENCE }
    }

    return true
  }
}

function employHistoryValidatorFactory(key: EmploymentHistoryKey) {
  return ({ values }) => {
    if (!values[key]?.length) {
      return false
    }

    const employmentDates = values[key].map((v) => v.startDate)
    const sortedEmploymentDates = sortDates(employmentDates)
    const [earliestEmployment] = sortedEmploymentDates
    const hasTwoYearsEmploymentHistory = isDateXYearsOld(earliestEmployment, 2)

    if (!hasTwoYearsEmploymentHistory) {
      throw { page: CUSTOM_PAGE_ERRORS.TWO_YEARS_EMPLOYMENT }
    }

    return true
  }
}

export const requiredReoDetails = [
  'street',
  'city',
  'state',
  'zipCode',
  'propertyOwners',
]

function validateReo(reo: ReoEntry) {
  if (typeof reo !== 'object' || [null, undefined].includes(reo)) {
    throw { page: CUSTOM_PAGE_ERRORS.REO_COMPLETENESS }
  }

  for (let i = 0; i < requiredReoDetails.length; i++) {
    const reoDetailName = requiredReoDetails[i]
    const reoDetail = reo[reoDetailName]

    if (typeof reoDetail !== 'string' || reoDetail.length === 0) {
      throw { page: CUSTOM_PAGE_ERRORS.REO_COMPLETENESS }
    }
  }

  const zipRegex = /^[0-9]{5}$/

  if (!zipRegex.test(reo.zipCode)) {
    throw { page: CUSTOM_PAGE_ERRORS.REO_ZIP_REGEX }
  }

  if (!states.includes(reo.state.trim() as State)) {
    throw { page: CUSTOM_PAGE_ERRORS.REO_STATE_CODE }
  }

  if (!allowedReoOwnerTypes.includes(reo.propertyOwners as any)) {
    throw { page: CUSTOM_PAGE_ERRORS.REO_PROPERTY_OWNER }
  }

  return true
}

export function checkIsReoEmpty(reo: ReoEntry) {
  for (let i = 0; i < requiredReoDetails.length; i++) {
    const reoDetailName = requiredReoDetails[i]
    const reoDetail = reo[reoDetailName]

    if (![null, undefined, ''].includes(reoDetail)) {
      return false
    }
  }

  return true
}

export function calculateMaxCashoutAmount(
  cashOut,
  remainingBalance,
  estimatedValue
) {
  let ltv = (remainingBalance + cashOut) / estimatedValue

  let maxCashout = cashOut
  while (ltv >= 0.8) {
    maxCashout -= 100
    ltv = (remainingBalance + maxCashout) / estimatedValue
  }

  return maxCashout
}
