import newRelic from '@mortgage-pos/ui/services/newRelic'

// importRetry.js
export async function importRetry(
  importFn,
  retries = 2,
  interval = 1000,
  firstTry = true
) {
  try {
    const fn = await importFn()
    if (!firstTry) {
      newRelic.increment('lazy_load.retry_success')
    }
    return fn
  } catch (error) {
    if (retries) {
      await wait(interval)
      newRelic.increment('lazy_load.retry')
      return importRetry(importFn, retries - 1, interval, false)
    } else {
      newRelic.increment('lazy_load.retry_failure')
      throw new Error(error)
    }
  }
}

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
