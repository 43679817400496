import Line from '@mortgage-pos/ui/assets/bankrate/Vector.png'
import TimeIcon from '@mortgage-pos/ui/assets/bankrate/time.svg'
import Save from '@mortgage-pos/ui/assets/bankrate/SAVE_ICON.svg'
import MoneyIcon from '@mortgage-pos/ui/assets/bankrate/money.svg'
import UserCheckIcon from '@mortgage-pos/ui/assets/user-check-icon.png'
import FacebookIcon from '@mortgage-pos/ui/assets/bankrate/FACEBOOK.png'
import LinkedInIcon from '@mortgage-pos/ui/assets/bankrate/LINKEDIN.png'
import InstagramIcon from '@mortgage-pos/ui/assets/bankrate/INSTAGRAM.png'
import BankrateLoader from '@mortgage-pos/ui/assets/bankrate/Bankrate_Loader.gif'
import BankrateLogo from '@mortgage-pos/ui/assets/bankrate/bankrate-logo-white.svg'
import BankrateLogoBlack from '@mortgage-pos/ui/assets/bankrate/bankrate-logo-header.svg'
import UploadLogo from '@mortgage-pos/ui/assets/bankrate/br_download_button.svg'
import BRInsuranceHome from '@mortgage-pos/ui/assets/bankrate/br_insurance_home.svg'
import BRDocumentHome from '@mortgage-pos/ui/assets/bankrate/br_document_home.svg'
import BROnline from '@mortgage-pos/ui/assets/bankrate/br_online.svg'
import RightArrow from '@mortgage-pos/ui/assets/bankrate/BR_arrow_right.svg'
import BrXComputer from '@mortgage-pos/ui/assets/bankrate/BR_X_COMPUTER.svg'
import ValuePropMoney from '@mortgage-pos/ui/assets/bankrate/value_prop_money.svg'
import ValuePropInfo from '@mortgage-pos/ui/assets/bankrate/value_prop_info.svg'
import ValuePropLowRates from '@mortgage-pos/ui/assets/bankrate/value_prop_low_rates.svg'
import UploadPhotoLogo from '@mortgage-pos/ui/assets/bankrate/BR_upload_photo.svg'
import BR_Checkmark from '@mortgage-pos/ui/assets/bankrate/BankrateCheckmark.svg'
import BR_Upload_Cloud from '@mortgage-pos/ui/assets/bankrate/BR_Upload_Cloud_Icon.svg'
import BR_Location_Pin from '@mortgage-pos/ui/assets/bankrate/BR_Location_Pin_Icon.svg'
import BRDashedLine from '@mortgage-pos/ui/assets/bankrate/BR_Dashed_Line.svg'
import BR_Rate_Table from '@mortgage-pos/ui/assets/bankrate/BR_Rate_Table.svg'
import ValuePropClock from '@mortgage-pos/ui/assets/bankrate/value_prop_clock.svg'
import ValuePropHouse from '@mortgage-pos/ui/assets/bankrate/value_prop_house.svg'
import ValuePropReviews from '@mortgage-pos/ui/assets/bankrate/value_prop_reviews.svg'

import Customization, {
  CustomTextMap,
  IconMap,
  LoaderMap,
  LogoMap,
} from '../../../types/customization'

const logos: LogoMap = {
  default: BankrateLogo,
  color: {
    black: BankrateLogoBlack,
  },
}

const loaders: LoaderMap = {
  default: BankrateLoader,
}

// TODO: `sageFullPartnerName` and `sageBaseUrl may not be applicable here since it's a
// Bankrate customization that most likely doesn't display the pages that previously had
// `Sage Mortgage` hard coded in but we can remove that later if it's not needed
const customText: CustomTextMap = {
  displayName: 'Bankrate',
  sageFullPartnerName: 'Sage Mortgage',
  sageBaseUrl: 'sagemortgage.com',
  valueProp1: {
    title: '100+ lenders',
    description:
      "We'll compare your loan with 100+ of our lenders to see how much we can save you.",
  },
  valueProp2: {
    title: '15 seconds',
    description:
      'How long it’ll take you to see your custom comparison options',
  },
}

const icons: IconMap = {
  money: MoneyIcon,
  time: TimeIcon,
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  linkedIn: LinkedInIcon,
  footer: Line,
  save: Save,
  helpful: UserCheckIcon,
  upload: UploadLogo,
  brOnlineApp: BROnline,
  brInsuranceHome: BRInsuranceHome,
  brDocumentHome: BRDocumentHome,
  rightArrow: RightArrow,
  brComputerWithX: BrXComputer,
  valuePropMoney: ValuePropMoney,
  valuePropInfo: ValuePropInfo,
  valuePropLowRates: ValuePropLowRates,
  uploadPhotoLogo: UploadPhotoLogo,
  checkmark: BR_Checkmark,
  uploadCloud: BR_Upload_Cloud,
  locationPin: BR_Location_Pin,
  brDashedLine: BRDashedLine,
  brRateTable: BR_Rate_Table,
  valuePropClock: ValuePropClock,
  valuePropHouse: ValuePropHouse,
  valuePropReviews: ValuePropReviews,
}

export default {
  logos,
  loaders,
  customText,
  icons,
} as Customization
