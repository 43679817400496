import { sha256 } from 'js-sha256'

/**
 *  Generates userId from email for use in Identify events
 *
 *  userId must be a user’s email that is formatted as such:
 *      - lower cased
 *      - hashed using SHA256 w/ hex encoding
 *
 *  This formatting is required so that we have a simple, standardized and consistent way of generating
 *  the userId to facilitate user deletion as required by the privacy and security team.
 *  Business teams must implement the userId generation themselves until a centralized identity service is built.
 *
 *  https://redventures.atlassian.net/wiki/spaces/IDM/pages/306480223/Identify
 * */

export function generateUserId(userEmail: string) {
  if (userEmail) {
    return sha256(userEmail?.toLowerCase())
  }
  return null
}
