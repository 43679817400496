import { CookieName } from '@mortgage-pos/types'

/**
 * Gets the landing url
 * The url is passed down from SageNext as cookie, set in session storage from current session, or freshly pulled from window
 */
export function getLandingUrl(): string {
  // If session storage, then it comes from current Alfie session and we use that one...
  const existingSessionLandingUrl = sessionStorage?.getItem(
    CookieName.LandingUrl
  )
  if (existingSessionLandingUrl) return existingSessionLandingUrl

  return window?.location
    ? `${window.location.pathname}${window.location.search}`
    : ''
}

/**
 * Sets landing URL in session storage
 */
export function setLandingUrl(): boolean {
  const existingSessionLandingUrl = sessionStorage?.getItem(
    CookieName.LandingUrl
  )
  const landingUrl =
    `${window?.location?.pathname}${window?.location?.search}` || ''

  if (existingSessionLandingUrl) return false

  sessionStorage?.setItem(CookieName.LandingUrl, landingUrl)
  return true
}
