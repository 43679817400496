export enum Partners {
  amazon = 'amazon',
  facet = 'facet',
  brCashOutRefi = 'brCashOutRefi',
}

interface PartnerCode {
  [code: string]: Partners
}

export const PartnerCodeList: PartnerCode = {
  '2f49875e': Partners.amazon,
  facet: Partners.facet,
  br439: Partners.brCashOutRefi,
}
