import { getCookie } from '@mortgage-pos/utils'
import { CookieName } from '@mortgage-pos/types'

/**
 * Gets the referrer url
 * The url is passed down from SageNext as cookie to ensure the full url is available
 */
export function getReferrerUrl(): string {
  const existingCookieReferrerUrl = getCookie(CookieName.ReferrerUrl)
  if (existingCookieReferrerUrl) return existingCookieReferrerUrl
  return ''
}
