import { Action, action, Thunk, thunk } from 'easy-peasy'
import { borrowerPortalRequest } from '../services/http'
import { PlaidLinkOnSuccessMetadata } from '@mortgage-pos/types'

interface PlaidState {
  linkToken: string | null
  accessToken: string | null
  isLoading: boolean
  error: string | null
  setLinkToken: Action<PlaidState, string>
  setAccessToken: Action<PlaidState, string>
  setLoading: Action<PlaidState, boolean>
  setError: Action<PlaidState, string | null>
  initializePlaid: Thunk<PlaidState>
  connectPlaidAccount: Thunk<
    PlaidState,
    {
      publicToken: string
      metadata: PlaidLinkOnSuccessMetadata
    }
  >
}

const plaidStore: PlaidState = {
  linkToken: null,
  accessToken: null,
  isLoading: false,
  error: null,

  // Actions
  setLinkToken: action((state, payload) => {
    state.linkToken = payload
  }),

  setAccessToken: action((state, payload) => {
    state.accessToken = payload
  }),

  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),

  setError: action((state, payload) => {
    state.error = payload
  }),

  // Thunks
  initializePlaid: thunk(async (actions, _, { getState }) => {
    actions.setLoading(true)
    try {
      const response = await borrowerPortalRequest('/plaid/link-token')
      actions.setLinkToken(response.data.linkToken)
    } catch (error) {
      actions.setError('Failed to initialize Plaid')
      console.error('Plaid initialization error:', error)
    } finally {
      actions.setLoading(false)
    }
  }),

  connectPlaidAccount: thunk(async (actions, { publicToken }) => {
    actions.setLoading(true)
    try {
      const response = await borrowerPortalRequest('/plaid/exchange-token', {
        publicToken,
      })
      actions.setAccessToken(response.data.accessToken)
      return response.data.success
    } catch (error) {
      actions.setError('Failed to connect bank account')
      console.error('Plaid connection error:', error)
      throw error
    } finally {
      actions.setLoading(false)
    }
  }),
}

export default plaidStore
