import { environment } from '@mortgage-pos/ui/env'
import { EncryptedSensitive } from '@mortgage-pos/types'
import { cheapUUID, axiosHttp } from '@mortgage-pos/utils'

export default class CryptoClient {
  static async encryptSensitiveData(payload: any): Promise<EncryptedSensitive> {
    try {
      const sensitiveUuid = cheapUUID()

      const encryptionResponse = await axiosHttp.post(
        `${environment.apiBaseUrl}/api/encrypt-sensitive`,
        { payload, sensitiveUuid }
      )

      const encryptedPayload = encryptionResponse?.data as EncryptedSensitive

      return encryptedPayload
    } catch (error) {
      return null
    }
  }
}
