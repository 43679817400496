import { getDecryptUrl } from '@mortgage-pos/utils/error-handling'

export function stripSensitiveDataFromPayload(payload: Record<string, any>) {
  if (payload?.custom?.sensitive) {
    delete payload.custom.sensitive
  }

  if (payload?.sensitive) {
    delete payload.sensitive
  }

  if (payload?.body?.trace?.extra?.sensitive) {
    delete payload.body.trace.extra.sensitive
  }
}

export function decorateEncryptedPayload(
  sensitiveUuid: string,
  payload: Record<string, any>
) {
  const decryptUrl = getDecryptUrl(sensitiveUuid)

  if (payload?.custom?.sensitive) {
    payload.custom.decryptUrl = decryptUrl
  }

  if (payload?.sensitive) {
    payload.decryptUrl = decryptUrl
  }

  if (payload?.body?.trace?.extra?.sensitive) {
    payload.body.trace.extra.decryptUrl = decryptUrl
  }
}
