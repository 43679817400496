import {
  parse,
  format,
  subYears,
  compareAsc,
  isEqual,
  sub,
  differenceInBusinessDays,
} from 'date-fns'
import { getTimezoneOffset } from 'date-fns-tz'

export const SECOND_IN_MILLISECONDS = 1000
export const MINUTE_IN_MILLISECONDS = SECOND_IN_MILLISECONDS * 60
export const HOUR_IN_MILLISECONDS = MINUTE_IN_MILLISECONDS * 60

type Holiday = {
  id: number
  date: Date
  name: string
}

export function transformStringToDate(
  date: string,
  formatString = 'yyyy-MM-dd'
): Date {
  return date ? parse(date, formatString, new Date()) : null
}

export function transformDateToString(
  date: Date,
  formatString: string = 'yyyy-MM-dd'
): string {
  return date ? format(date, formatString) : null
}

export function areDatesEqual(compareA: Date, compareB: Date): boolean {
  // both values are undefined/null or the dates match
  return (
    ((compareA === undefined || compareA === null) &&
      (compareB === undefined || compareB === null)) ||
    isEqual(compareA, compareB)
  )
}

export function isDateXYearsOld(
  startDateString: string,
  numberOfYearsAgo: number
): boolean {
  const todaysDate = new Date()
  const comparisonDate = subYears(todaysDate, numberOfYearsAgo)
  const startDate = new Date(startDateString)

  return compareAsc(startDate, comparisonDate) === -1
}

export function isValidDate(date: Date): boolean {
  if (typeof date !== 'object' || typeof date?.toString !== 'function') {
    return false
  }

  // This is the standard behavior of the Date object:
  // new Date('merp') -> Invalid Date {}
  return date.toString() !== 'Invalid Date'
}

export function sortDates(dates: any[]) {
  return dates.sort((a, b) => compareAsc(new Date(a), new Date(b)))
}

export function getMilitaryHour(hour: string): number {
  const hourNormalized = hour.replace(/[\s\W_]/g, '').toLowerCase()
  const parsedHour = parseInt(hourNormalized)

  if (parsedHour > 12 || parsedHour < 0 || Number.isNaN(parsedHour)) {
    throw new Error(`The hour (${hour}) is not a valid value`)
  }

  // Handle special cases for noon and midnight
  const isLastHour = parsedHour === 12
  const noon = 12
  const midnight = 0

  if (hourNormalized.includes('am')) {
    return isLastHour ? midnight : parsedHour
  }

  if (hourNormalized.includes('pm')) {
    return isLastHour ? noon : parsedHour + 12
  }

  throw new Error('Times must be provided as either `am` or `pm`')
}

export function transformDateRange(dateRange) {
  let from = dateRange.from
    ? new Date(dateRange.from)
    : sub(new Date(), { days: 30 })
  let to = dateRange.to ? new Date(dateRange.to) : new Date()

  const timezone = getSystemTimezone()
  const timezoneOffset = getTimezoneOffset(timezone) / HOUR_IN_MILLISECONDS

  from = new Date(
    Date.UTC(
      from.getUTCFullYear(),
      from.getUTCMonth(),
      from.getUTCDate(),
      -timezoneOffset
    )
  )
  to = new Date(
    Date.UTC(
      to.getUTCFullYear(),
      to.getUTCMonth(),
      to.getUTCDate(),
      23 - timezoneOffset,
      59,
      59,
      999
    )
  )

  return { from, to }
}

export function getSystemTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

/**
 *
 * @returns the difference in business days between the issue date and the lock expiration date,
 * optionally excluding holidays
 */
export const getNumOfBusinessDaysBetween = async ({
  earlierDate,
  laterDate,
  listOfHolidays,
  excludeHolidays = true,
}: {
  earlierDate: Date
  laterDate: Date
  listOfHolidays: Holiday[]
  excludeHolidays?: boolean
}) => {
  const diffInBusinessDays = differenceInBusinessDays(laterDate, earlierDate)

  if (!excludeHolidays) {
    return diffInBusinessDays
  }

  const holidaysBetweenDates = listOfHolidays.filter(
    (holiday) => holiday?.date >= earlierDate && holiday?.date <= laterDate
  )
  const numOfHolidays = holidaysBetweenDates?.length || 0

  return diffInBusinessDays - numOfHolidays
}
