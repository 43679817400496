import '@mortgage-pos/ui/styles/scss/components/loading.scss'
import React, { FC } from 'react'
import { loaderMap } from '@mortgage-pos/ui/utils/customizationHelpers'

type LoaderType = 'default' | 'house' | 'checklist'

interface Props {
  loaderType?: LoaderType
  text?: string
  shouldWrap?: boolean
  fullPage?: boolean
}

const LoadingContent = ({ loaderType, text = '' }) => (
  <div data-testid="loading" className="loading">
    <img
      className={`loading__image --${loaderType}`}
      src={loaderMap(loaderType)}
      alt="Loading"
    />
    <div className="loading__text">{text}</div>
  </div>
)

const Loading: FC<Props> = ({
  loaderType = 'default',
  text = '',
  shouldWrap = true,
  fullPage = false,
}) => {
  const loadingContent = <LoadingContent loaderType={loaderType} text={text} />

  if (fullPage) {
    return <div className="loading-page">{loadingContent}</div>
  }

  if (!shouldWrap) {
    return loadingContent
  }

  return <div className="app-content__wrap">{loadingContent}</div>
}

export default Loading
