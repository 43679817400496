import rollbar from '@mortgage-pos/ui/services/rollbar'
import { Incense } from '@mortgage-pos/incense/Incense.class'
import { IncenseClient } from '@mortgage-pos/incense/Incense.base-types'

export type IncenseFactoryInstance = (previousError?: any) => Incense

export function IncenseFactory(client: IncenseClient): IncenseFactoryInstance {
  return (previousError?: any) => new Incense(client, previousError)
}

const incenseClient = rollbar

const incense = IncenseFactory(incenseClient)

export default incense
