export function redactAll(redactable: object): object {
  if (!redactable) {
    return null
  }

  return Object.entries(redactable).reduce((acc, [key, value]) => {
    if (typeof value !== 'object') {
      acc[key] = redact(key, value)
    } else {
      acc[key] = redactAll(value)
    }
    return acc
  }, {})
}

function redact(key, value) {
  return redactKeys.includes(key) ? 'pii filtered' : value
}

const redactKeys = [
  'email',
  'phone',
  'address',
  'street',
  'lastName',
  'firstName',
  'phonePrimary',
  'primaryAddressStreet',
  'subjectPropertyAddressStreet',
  'password',
  'ssn',
  'coBorrowerSsn',
  'autoCompleteAddressFields',
  'consumerSsn',
]
