import { gql } from 'apollo-boost'
import { StoreModel } from './index'
import newRelic from '@mortgage-pos/ui/services/newRelic'
import { graphQL } from '@mortgage-pos/ui/services/http'
import { variation } from '@mortgage-pos/ui/services/featureFlags'
import { FEATURE_FLAG_TYPES } from '@mortgage-pos/data'
import { thunk, Thunk, computed, Computed, action, Action } from 'easy-peasy'
import incense from '@mortgage-pos/ui/incense'

import {
  sumValues,
  getGraphQLString,
  calcMonthlyPayment,
} from '@mortgage-pos/utils'

import {
  LoanType,
  IncomeSource,
  LiabilityAnswers,
  QuestionnaireType,
  NtlSoftQualStatus,
  NtlQualResult,
  NtlDisqualifiedReason,
} from '@mortgage-pos/types'

export interface NoTalkLockModel {
  combinedIncomeSources: Computed<NoTalkLockModel, IncomeSource[], StoreModel>
  isTexasCashOut: Computed<NoTalkLockModel, boolean, StoreModel>
  totalMonthlyIncome: Computed<NoTalkLockModel, number, StoreModel>
  combinedManualLiabilities: Computed<
    NoTalkLockModel,
    LiabilityAnswers[],
    StoreModel
  >
  totalMonthlyDebts: Computed<NoTalkLockModel, number, StoreModel>
  estimatedDebtToIncome: Computed<NoTalkLockModel, number, StoreModel>
  hasDisqualifyingEmployment: Computed<NoTalkLockModel, boolean, StoreModel>
  softQualificationStatus: Computed<
    NoTalkLockModel,
    NtlSoftQualStatus,
    StoreModel
  >
  getQualificationStatus: Thunk<
    NoTalkLockModel,
    null,
    null,
    StoreModel,
    Promise<NtlQualResult>
  >
  disqualify: Thunk<NoTalkLockModel, string, null, StoreModel, Promise<boolean>>
  checkSplitFlowSessionLength: Thunk<
    NoTalkLockModel,
    null,
    object,
    StoreModel,
    Promise<void>
  >
}

const noTalkLock = (): NoTalkLockModel => {
  return {
    combinedIncomeSources: computed(
      [(state, storeState) => storeState.answers.mergedAnswers],
      (mergedAnswers) => {
        const { hasCoBorrower, incomeSources, coBorrowerIncomeSources } =
          mergedAnswers

        const incomes: IncomeSource[] = incomeSources ?? []
        const coBorrowerIncomes: IncomeSource[] = coBorrowerIncomeSources ?? []

        return hasCoBorrower === 'Yes'
          ? [...incomes, ...coBorrowerIncomes]
          : incomes
      }
    ),

    isTexasCashOut: computed(
      [(state, storeState) => storeState.answers.mergedAnswers],
      ({ state, isTakingCashOut }) => {
        return state === 'TX' && isTakingCashOut === 'Yes'
      }
    ),

    totalMonthlyIncome: computed(
      [(state) => state, (state, storeState) => storeState.answers],
      ({ combinedIncomeSources }, _answers) => {
        // Filter out old incomes and ones without an amt
        // Map the calculated monthly amt after filtering unneeded incomes
        const incomeAmounts = combinedIncomeSources
          .filter((incomeSource) => !incomeSource.endDate)
          .filter((incomeSource) => incomeSource.grossAnnualIncomeAmount)
          .map((incomeSource) => {
            return Math.round(incomeSource.grossAnnualIncomeAmount / 12)
          })

        return sumValues(incomeAmounts)
      }
    ),

    combinedManualLiabilities: computed(
      [(state, storeState) => storeState.answers.mergedAnswers],
      (mergedAnswers) => {
        const {
          hasCoBorrower,
          otherLiabilitiesGroup,
          coBorrowerOtherLiabilitiesGroup,
        } = mergedAnswers

        const primaryLiabilities = otherLiabilitiesGroup ?? []
        const coBorrowerLiabilities = coBorrowerOtherLiabilitiesGroup ?? []

        return hasCoBorrower === 'Yes'
          ? [...primaryLiabilities, ...coBorrowerLiabilities]
          : primaryLiabilities
      }
    ),

    totalMonthlyDebts: computed(
      [
        (state) => state,
        (state, storeState) => storeState.answers.mergedAnswers,
        (state, storeState) => storeState.propertyInfo.avmMonthlyPropertyTax,
      ],
      ({ combinedManualLiabilities }, mergedAnswers, avmMonthlyPropertyTax) => {
        const {
          hasCoBorrower,
          expenseHOAdues,
          previouslySelectedRate,
          expenseMortgageInsurance,
          expenseHomeownerInsurance,
        } = mergedAnswers

        // $150 (average CC bill) * applicantCount - is used for calcs
        const applicantCount = hasCoBorrower === 'Yes' ? 2 : 1
        const estimatedCreditCardAmt = 150 * applicantCount

        // Avoid uncaught errs when there is no BR rate
        const estimatedRatePayment = previouslySelectedRate
          ? calcMonthlyPayment(previouslySelectedRate)
          : 0

        const manualLiabilitiesPayments = combinedManualLiabilities.reduce(
          (acc, liability) => acc + liability.otherLiabilityAmount,
          0
        )

        return sumValues([
          expenseHOAdues,
          avmMonthlyPropertyTax,
          expenseMortgageInsurance,
          expenseHomeownerInsurance,
          estimatedCreditCardAmt,
          estimatedRatePayment,
          manualLiabilitiesPayments,
        ])
      }
    ),

    estimatedDebtToIncome: computed(
      [(state) => state, (state, storeState) => storeState.answers],
      ({ totalMonthlyIncome, totalMonthlyDebts }, _answers) => {
        const disqualifyingDti = 100

        if (!(totalMonthlyIncome > 0)) {
          return disqualifyingDti
        }

        return Math.round((totalMonthlyDebts / totalMonthlyIncome) * 100)
      }
    ),

    hasDisqualifyingEmployment: computed(
      [(state) => state, (state, storeState) => storeState.answers],
      ({ combinedIncomeSources }, _answers) => {
        return combinedIncomeSources.reduce(
          (hasDisqualifyingEmployment: boolean, incomeSource): boolean => {
            if (hasDisqualifyingEmployment) {
              return true
            }

            return ['selfEmployed', 'noIncome'].includes(
              incomeSource.incomeType
            )
          },
          false
        )
      }
    ),

    softQualificationStatus: computed(
      [
        (state) => state,
        (state, storeState) => storeState.answers.mergedAnswers,
        (state, storeState) => storeState.application.applicationId,
        (state, storeState) => storeState.questionnaire.questionnaireType,
      ],
      (
        noTalkLock,
        mergedAnswers,
        applicationId,
        questionnaireType
      ): NtlSoftQualStatus => {
        try {
          if (questionnaireType !== QuestionnaireType.NTL) {
            return generateSoftQualFailureResponse(
              NtlDisqualifiedReason.INCORRECT_QUESTIONNAIRE_TYPE
            )
          }

          const {
            loanPurpose,
            forbearance,
            rollInFees,
            escrowRollIn,
            escrow,
            purchaseStage,
          } = mergedAnswers

          // Always soft qualify contract-in-hand borrowers so we pull credit and use liabilities for DTI
          if (
            loanPurpose === LoanType.Purchase &&
            purchaseStage === 'contract'
          ) {
            return { isSoftQualified: true }
          }

          if (loanPurpose === LoanType.Purchase) {
            return generateSoftQualFailureResponse(
              NtlDisqualifiedReason.PURCHASE
            )
          }

          if (
            rollInFees === 'idk' ||
            escrowRollIn === 'idk' ||
            escrow === 'idk'
          ) {
            return generateSoftQualFailureResponse(
              NtlDisqualifiedReason.UNSURE_FEES_ESCROW
            )
          }

          const {
            isTexasCashOut,
            estimatedDebtToIncome,
            hasDisqualifyingEmployment,
          } = noTalkLock

          if (forbearance?.toLowerCase() === 'yes') {
            return generateSoftQualFailureResponse(
              NtlDisqualifiedReason.HAS_FORBEARANCE
            )
          }

          if (isTexasCashOut) {
            return generateSoftQualFailureResponse(
              NtlDisqualifiedReason.TEXAS_CASHOUT
            )
          }

          if (hasDisqualifyingEmployment) {
            return generateSoftQualFailureResponse(
              NtlDisqualifiedReason.EMPLOYMENT
            )
          }

          if (estimatedDebtToIncome > 50) {
            return generateSoftQualFailureResponse(NtlDisqualifiedReason.DTI)
          }

          return {
            isSoftQualified: true,
          }
        } catch (err) {
          incense(err)
            .details({
              name: 'NtlSoftQualStatusError',
              message: 'Failed to soft qualify applicant for NTL',
            })
            .safe({ applicationId })
            .error()

          return generateSoftQualFailureResponse(
            `noTalkLock: error durring softQualificationStatus, ${err}`
          )
        }
      }
    ),

    getQualificationStatus: thunk(
      async (_, __, { getStoreState }): Promise<NtlQualResult> => {
        const { applicationId } = getStoreState().application

        try {
          const questionSet = new URLSearchParams(window.location.search).get(
            'question-set'
          )

          const ntlDisabledQuestionSets = await variation(
            FEATURE_FLAG_TYPES.NTL_DISABLED_QUESTION_SETS
          )

          if (ntlDisabledQuestionSets.includes(questionSet)) {
            return generateNtlQualFailureResponse(
              'ntlQualificationStatus: NTL disabled'
            )
          }

          const response = await graphQL({
            query: ntlQualificationQuery,
          })

          const { errors } = response.data

          if (errors?.length > 0) {
            return generateNtlQualFailureResponse(
              'ntlQualificationStatus response error'
            )
          }

          const qualificationData = response?.data?.data?.ntlQualificationStatus

          return (
            qualificationData ??
            generateNtlQualFailureResponse(
              'ntlQualificationStatus: No response data'
            )
          )
        } catch (e) {
          incense(e)
            .details({
              name: 'GetQualificationStatusError',
              message: 'Failed to retrieve qualification for NTL',
            })
            .safe({ applicationId })
            .error()

          return generateNtlQualFailureResponse(
            'noTalkLock: Error during getQualificationStatus'
          )
        }
      }
    ),

    disqualify: thunk(
      async (
        _,
        reason,
        { getStoreState, getStoreActions }
      ): Promise<boolean> => {
        if (await variation(FEATURE_FLAG_TYPES.DEBUG_NTL_QUALIFICATION)) {
          console.log(`Disqualified from NTL: ${reason}`)
        }
        const { setAnswers } = getStoreActions().answers
        const newRelicTags = [
          {
            key: 'reason',
            value: reason,
          },
        ]

        setAnswers({ isNtlQualified: false })
        newRelic.increment('ntl_qualification.disqualified', newRelicTags)

        try {
          const {
            data: { errors },
          } = await graphQL({
            query: saveNtlDisqualifiedReasonQuery,
            variables: { applicationId: null, reason },
          })

          if (errors && errors.length) {
            newRelic.increment('save_disqualify_reason.error', newRelicTags)
            return
          }

          return
        } catch (e) {
          newRelic.increment('save_disqualify_reason.error', newRelicTags)
          return
        }
      }
    ),

    checkSplitFlowSessionLength: thunk(
      async (
        { disqualify },
        __,
        { getState, getStoreState, getStoreActions }
      ): Promise<void> => {
        let shouldConvert = false

        const { isNtlQualified } = getStoreState().answers.answers
        if (isNtlQualified !== true) return

        const { isApplyFlow } = getStoreState().questionnaire
        if (isApplyFlow === false) return

        const { applicationCreatedAt } = getStoreState().application
        const createdAtTime = applicationCreatedAt.getTime()
        const ONE_HOUR: number = 60 * 60 * 1000

        if (Date.now() - createdAtTime >= ONE_HOUR) {
          await disqualify(NtlDisqualifiedReason.TOO_OLD)
          shouldConvert = true
        }

        if (shouldConvert) {
          try {
            await graphQL({
              query: convertNtlToBaselineQuery,
            })

            return
          } catch (e) {
            incense(e)
              .details({
                name: 'checkSplitFlowSessionLength',
                message: 'Error calling convertNtlToBaseline query',
              })
              .error()
              .rethrow()

            return
          }
        }
      }
    ),
  }
}

export default noTalkLock

function generateSoftQualFailureResponse(reason: string): NtlSoftQualStatus {
  return {
    isSoftQualified: false,
    reason,
  }
}

function generateNtlQualFailureResponse(reason: string): NtlQualResult {
  return {
    isQualified: false,
    reason,
  }
}

export const ntlQualificationQuery = getGraphQLString(gql`
  query ntlQualificationStatus {
    ntlQualificationStatus {
      isQualified
      debtToIncome
      reason
      currentLoanTerm
    }
  }
`)

// TODO: remove applicationId from query/mutation
export const saveNtlDisqualifiedReasonQuery = getGraphQLString(gql`
  mutation saveNtlDisqualifiedReason($applicationId: Int, $reason: String!) {
    saveNtlDisqualifiedReason(applicationId: $applicationId, reason: $reason)
  }
`)

export const convertNtlToBaselineQuery = getGraphQLString(gql`
  mutation convertNtlToBaseline {
    convertNtlToBaseline
  }
`)
