import { jsonUtils } from './json'

export function extractGQLErrors(error: any) {
  try {
    let errors = jsonUtils.clone(error?.response?.data?.errors)

    if (Array.isArray(errors) && errors?.length > 50) {
      errors = errors.slice(0, 50)
    }

    return errors
  } catch (error) {
    return null
  }
}
