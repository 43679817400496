import { DefaultFeatureFlags } from './index'

export function hasLocalOverrideFlag(flag: string) {
  return Object.prototype.hasOwnProperty.call(LOCAL_FF_OVERRIDES, flag)
}

/**
 * This variable is used to set any flags you need to set on your local machine.
 * A flag set here will supercede its counterpart coming from LD. Please bear
 * in mind this is for LOCAL DEVELOPMENT ONLY and should not be committed to
 * the mortgage-pos repo.
 *
 * To stop your Git worktree from tracking changes to this file, run the npm
 * script:
 *
 * `npm run skip-ff-locals`
 */
export const LOCAL_FF_OVERRIDES: Partial<DefaultFeatureFlags> = {}
