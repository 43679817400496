import { CookieName } from '@mortgage-pos/types'

export function getCookie(cookieName: CookieName) {
  const cookieRegex = new RegExp(`${cookieName}=([^;]+)`)
  const cookieVal = cookieRegex.exec(document.cookie)
  return cookieVal !== null ? unescape(cookieVal[1]) : null
}

export function setCookie(
  cookieName: CookieName,
  cookieVal: string,
  expInDays?: number,
  domain?: string
): void {
  let cookie = `${cookieName}=${cookieVal};path=/;`

  if (domain) {
    cookie += `domain=${domain};`
  }

  if (!expInDays) {
    document.cookie = cookie
    return
  }

  const cookieExpiration = new Date()
  cookieExpiration.setDate(cookieExpiration.getDate() + expInDays)
  const cookieAttributes = `;expires=${cookieExpiration};`

  document.cookie = `${cookie}${cookieAttributes}`
}

export function deleteCookie(cookieName: CookieName, domain?: string): void {
  let cookie = `${cookieName}=; path=/; Max-Age=0;`

  if (domain) {
    cookie += `domain=${domain};`
  }

  document.cookie = cookie
}
