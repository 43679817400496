import http from '@mortgage-pos/ui/services/http'
import { StoreModel } from './index'
import isEmpty from 'lodash/isEmpty'
import { environment } from '@mortgage-pos/ui/env'
import rollbar from '@mortgage-pos/ui/services/rollbar'
import newRelic from '@mortgage-pos/ui/services/newRelic'
import { CustomError } from '@mortgage-pos/error-handling'
import GoogleClient from '@mortgage-pos/ui/services/GoogleClient'
import { Thunk, Action, action, thunk, Computed, computed } from 'easy-peasy'

interface LEData {
  loanAmount?: number
  cashOut?: number
  term?: number
  interest?: number
  monthlyPayment?: number
  lenderCredits?: number
  appraisalFee?: number
  titleFees?: number
  taxesAndGovFees?: number
  closingCosts?: number
  loanPurpose?: string
  firstName?: string
  lastName?: string
  coBorrowerFirstName?: string
  coBorrowerLastName?: string
  points?: number
  productFamily?: string
  propertyAddress?: string
  propertyValue?: number
  originationFee?: number
  monthlyPaymentPnI?: number
  apr?: number
  lender?: string
}

interface ExtractDocumentDetailsInput {
  file: File
  documentType?: string
}
export interface LEComparisonModel {
  hasRunCreditPull: boolean
  wasCreditPullSuccessful: boolean
  isLeComparisonApp: boolean
  isDemoFlow: boolean
  rawLEData: LEData
  isLeComparisonRunning: boolean
  setIsDemoFlow: Action<LEComparisonModel, boolean>
  setHasRunCreditPull: Action<LEComparisonModel, boolean>
  setWasCreditPullSuccessful: Action<LEComparisonModel, boolean>
  setIsLeComparisonRunning: Action<LEComparisonModel, boolean>
  setLeComparisonApp: Action<LEComparisonModel, boolean>
  setRawLEData: Action<LEComparisonModel, LEData>
  leRate: Computed<LEComparisonModel, object, StoreModel>
  extractLEDetails: Thunk<
    LEComparisonModel,
    ExtractDocumentDetailsInput,
    object,
    StoreModel
  >
}

const leComparison = (): LEComparisonModel => ({
  hasRunCreditPull: false,

  wasCreditPullSuccessful: false,

  isLeComparisonApp: false,

  isDemoFlow: false,

  rawLEData: null,

  isLeComparisonRunning: false,

  setIsDemoFlow: action((state, isDemoFlow) => {
    state.isDemoFlow = isDemoFlow
  }),

  setHasRunCreditPull: action((state, hasRunCreditPull) => {
    state.hasRunCreditPull = hasRunCreditPull
  }),

  setWasCreditPullSuccessful: action((state, wasCreditPullSuccessful) => {
    state.wasCreditPullSuccessful = wasCreditPullSuccessful
  }),

  setIsLeComparisonRunning: action((state, isLeComparisonRunning) => {
    state.isLeComparisonRunning = isLeComparisonRunning
  }),

  setLeComparisonApp: action((state, isLeComparisonApp) => {
    state.isLeComparisonApp = isLeComparisonApp
  }),

  setRawLEData: action((state, rawLEData) => {
    state.rawLEData = rawLEData
  }),

  leRate: computed(
    [
      (state) => state.rawLEData,
      (_, storeState) => storeState.answers.mergedAnswers,
    ],
    (rawLeData, mergedAnswers) => {
      const userEnteredLeData = mergedAnswers.leData?.[0]

      if (!rawLeData && !userEnteredLeData) {
        return {}
      }

      const leData = rawLeData || userEnteredLeData

      return {
        discountPoints: leData.points ? leData.points / leData.loanAmount : 0,
        principleAndInterestPayment:
          leData.monthlyPaymentPnI || leData.monthlyPayment,
        rate: leData.interest,
        closingCosts: leData.closingCosts,
        apr: leData.apr,
        loanAmount: leData.loanAmount,
        // TODO: update for adjustable mtgs
        term: leData.term ? 'F' + leData.term : '',
      }
    }
  ),

  extractLEDetails: thunk(
    async (
      { setRawLEData, setLeComparisonApp, setIsLeComparisonRunning },
      { file, documentType },
      { getStoreActions, getStoreState }
    ) => {
      const { answer } = getStoreActions().questionnaire
      const { initialize } = getStoreActions().application
      const { answers } = getStoreState().answers

      setLeComparisonApp(true)
      await initialize()

      const { applicationGuid } = getStoreState().application

      let leDetailsResponse
      try {
        const body = new FormData()
        body.append('application_guid', applicationGuid)

        if (documentType === 'mortgageStatement') {
          body.append('any_document', file)
          body.append('application_guid', applicationGuid)

          leDetailsResponse = await http.post(
            `${environment.apiBaseUrl}/api/any-adress-extract`,
            body,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          )
        } else {
          body.append('loan_estimate', file)

          leDetailsResponse = await http.post(
            `${environment.apiBaseUrl}/api/loan-estimate-compare`,
            body,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          )
        }
      } catch (e) {
        const error = new CustomError(
          'ExtractLEDetailsError',
          'ExtractLEDetailsError - Failed to extract LE Details'
        )

        rollbar.error(error, {
          sensitive: {
            error: e,
          },
        })

        newRelic.increment('upload_loan_estimate.error')
        throw error
      }

      const leData = leDetailsResponse.data

      setRawLEData(leData)

      const answersFromLe = await extractAnswersFromLeData(leData)
      // Ensuring loanPurpose is included in first answer save
      const answersWithLoanPurpose = {
        ...answersFromLe,
        loanPurpose: answers.loanPurpose,
      }

      await answer(answersWithLoanPurpose)

      newRelic.increment('upload_loan_estimate.success')

      setIsLeComparisonRunning(false)

      return { leData, answersFromLe }
    }
  ),
})

export default leComparison

async function extractAnswersFromLeData(leData) {
  const addressAnswers = await getAddressAnswers(leData.propertyAddress)

  return {
    ...addressAnswers,
    firstName: leData.firstName,
    lastName: leData.lastName,
    estimatedValue: leData.propertyValue,
    cashOut: leData.cashOut,
    remainingBalance: leData.cashOut
      ? leData.loanAmount - leData.cashOut
      : leData.loanAmount,
    propertyType: 'SingleFamily',
    addresses: [
      {
        type: 'current',
        ...addressAnswers,
      },
    ],
    monthlyPayment: leData.monthlyPaymentPnI || leData.monthlyPayment,
    leRate: [
      {
        discountPoints: leData.points ? leData.points / leData.loanAmount : 0,
        principleAndInterestPayment:
          leData.monthlyPaymentPnI || leData.monthlyPayment,
        rate: leData.interest,
        closingCosts: leData.closingCosts,
        apr: leData.apr,
        loanAmount: leData.loanAmount,
        term: leData.term ? 'F' + leData.term : '',
      },
    ],
  }
}

async function getAddressAnswers(addressString) {
  if (!addressString) {
    return {}
  }

  const geolocationData = await GoogleClient.getAddressDetails(addressString)

  if (!isEmpty(geolocationData)) {
    return geolocationData
  }

  try {
    const addressParts = addressString.split(',')
    return {
      zipCode: addressString.slice(-5),
      state: addressParts[1].split(' ')[1],
    }
  } catch (e) {
    return {}
  }
}
