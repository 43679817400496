import { eventTypeMap } from '@mortgage-pos/data'
import { alfalfaUtils } from '@mortgage-pos/utils'
import { EventPayload, EventName } from '@mortgage-pos/types'

let trackingData = {}

export const sendTagularEvent = (
  eventName: EventName,
  eventPayload: EventPayload
) => {
  const payload = {
    '@type': eventTypeMap[eventName],
    ...eventPayload,
  }

  // Dont send events from test env or if creating Alfalfa test apps
  if (
    process.env.NODE_ENV === 'test' ||
    alfalfaUtils.getConfiguration().isTestingMode
  ) {
    recordTrackingData(eventName, payload)
  } else {
    if (typeof (window as any).tagular === 'function') {
      ;(window as any).tagular('beam', eventName, payload)
    }
  }
}

export const recordTrackingData = (eventName: EventName, payload) => {
  trackingData[eventName] = trackingData[eventName] || []
  trackingData[eventName].push(payload)
}

/** Testing utilities */
export const tagularData = (key) => {
  if (key) {
    return trackingData[key]
  }

  return trackingData
}

export const resetTagularData = () => {
  trackingData = {}
}
