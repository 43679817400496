import { StateAbbreviation } from '@mortgage-pos/types'
import { environment } from '../environments/environment'

const productConfigObj = {
  sage: {
    name: 'Sage Mortgage',
    getCompanyLegalName: () => 'Forward Home Lending, LLC dba Sage Mortgage',
  },
  'sage-home-loans': {
    name: 'Sage Home Loans Corporation',
    getCompanyLegalName: (state: StateAbbreviation) => {
      if (
        [
          StateAbbreviation.Ne,
          StateAbbreviation.Fl,
          StateAbbreviation.Wa,
        ].includes(state)
      ) {
        return 'Sage Loans Corporation (CA)'
      } else if (state === StateAbbreviation.Ga) {
        return 'Sage Home Loans Corporation (CA)'
      }
      return 'Sage Home Loans Corporation'
    },
  },
}

export const productConfig = productConfigObj[environment.productName]
