import { detect } from 'detect-browser'
import { EventTag } from '@mortgage-pos/types'

const urlParams = new URLSearchParams(window.location.search)

const windowMatchers = () => ({
  mobile: window.matchMedia('(max-width: 47.43749rem)'),
  tablet: window.matchMedia(
    '(min-width: 47.4375rem) and (max-width: 77.43749rem)'
  ),
  desktop: window.matchMedia('(min-width: 77.4375rem)'),
})

const browser = () => {
  const browser = detect()
  return browser ? browser.name : 'unknown'
}

const deviceType = () => {
  if (windowMatchers().mobile.matches) return 'mobile'
  if (windowMatchers().tablet.matches) return 'tablet'

  return 'desktop'
}

const questionnaire = () => {
  const questionSet = urlParams.get('question-set')
  return questionSet || 'default'
}

const trafficSource = () => {
  if (urlParams.get('bankrate-lead-id')) return 'Bankrate'
  if (urlParams.get('fbclid')) return 'Facebook'

  return 'Homepage'
}

export const globalTags = (): EventTag[] => [
  { key: 'browser', value: browser() },
  { key: 'device', value: deviceType() },
  { key: 'questionnaire', value: questionnaire() },
  { key: 'trafficSource', value: trafficSource() },
]
