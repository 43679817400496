export enum SectionStatusCode {
  active = 'active',
  complete = 'complete',
  visited = 'visited',
}

export interface SectionStatusUpdate {
  sectionName: string
  statusCode: SectionStatusCode
}
