import { alfalfaUtils } from '@mortgage-pos/utils'

import {
  AddressType,
  LeadSourceType,
  CoBorrowerAddress,
  IncomeSource,
} from '@mortgage-pos/types'

export function captureAlfalfaLeadSource(): LeadSourceType {
  const alfalfaConfig = alfalfaUtils.getConfiguration()
  const leadSource = alfalfaConfig?.isTestingMode
    ? LeadSourceType.Alfalfa
    : null

  return leadSource
}

export function payloadHasCoBorrowerInfo(stepAnswers) {
  return (
    stepAnswers.coBorrowerPersonalInfo ||
    stepAnswers.coBorrowerDeclarationBankrupt ||
    stepAnswers.coBorrowerGrossAnnualIncome ||
    stepAnswers.coBorrowerMonthlyDebtPayments ||
    stepAnswers.coBorrowerTotalAssets
  )
}

export function extractPrimaryBorrowerAddress(answers): CoBorrowerAddress {
  const addressRevisionId = answers?.coBorrowerAddresses?.[0]?.id

  // If financed property is also their current residence, use it, otherwise
  // use the borrower-provided current residence
  const primaryBorrowerAddressAnswer =
    answers?.isCurrentAddress === 'Yes'
      ? answers // Answers for propertyAddress are at the top level
      : answers?.addresses?.[0]

  const primaryBorrowerAddress: CoBorrowerAddress = {
    type: AddressType.Current,
    street: primaryBorrowerAddressAnswer?.street,
    apt: primaryBorrowerAddressAnswer?.apt,
    city: primaryBorrowerAddressAnswer?.city,
    state: primaryBorrowerAddressAnswer?.state,
    zipCode: primaryBorrowerAddressAnswer?.zipCode,
  }

  // This ensures we update the address revision if it exists in the db
  if (addressRevisionId) {
    primaryBorrowerAddress.id = addressRevisionId
  }

  return primaryBorrowerAddress
}

export function attachRecordIds(ids, stepAnswers) {
  if (!ids) {
    return stepAnswers
  }

  return stepAnswers.map((item, index) => ({
    ...item,
    ...ids[index],
  }))
}

export function removeUnwantedAnswers(fillPayload: any) {
  const unwantedAnswers = [
    'hasSavedSSN',
    'isNtlQualified',
    'hasSavedCoBorrowerSSN',
  ]

  unwantedAnswers.forEach((answerKey) => delete fillPayload[answerKey])

  return fillPayload
}

export function removeUnwantedCoBorrowerAnswers(fillPayload: any) {
  const payload = { ...fillPayload }
  const unwantedAnswers = ['coBorrowerSoftCreditConsentDisclosure']

  unwantedAnswers.forEach((answerKey) => delete payload[answerKey])

  return payload
}

export function addYearsInProfession(incomes: IncomeSource[]) {
  const incomesWithYearsInProfession = incomes.map((income) => {
    if (income?.startDate) {
      const startDate = new Date(income.startDate)
      const endDate = income?.currentJob
        ? new Date()
        : new Date(income?.endDate)

      const completedYearsInProfession = Math.floor(
        getMonthsBetween(startDate, endDate) / 12
      )

      income.yearsInThisProfession =
        completedYearsInProfession < 0 ? 0 : completedYearsInProfession
    }

    return income
  })

  return incomesWithYearsInProfession
}

export function getMonthsBetween(dateFrom, dateTo) {
  return (
    dateTo.getMonth() -
    dateFrom.getMonth() +
    12 * (dateTo.getFullYear() - dateFrom.getFullYear())
  )
}
