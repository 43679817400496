export enum EventName {
  Identify = 'Identify',
  FormStarted = 'FormStarted',
  FormContinued = 'FormContinued',
  FormSubmitted = 'FormSubmitted',
  FieldInputted = 'FieldInputted',
  FormErrored = 'FormErrored',
  CohesionBSMBridge = 'CohesionBSMBridge',
  LoanTekCall = 'LoanTekCall',
  LoanTekErrored = 'LoanTekErrored',
  LoanTekResponse = 'LoanTekResponse',
  BeSmarteePortal = 'BeSmarteePortal',
  LOAssigned = 'LOAssigned',
  LOAssignedV2 = 'LoAssignedV2',
  LeadRecieved = 'LeadRecieved',
  ApplicationStarted = 'ApplicationStarted',
  ApplicationStartedV2 = 'ApplicationStartedV2',
  ApplicationRevised = 'ApplicationRevised',
  VelocifyLeadUpdated = 'VelocifyLeadUpdated',
  ApplicationStatusUpdated = 'ApplicationStatusUpdated',
  QApplicationLeadReceived = 'QApplicationLeadReceived',
  QApplicationApplicationStarted = 'QApplicationStarted',
  QApplicationApplicationRevised = 'QApplicationRevised',
  QApplicationStatusUpdated = 'QApplicationStatusUpdated',
  QApplicationLOAssigned = 'QApplicationLOAssigned',
  QApplicationLoanTekCall = 'QApplicationLoanTekCall',
  QApplicationLoanTekErrored = 'QApplicationLoanTekErrored',
  QApplicationLoanTekResponse = 'QApplicationLoanTekResponse',
  VelocifyApplicationStatusUpdatedEvent = 'VelocifyApplicationStatusUpdatedEvent',
  VelocifyLeadCompleted = 'VelocifyLeadCompleted',
  ElementClicked = 'ElementClicked',
  ElementViewed = 'ElementViewed',
  ProcessorAssignedV2 = 'ProcessorAssignedV2',
  ProductViewed = 'ProductViewed',
  ProductClicked = 'ProductClicked',
  PreQualify = 'PreQualify',
  PageViewed = 'PageViewed',
  AccountCreated = 'AccountCreated',
  LoanToUnderwriting = 'LoanToUnderwriting',
  LoanFunded = 'LoanFunded',
  LoanEsigned = 'LoanEsigned',
  LoanConditionallyApproved = 'loanConditionallyApproved',
  LoanClearToClose = 'loanClearToClose',
  LenderLocked = 'lenderLocked',
  LockedRate = 'lockedRate',
  ApplicationRevisedV3 = 'ApplicationRevisedV3',

  // ELLO Events
  WelcomeEmailV3 = 'WelcomeEmailV3',

  // Borrower Portal Events
  ProcessorAssigned = 'ProcessorAssigned',
  DocumentUploaded = 'DocumentUploaded',
  MessageSent = 'MessageSent',
  LoanLocked = 'LoanLocked',
  DocumentAdded = 'DocumentAdded',
  DocumentReviewed = 'DocumentReviewed',
  StatusUpdated = 'StatusUpdated',
  BorrowerLogin = 'BorrowerLogin',
  ConditionCreated = 'ConditionCreated',
  ConditionCompleted = 'ConditionCompleted',
  ConditionAction = 'ConditionAction',
  lockDeskCompleted = 'lockDeskCompleted',

  // Auth0 Events
  Login = 'Login',
  Logout = 'Logout',
  SignUp = 'SignUp',
  PasswordReset = 'PasswordReset',
}

export type MakeEventResult = {
  makePayload: any
  makeEventSuccessful: boolean
}

export type EventPayload =
  | UserEvent
  | IdentifyEvent
  | LoanTekCallEvent
  | LoanTekResponseEvent
  | ErrorEvent
  | BeSmarteePortalEvent
  | LoanOfficerAssignmentEvent
  | LeadReceivedEvent
  | ApplicationRevisedEvent
  | ApplicationStartedEvent
  | ApplicationStatusUpdatedEvent
  | VelocifyLeadUpdatedRecord
  | VelocifyLeadCompletedEvent
  | ProcessorAssignedEvent
  | DocumentUploadedEvent
  | MessageSentEvent
  | LoanLockedEvent
  | DocumentAddedEvent
  | DocumentReviewedEvent
  | StatusUpdatedEvent
  | BorrowerLoginEvent
  | ElementTrackingEvent
  | ProductClickedEvent
  | ProductViewedEvent
  | ApplicationStartedEventV2
  | ConditionEvent
  | LOAssignedEventV2
  | ProcessorAssignedEventV2
  | PrequalRevisionEvent
  | LockDeskCompleteEvent
  | PageTrackingEvent
  | AccountCreated
  | LoanToUnderwriting
  | LoanFunded
  | LoanEsigned
  | LoanConditionallyApproved
  | LoanClearToClose
  | LenderLocked
  | LockedRate
  | ApplicationRevised
  | IdentityV1Event

/** Standard Cohesion event structure */

export type UserEvent = {
  formContext: FormContext
  stepContext: StepContext
  webContext?: WebContext
}

export enum FormName {
  SageQ = 'SageQ',
  AdvisorSignup = 'Advisor_Network_Signup',
  AlfieApplication = 'Alfie_Application',
  BorrowerPortal = 'Borrower_Portal',
  BPRealtor = 'Borrower_Portal_Realtor_Contact',
}

export enum FormBrand {
  Sage = 'Sage Mortgage',
}

export type FormContext = {
  formVersion: string
  formId: string
  formName: FormName
  formType: string
  formBrand: FormBrand
}

export type StepContext = {
  stepId: string
  stepNumber: number
  stepName?: string
  stepType?: string
}

export type PageContext = {
  referrer?: string
  url?: string
  title?: string
  revisionId?: string
  pageType?: string
  postId?: string
}

export type CookieConsent = {
  essential?: boolean
  performance?: boolean
  preference?: boolean
  targeting?: boolean
  social?: boolean
}

export type Library = {
  name?: string
  version?: string
}

export type WebContext = {
  anonymousId: string
  authenticatedUserId?: string
  fromManual?: boolean
  instanceId?: string
  sessionId?: string
  ip?: string
  manualInstanceId?: string
  library?: Library
  page?: PageContext
  timezoneOffset?: number
  userAgent?: string
  crossSiteId?: string
  hashId?: string
  cohesionConsent?: boolean
  cookieConsent?: CookieConsent
}

export type LoanContext = {
  lenderLoanID: string
  lenderSource: string
  processorID: string
}

export type ApplicationContext = {
  applicationID: string
  applicationUUID?: string
  trafficSource?: string
}

type WebElement = {
  location?: string
  position?: string
  elementType: string
  text?: string
  htmlId?: string
}

/** Identify Event for user retargeting through Iterable */

export type IdentifyEvent = {
  userId: string
  traits: IdentifyTraits
  externalIds: externalId[]
  webContext?: WebContext
}

type IdentifyTraits = {
  address: object
  email: string
  firstName: string
  lastName: string
  phone: string
}

type externalId = {
  id: string
  type: ExternalIdType
  collection: string
  encoding: string
}

export enum ExternalIdType {
  Bankrate = 'br_lead_id',
}

/** Custom Server Side Events */

export type CustomMakeEvent = {
  cohesionContext: CohesionContext
  BSMLoanNumber?: string
}

export type ErrorEvent = {
  cohesionContext: CohesionContext
  statusCode: number
  errorMessage: string
}

export type CohesionContext = {
  anonymousId: string
  instanceId: string
  ip: string
  userAgent: string
  authenticatedUserId: string
  transitId?: string
  pageUrl: string
  pageName: string
  sessionId: string
}

export type AuthContext = {
  authTimestamp: string
  applicationId: string
  applicationName: string
  authId: string
  authUserName: string
  isMobile: boolean
  loginCount: number
  authTenant: string
  email: string
  userAgent: string
  ip: string
  correlationId: string
  rnaTenantId: string
  sessionId: string
}

export type SageContext = {
  qLeadID: string
  externalLeadID: string
  externalLeadSource: string
  qApplicationID: string
  externalLoanID: string
  externalLoanSource: string
  lenderLoanID: string
  lenderSource: string
}

export type NewSageContext = {
  leadID: string
  externalLeadID: string
  externalLeadSource: string
  applicationID: string
}

/** LoanTek Events */

export type LoanTekCallEvent = {
  includeInterestOnly?: boolean
  loanPurpose: string
  loanAmount: number
  loanToValue?: number
  cashOut?: string
  propertyType: string
  propertyUse: string
  lockPeriod?: number
  creditScore?: number
  zipCode: string
  cohesionContext: CohesionContext
}

export type LoanTekResponseEvent = {
  cohesionContext: CohesionContext
  rates: LoanTekRateTrackingData[]
}

export type LoanTekRateTrackingData = {
  rate: number
  apr: number
  family: string
  term: string
  type: string
  fees: [
    {
      description: string
      amount: number
    }
  ]
  loanPurpose: string
  originationPoints: number
  loanAmount: number
  monthlyPayment: number
  totalLenderFees: number
  discountPointsAsDollarAmount: number
}

/** ./LoanTek Events */

export type BeSmarteePortalEvent = {
  LoanNumber: string
  SignupDate?: string
  ApplicationCreationDate?: string
  ApplicationSubmittedDate?: string
  Status: string
  TRIDCount?: number
  SendTo?: string
  Broker: string
  StatusDate?: string
  Purpose: string
  LoanOfficer?: string
  RateLockStatus: string
  LockPeriod: string
  ActivityDate?: string
  RegisterDate?: string
  LoanAmount: number
  Processor?: string
  LoanOfficerAssistant?: string
  Closer?: string
  Underwriter?: string
  Messages: string
  DisclosuresSigned?: string
  Alerts?: string
  IntakeMethod?: string
}

export type LoanOfficerAssignmentEvent = {
  sageContext: SageContext
  cohesionContext?: CohesionContext
  anonymousId: string
  userId: string
  borrowerFirstName: string
  borrowerLastName: string
  LOFirstName: string
  LOLastName: string
  LOPhoneNumber: string
  LOHeadshot: string
  LOEmail: string
  NMLS: string
  LOId: string
  routingPolicy: string
}

export type LeadReceivedEvent = {
  sageContext: SageContext
  authenticatedUserID: string
  referrerURL: string
  loanType: string
  loanAmount: number
  cashOutAmount: number
  propertyStateAbr: string
  APRSelection: string
  propertyType: string
  device: string
  propertyUse: string
  propertyZip: string
  creditScore: string
  anonymousId: string
}

export type ApplicationStartedEvent = {
  sageContext: SageContext
  cohesionContext: CohesionContext
  authenticatedUserId: string
  anonymousId: string
  applicationStartedAt: Date | string
}

export type ApplicationStartedEventV2 = {
  activityDateTime: Date | string
  applicationContext: ApplicationContext
  webContext: WebContext
  borrowerID?: string
}

export type LOAssignedEventV2 = ApplicationStartedEventV2 & {
  loanOfficerID: string
  loAssignedId: string
}

export type ProcessorAssignedEventV2 = {
  activityDateTime: Date | string
  applicationContext: ApplicationContext
  processorID: string
  processorAssignedId: string
}

export type AccountCreated = {
  activityDateTime: Date | string
  applicationContext: ApplicationContext
  webContext: WebContext
  auth0ID?: string
}

export type PrequalRevisionEvent = {
  applicationContext: ApplicationContext
  activityDateTime: Date | string
  prequalID: string
  prequalExpiration: Date | string
  maxPurchasePrice: number
  adjustedPurchasePrice: number
  propertyUse: string
  adjustedDownPayment: number
  loanAmount: number
  propertyType: string
  maxDownPayment: number
}

export type ApplicationRevisedEvent = {
  sageContext: SageContext
  cohesionContext: CohesionContext
  authenticatedUserID: string
  anonymousId: string
  loanToValue: number
  debtToIncome: number
  creditScore: number
  propertyValue: number
  propertyUse: string
  propertyType: string
  cashOutAmount: number
  loanAmount: number
  loanPurpose: string
  propertyStateAbr: string
  remainingBalance: number
  revisedAt: string
}

export type ApplicationStatusUpdatedEvent = {
  sageContext: SageContext
  cohesionContext?: CohesionContext
  authenticatedUserId: string
  anonymousId: string
  status: string
  statusSource: string
  statusHistory?: string
  statusSyncedAt: string
}

export type WelcomeEmailEvent = {
  sageContext: NewSageContext
  cohesionContext: CohesionContext
  borrowerFirstName: string
  borrowerLastName: string
  borrowerEmail: string
  borrowerPhone: string
  borrowerApplicationURL: string
  subjectPropertyState: string
  LOName: string
  LOPhoneNumber: string
  LOEmail: string
  LONMLS: string
}

export type VelocifyApplicationStatusUpdatedEvent = {
  cohesionContext?: CohesionContext
  authenticatedUserId: string
  anonymousId: string
  status: string
  statusSyncedAt: string
}

export type TrackingAttributes = {
  bankrateLeadId?: string
  leadId?: number
  fBLeadId?: string
  gclid?: string
  questionnaireType?: string
  'cohesion-tenant-id'?: string
  'cohesion-anonymous-id'?: string
  'cohesion-transit-id'?: string
  'cohesion-session-id'?: string
  'cohesion-instance-id'?: string
  'webcontext-authenticate-user-id'?: string
  'webcontext-from-manual'?: string
  'webcontext-library-name'?: string
  'webcontext-library-version'?: string
  'webcontext-manual-instance-id'?: string
  'webcontext-page-referrer'?: string
  'webcontext-page-title'?: string
  'webcontext-page-url'?: string
  'webcontext-page-revision-id'?: string
  'webcontext-page-page-type'?: string
  'webcontext-page-post-id'?: string
  'webcontext-page-timezone-offset'?: string
  'webcontext-cross-site-id'?: string
  'webcontext-hash-id'?: string
  'webcontext-cohesion-consent'?: string
  'webcontext-cookie-consent-essential'?: string
  'webcontext-cookie-consent-performance'?: string
  'webcontext-cookie-consent-preference'?: string
  'webcontext-cookie-consent-targeting'?: string
  'webcontext-cookie-consent-social'?: string
  referrer?: string
}

export interface VelocifyLeadUpdatedRecord {
  Status?: string
  StatusDate?: string
  authenticatedUserID?: string
  ApplicationReceivedDate?: string
  LeadSource?: string
  Purpose?: string
  LoanAmount?: number
  propertyStateAbr?: string
  LoanOfficer?: string
  LoanOfficerId?: string
  ContactType?: string
  ContactDate?: string
  SentToLos?: string
  qApplicationContext?: {
    qLeadID?: string
    qApplicationID?: string
    externalLeadID?: string
    externalLeadSource?: string
    externalLoanID?: string
    externalLoanSource?: string
    lenderLoanID?: string
    lenderSource?: string
  }
}

export interface VelocifyLeadCompletedEvent {
  firstName?: string
  lastName?: string
  address?: string
  city?: string
  state?: string
  zip?: string
  phoneNumber?: string
  qcontext?: {
    qLeadID?: string
    qApplicationID?: string
    externalLeadID?: string
    externalLeadSource?: string
    externalLoanID?: string
    externalLoanSource?: string
    lenderLoanID?: string
    lenderSource?: string
  }
}

export interface BorrowerPortalContext {
  borrowerName?: string
  borrowerAppId?: string
  processorAssignedId?: string
  LOAssignedId?: string
  status?: string
  lender?: string
  leadSource?: string
}

export interface MessageSentEvent {
  borrowerPortalContext: BorrowerPortalContext
  messageText: string
  messageSender: string
}

export interface ProcessorAssignedEvent {
  borrowerPortalContext: BorrowerPortalContext
}

export interface DocumentUploadedEvent {
  borrowerPortalContext: BorrowerPortalContext
  documentList?: Document[]
}

export interface Condition {
  conditionName?: string
  id?: number
  uuid?: string
  title?: string
  description?: string
  created_at?: Date
  updated_at?: Date
  expiration_date?: Date
  type?: string
  co_borrower_application_id?: number
  documentRequests?: ConditionDocument[]
  application_id?: number
}

export interface ConditionDocument {
  id: number
  uuid: string
  title: string
  status: DocumentRequestStatus
  created_at?: Date
  updated_at?: Date
  filename?: string
}

export enum DocumentRequestStatus {
  Requested = 'requested',
  Incomplete = 'incomplete',
  Received = 'received',
  Rerequested = 'rerequested',
  Fulfilled = 'fulfilled',
}
export interface LoanLockedEvent {
  borrowerPortalContext: BorrowerPortalContext
  conditionList: Condition[]
}

interface Document {
  documentName: string
  documentAction?: 'accepted' | 'rejected'
}
export interface DocumentAddedEvent {
  borrowerPortalContext: BorrowerPortalContext
  documentList?: Document[]
}

export interface DocumentReviewedEvent {
  borrowerPortalContext: BorrowerPortalContext
  documentList?: Document[]
}

export interface StatusUpdatedEvent {
  borrowerPortalContext: BorrowerPortalContext
}

export interface BorrowerLoginEvent {
  borrowerPortalContext: BorrowerPortalContext
}

export interface ElementTrackingEvent {
  webElement: WebElement
  actionOutcome?: string
  outBoundUrl?: string
}

export interface PageTrackingEvent {
  payload: object
}

export interface Product {
  brand: string
  category: string
  coupon: string
  location: string
  name: string
  position: number
  price: number
  quantity: number
  upc: string
  variant: string
  productId: string
}

export interface ProductClickedEvent {
  product: Product
  listId: string
}

export interface ProductViewedEvent {
  product: Product
  listId: string
}

export interface ConditionEvent {
  conditionID: string
  applicationContext: ApplicationContext
  conditionTitle?: string
  conditionDescription?: string
  expirationDate?: string
  activityDateTime: string
  conditionType?: string
  conditionActionID?: string
  actionType?: string
  actionDetail?: string
  borrowerID?: string
  coBorrowerID?: string
}

export enum ActionType {
  StatusUpdate = 'status',
  NoteUpdate = 'note',
}

export type LockDeskCompleteEvent = {
  activityDateTime: Date | string
  applicationContext: ApplicationContext
  lockDeskCompletedID: string
  lockedRateID: string
  pricingScenarioID: string
  administrator: string
}

export type LoanToUnderwriting = {
  applicationContext: ApplicationContext
  loanContext: LoanContext
  activityDateTime: Date | string
  loanToUnderwritingID: string
}

export type LoanFunded = {
  applicationContext: ApplicationContext
  loanContext: LoanContext
  activityDateTime: Date | string
  lockedRateID: string
}

export type LoanEsigned = {
  applicationContext: ApplicationContext
  loanContext: LoanContext
  activityDateTime: Date | string
}

export type LoanConditionallyApproved = {
  applicationContext: ApplicationContext
  loanContext: LoanContext
  activityDateTime: Date | string
  conditionallyApprovedID: string
}

export type LoanClearToClose = {
  applicationContext: ApplicationContext
  loanContext: LoanContext
  activityDateTime: Date | string
  clearToCloseID: string
}

export type LenderLocked = {
  applicationContext: ApplicationContext
  loanContext: LoanContext
  activityDateTime: Date | string
  openingLoanOfficerID: string
  closingLoanOfficerID: string
  lockExpirationDate: Date | string
}

export type LockedRate = {
  applicationContext: ApplicationContext
  activityDateTime: Date | string
  lockedRateID: string
  adjustmentsTotal: number
  apr: number
  cashOut: number
  combinedLoanToValue: number
  discountOrCreditAmount: number
  discountPoints: number
  discountPointsAsDollarAmounts: number
  escrowFees: number
  family: string
  finalPrice: number
  lenderName: string
  loanAmount: number
  loanPurpose: string
  loanToValue: number
  lockPeriod: number
  originationPoint: number
  propertyValue: number
  quoteType: string
  rate: number
  term: string
  totalLenderFees: number
  type: string
}

export type ApplicationRevised = {
  applicationRevisionID: string
  applicationID: string
  nextRevision: string
  firstNameIndicator: boolean
  lastNameIndicator: boolean
  borrowerID: string
  emailIndicator: boolean
  addressIndicator: boolean
  address2Indicator: boolean
  cityIndicator: boolean
  stateIndicator: boolean
  zipIndicator: boolean
  loanToValue: string
  debtToIncome: string
  creditScore: string
  propertyValue: string
  propertyUse: string
  propertyType: string
  cashOut: string
  loanAmount: string
  iceBox: boolean
  notes: string
  createdAt: Date | string
  updatedAt: Date | string
  deletedAt: Date | string
  ssnAlias: string
  maritalStatus: string
  propertyYearBuilt: string
  propertyYearPurchased: string
  remainingBalance: string
  expenseRent: string
  expenseFirstMortgage: string
  expenseSecondMortgage: string
  expenseHomeInsurance: string
  expensePropertyTaxes: string
  expenseMortgageInsurance: string
  declarationJudgements: boolean
  declarationBankruptcy: boolean
  declarationForeclosures: boolean
  declarationLawsuits: boolean
  declarationLoanForeclosures: boolean
  declarationLoanDefaults: boolean
  declarationAlimony: boolean
  declarationBorrowedDown: boolean
  declarationNoteEndorser: boolean
  declarationCitizen: boolean
  declarationResident: boolean
  declarationPrimaryResidence: boolean
  declarationMilitary: boolean
  declarationVaLoan: boolean
  declarationFhaLoan: boolean
  declarationHomeOwnership: boolean
  declarationDependents: string
  declarationSchooling: string
  declarationPropertyType: string
  declarationPropertyTitle: string
  declarationGender: string[]
  declarationEthnicity: string[]
  declarationRace: string[]
  selfEmployed: boolean
  isCurrentAddress: boolean
  expenseHoaDues: string
  dateOfBirthIndicator: boolean
  titleHolderLegalNamesIndicator: boolean
  propertyAttachment: string
  propertyNumberOfStories: string
  propertyPurchasePrice: string
  propertyDownPayment: string
  loanPurpose: string
  termsOfUsePrivacyPolicyAndElectronicConsentAgreement: boolean
  affiliatedBusinessArrangementDisclosure: boolean
  tcpaDisclosure: boolean
  undisclosedBorrowedFundsIndicator: boolean
  undisclosedBorrowerFundsAmount: string
  originalCost: string
  declarationCitizenStatus: string
  nonSpousePropertyRights: boolean
  nonSpousePropertyRightsState: string
  nonSpousePropertyRightsType: string
  propertyCondoDesignType: string
  homeBusiness: boolean
  dependentsAges: string[]
  declarationUndisclosedCreditApplicationIndicator: boolean
  declarationPropertyProposedCleanEnergyLienIndicator: boolean
  declarationConveyedTitle: boolean
  declarationBankruptcyType: string
  secondPhoneIndicator: boolean
  desiredRateType: string
  otherAnnualIncomeAmount: string
  totalMonthlyExpenses: string
  creditedLoanOfficer: string
  lenderLoanAmount: string
  closingDisclosureSentDate: Date | string
  closingDisclosureReceivedDate: Date | string
  adverseLoanComplete: boolean
  tilIntlDisclosureProvidedDate: Date | string
  submittedToUwDate: Date | string
  underwritingApprovalDate: Date | string
  clearToCloseDate: Date | string
  docsOutDate: Date | string
  lenderLoCompensation: string
  refiReason: string
  timeInHome: string
  escrow: boolean
  rollInFees: boolean
  forbearance: boolean
  militaryDetails: string[]
  militaryServiceMember: string
  militaryServiceTourDate: Date | string
  adverseActionDate: Date | string
  borrowerSubmittedDate: Date | string
  isRollInEscrow: boolean
  purchaseStage: string
  autoCompleteAddressFields: string
  titleMannerHeld: string
  proposedExpenseFirstMortgage: string
  proposedExpenseSecondMortgage: string
  proposedExpenseHomeInsurance: string
  proposedExpenseMortgageInsurance: string
  proposedExpenseHoaDues: string
  proposedExpensePropertyTaxes: string
  submissionAuthorization: boolean
  termsAndCreditAuthorization: boolean
  purchaseKnownAddress: boolean
  sellerCredit: string
  dueDiligence: string
  earnestMoney: string
  expectedRentalIncome: string
  jointApplicantIntention: boolean
  firstTimeBuyer: boolean
  annualHouseholdIncome: string
  moneyDebtPayments: string
  totalAssets: string
  latestLoanDate: Date | string
  latestLoanAmount: string
  originalLoanTerm: string
  amortizationType: string
  interestRate: string
  closingDate: Date | string
  declarationRelationWithSeller: boolean
  cashOutOptions: string
  promotionMentioned: boolean
  offerAccepted: boolean
}

export type IdentityV1Event = {
  authContext: AuthContext
  webContext: WebContext
}
