import { StoreModel } from './index'
import { action, Action, thunk, Thunk } from 'easy-peasy'
import pricingAnswers from '@mortgage-pos/ui/data/answersForPricing'

import { AlfalfaOutboundEvent } from '@mortgage-pos/types'

export interface AlfalfaExtendedFeaturesModel {
  isLoadingAlfalfaFeatures: boolean
  setIsLoadingAlfalfaFeatures: Action<AlfalfaExtendedFeaturesModel, boolean>
  skipToPricing: Thunk<AlfalfaExtendedFeaturesModel, null, object, StoreModel>
  extendedEventTypeActionMap: Partial<Record<AlfalfaOutboundEvent, string>>
}

const alfalfaExtendedFeatures = (): AlfalfaExtendedFeaturesModel => {
  return {
    isLoadingAlfalfaFeatures: false,

    setIsLoadingAlfalfaFeatures: action((state, payload) => {
      state.isLoadingAlfalfaFeatures = payload
    }),

    extendedEventTypeActionMap: {
      [AlfalfaOutboundEvent.SkipToPricing]: 'skipToPricing',
    },

    skipToPricing: thunk(
      async (
        { setIsLoadingAlfalfaFeatures },
        __,
        { getStoreActions, getStoreState }
      ) => {
        const { pages, slug, isSplitFlow } = getStoreState().questionnaire
        const { answer, setPageIndex } = getStoreActions().questionnaire
        const { softPullCredit } = getStoreActions().application

        setIsLoadingAlfalfaFeatures(true)
        try {
          const ntlQualificationPageIndex = pages?.findIndex((page) => {
            return page?.name === 'ntlQualification'
          })

          if (ntlQualificationPageIndex < 0) {
            setIsLoadingAlfalfaFeatures(false)
            console.log('Alfalfa: Could not find NTL Qualification page')
            return
          }

          const answerSet = pricingAnswers[slug]
            ? pricingAnswers[slug]
            : pricingAnswers.ntl

          await answer(answerSet)
          if (isSplitFlow) {
            await softPullCredit()
          }
          await setPageIndex(ntlQualificationPageIndex)
        } catch (error) {
          console.error('Alfalfa Error: skipToPricing ', error)
        }

        setIsLoadingAlfalfaFeatures(false)
      }
    ),
  }
}

export default alfalfaExtendedFeatures
