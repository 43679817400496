import { FEATURE_FLAG_TYPES } from '@mortgage-pos/data'
import { VisibleIfPrebuiltCondition } from '@mortgage-pos/types'
import get from 'lodash/get'
import { useVariation } from '@mortgage-pos/ui/services/featureFlags'
import rollbar from '@mortgage-pos/ui/services/rollbar'

function hasSpecialCondition(answer) {
  if (typeof answer !== 'string') {
    return false
  }

  return answer.includes('{{')
}

function evaluateSpecialCondition(answer, questionName, answers) {
  // Extract the token from the double curlies: {{thing}} => thing
  const specialCondition = answer
    .match(/{{(.*?)}}/g)[0]
    .replace(/{{|}}/g, '')
    .trim()

  const actualAnswer = get(answers, questionName)

  if (specialCondition.toLowerCase() === 'notanswered') {
    return !!actualAnswer === false
  }

  if (specialCondition.toLowerCase() === 'answered') {
    return !!actualAnswer === true
  }

  throw new Error(`Special condition failed with: ${specialCondition}`)
}

function convertBooleans(answer) {
  if (answer === 'true') {
    return true
  }

  if (answer === 'false') {
    return false
  }

  return answer
}

function isJson(condition) {
  if (typeof condition !== 'string') {
    return false
  }

  try {
    JSON.parse(condition)
    return true
  } catch (error) {
    return false
  }
}

const numericStringRegexes = [
  '^0{1}$', // The number 0
  '^[1-9]{1}[0-9]*(?![\\.])$', // Whole numbers
  '^0(?![0-9])\\.{1}[0-9]+$', // Decimals starting with zero (i.e 0.123)
  '^[1-9]+[0-9]*\\.{1}[0-9]+$', // Decimals starting with non-zero (i.e. 1.123)
  '^\\.{1}[0-9]+$', // Decimals starting with decimal point
]

const validNumericString = new RegExp(`${numericStringRegexes.join('|')}`)

export function typecastAnswer(answer: any) {
  if (typeof answer !== 'string') {
    return answer
  }

  if (answer.match(validNumericString)) {
    return Number(answer)
  }

  const answerClean = answer.toLowerCase().trim()

  if (answerClean === 'false') {
    return false
  }

  if (answerClean === 'true') {
    return true
  }

  if (answerClean === 'null') {
    return null
  }

  return answer
}

function evaluateStringCondition(condition, answers) {
  const splitCondition = condition.split('=').map((item) => item.trim())
  const questionName = splitCondition[0]
  const answer = splitCondition[1]

  if (hasSpecialCondition(answer)) {
    return evaluateSpecialCondition(answer, questionName, answers)
  }

  return answers[questionName] === convertBooleans(answer)
}

export function isVisibleIfConstructor(visibleIfConstructor) {
  if (!visibleIfConstructor) return false
  if (!Array.isArray(visibleIfConstructor)) return false
  if (visibleIfConstructor.length > 0) return true
}

export function isPrebuiltCondition(condition) {
  return condition
    ? Object.prototype.hasOwnProperty.call(condition, 'name')
    : false
}

export function isMultiCondition(condition) {
  return condition
    ? Object.prototype.hasOwnProperty.call(condition, 'operator') &&
        Object.prototype.hasOwnProperty.call(condition, 'conditions')
    : false
}

export function isSingleCondition(condition) {
  return (
    Object.prototype.hasOwnProperty.call(condition, 'question') &&
    Object.prototype.hasOwnProperty.call(condition, 'conditional') &&
    Object.prototype.hasOwnProperty.call(condition, 'answer')
  )
}

/**
 * Determines whether a user's current answers satisfy the requirements for showing a given
 * question on the lead form
 *
 * @param {String || Object} condition - rule for deciding whether or not to show the question
 * @param {Object} answers - set of current answers (key: questionName, value: answer)
 * @param {Object} prebuiltConditions - list of prebuilt conditions that match Storyblok's datasource, must have a 'name' prop
 * @returns {Boolean} questionVisibility - should we show the current question?
 *
 * NOTE: There are 3 ways to format conditions:
 *    - (1) String condition -> `${question} = ${answer}`
 *    - (2) Multi condition -> { operator {String: '&&' or '||'}, conditions {Array} }
 *    - (3) Single condition -> { question, conditional, answer }
 */
export function isQuestionVisible(condition, answers, prebuiltConditions = {}) {
  if (!condition) return true

  if (isJson(condition)) {
    const parsedCondition = JSON.parse(condition)
    return isQuestionVisible(parsedCondition, answers, prebuiltConditions)
  }

  if (typeof condition === 'string') {
    return evaluateStringCondition(condition, answers)
  }

  if (isPrebuiltCondition(condition)) {
    const prebuiltCondition =
      prebuiltConditions[(condition as VisibleIfPrebuiltCondition).name]

    if (prebuiltCondition === undefined) {
      rollbar.error('Invalid prebuilt visibleIf condition provided', {
        condition,
      })
      return false
    }

    return typeof prebuiltCondition === 'function'
      ? prebuiltCondition()
      : prebuiltCondition
  }

  // Given an array of conditions, recurse through them, evaluating against the given operator
  if (isMultiCondition(condition)) {
    const { operator, conditions } = condition
    const questionVisibility = operator === '||' ? false : true
    const operation = `questionVisibility ${operator} isQuestionVisible(condition, answers)`

    return conditions.reduce(
      (questionVisibility, condition) => eval(operation),
      questionVisibility
    )
  }

  // Conditional can be any logical comparator (i.e. ===, ==, !==, !=)
  if (isSingleCondition(condition)) {
    const { question, conditional, answer } = condition

    if (hasSpecialCondition(answer)) {
      return evaluateSpecialCondition(answer, question, answers)
    }

    const castedAnswer = typecastAnswer(answer)
    const actualAnswer = get(answers, question)
    return eval(`actualAnswer ${conditional} castedAnswer`)
  }
}

export const shouldShowQuestion = (
  values,
  visibilityRule = null,
  prebuiltValidations
) => {
  return visibilityRule
    ? isQuestionVisible(visibilityRule, values, prebuiltValidations)
    : true
}

export const useShouldShowQuestion = (
  values,
  question,
  prebuiltValidations
) => {
  const visibleIfCondition = question.visibleIfConstructor?.[0]

  return shouldShowQuestion(values, visibleIfCondition, prebuiltValidations)
}
