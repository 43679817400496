import { RequestHandler } from 'express'
import axios, { AxiosResponse } from 'axios'

export function hasAxiosErrors(response: AxiosResponse) {
  return response?.data?.errors?.length > 0
}

export function handleAsyncErrs(routeHandler: RequestHandler): RequestHandler {
  return async (req, res, next) => {
    try {
      await routeHandler(req, res, next)
    } catch (err) {
      next(err)
    }
  }
}

// Make Axios mockable
export const axiosHttp = axios.create()
