let urlSpy = ''

export const redirectedTo = () => urlSpy
export const resetRedirectUrl = () => (urlSpy = '')

export function redirectTo(url) {
  if (process.env.NODE_ENV === 'test') {
    urlSpy = url
    return
  }

  window.location.href = url
}
