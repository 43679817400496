import {
  Questions,
  AddressType,
  QuestionType,
  QuestionRadio,
  QuestionGroup,
  QuestionInput,
  QuestionHidden,
  QuestionCheckbox,
  QuestionBinaryButton,
  QuestionCheckboxMulti,
  AddressWithMoveInDate,
} from '@mortgage-pos/types'

export const componentIsQuestionGroup = (componentName) => {
  return (
    componentName === QuestionType.QuestionGroup ||
    componentName === QuestionType.RepeatableQuestionGroup ||
    componentName === QuestionType.CreateAccount
  )
}

export const calculateDefaultValue = (question: Questions) => {
  if (question.component === QuestionType.Hidden) {
    const value = (question as QuestionHidden).value

    return value === 'null' ? null : value
  }

  if (question.component === QuestionType.Checkbox) {
    return JSON.parse((question as QuestionCheckbox).checked)
  }

  if (question.component === QuestionType.Radio) {
    return (question as QuestionRadio).defaultValue
  }

  if (
    question.component === QuestionType.Input &&
    ((question as QuestionInput).inputType === 'currency' ||
      (question as QuestionInput).inputType === 'number' ||
      (question as QuestionInput).inputType === 'date')
  ) {
    return null
  }

  if (question.component === QuestionType.CheckboxMulti) {
    if (!(question as QuestionCheckboxMulti).defaultOption) {
      return []
    }

    return [(question as QuestionCheckboxMulti).defaultOption]
  }

  if (question.component === QuestionType.BinaryButton) {
    return (question as QuestionBinaryButton).defaultYes ? 'Yes' : 'No'
  }

  if (componentIsQuestionGroup(question.component)) {
    return [buildQuestionGroup((question as QuestionGroup).questions)]
  }

  return ''
}

export const buildQuestionGroup = (questions: Questions[]) =>
  questions.reduce((questionGroup, question: Questions) => {
    const nestedQuestions = getNestedQuestions(question)
    for (const q of nestedQuestions) {
      questionGroup[q.questionName.name] = calculateDefaultValue(q)
    }
    return questionGroup
  }, {})

export const getNestedQuestions = (question: Questions, result = []) => {
  for (const [key, value] of Object.entries(question)) {
    if (key === 'questionName') {
      result.push(question)
    }
    if (value) {
      if (Array.isArray(value)) {
        value.forEach((value) => {
          getNestedQuestions(value, result)
        })
      }
      if (value.constructor.name === 'Object') {
        getNestedQuestions(value, result)
      }
    }
  }
  return result
}

export const mapAddressGroupValues = (question, answers, livesAtKey) => {
  const [relatedGroupName, index] = question.questionName.name.split('+')

  const getAddressesByType = (type) => {
    const addressesByType = answers[relatedGroupName].filter(
      (address) => address && address.type === type
    )

    // If no addresses matching given type, always fallback to default value
    return addressesByType.length > 0
      ? addressesByType
      : calculateDefaultValue(question)
  }

  // Has a different key for primary borrower vs co-borrower
  const livesAtPropertyAddress = answers[livesAtKey] === 'Yes'

  // (* Notes on case '1' and '2' *)
  // addresses+1 and addresses+2 are essentially the same form (gathering previous addresses)
  // but their visibility is based upon the answer to isCurrentAddress for user experience purposes.
  // When a user returns to the page, we need to make sure the right form gets the previous address
  // answers and that the other is given a default value.
  switch (index) {
    case '0':
      return getAddressesByType(AddressType.Current)
    case '1':
      return livesAtPropertyAddress
        ? getAddressesByType(AddressType.Previous)
        : calculateDefaultValue(question)
    case '2':
      return livesAtPropertyAddress
        ? calculateDefaultValue(question)
        : getAddressesByType(AddressType.Previous)
    default:
      break
  }
}

export const getAutocompleteFillAddressValues = (preFilledAnswers) => {
  // TO:DO Extend this logic for question groups

  const {
    autoCompleteAddressFields = '',
    street = '',
    apt = '',
    state = '',
    city = '',
    zipCode = '',
  } = preFilledAnswers || {}

  return { autoCompleteAddressFields, street, apt, state, city, zipCode }
}

export const buildPreviousAddresses = ({
  stepAnswers,
}): Array<AddressWithMoveInDate> => {
  const repeatable: Array<AddressWithMoveInDate> =
    generateAutoCompleteAddressAnswers({ stepAnswers })
  const nonRepeatable: Array<AddressWithMoveInDate> =
    stepAnswers?.addresses.filter(
      (address) => address.type === AddressType.Previous
    )

  // no previous addresses
  if (repeatable?.length === 0 && nonRepeatable?.length === 0) {
    return []
  }

  // manual inputs only
  if (nonRepeatable?.length > 0 && repeatable?.length === 0) {
    return nonRepeatable
  }

  // autocomplete inputs only
  if (repeatable?.length > 0 && nonRepeatable?.length === 0) {
    return repeatable
  }

  // dedupe arrays based on move in date. we won't have IDs at this time.
  return [...repeatable, ...nonRepeatable].filter(
    (v, i, a) => a.findIndex((v2) => v2?.moveInDate === v?.moveInDate) === i
  )
}

const generateAutoCompleteAddressAnswers = ({ stepAnswers }) => {
  const repeatableAddressGroups = ['addresses+1', 'addresses+2']
  return repeatableAddressGroups.flatMap((repeatableGroup) => {
    const group = stepAnswers[repeatableGroup]
    if (!group || group.length < 1) return []
    return group
      .filter(({ zipCode }) => zipCode?.length > 0)
      .map(({ zipCode, city, state, apt, street, moveInDate }) => ({
        zipCode,
        city,
        state,
        apt,
        street,
        moveInDate,
        type: AddressType.Previous,
      }))
  }, [])
}
