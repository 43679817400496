import { PropertyTypes, PropertyUses } from '@mortgage-pos/types'

const multiUnitPropertyTypes = [
  PropertyTypes.MultiFamily2Units,
  PropertyTypes.MultiFamily3Units,
  PropertyTypes.MultiFamily4Units,
]

export const calculateMaxCashout = ({
  estimatedValue,
  remainingBalance,
  propertyType,
  propertyUse,
}) => {
  let LTV
  if (!estimatedValue || !remainingBalance || !propertyType || !propertyUse) {
    return 0
  } else if (
    multiUnitPropertyTypes.includes(propertyType) &&
    PropertyUses.SecondaryOrVacation === propertyUse
  ) {
    return 0
  } else if (
    multiUnitPropertyTypes.includes(propertyType) &&
    PropertyUses.InvestmentOrRental === propertyUse
  ) {
    LTV = 0.7
  } else {
    LTV = 0.75
  }

  const maxLoanAmount = Math.floor(estimatedValue * LTV)
  const maxCashout = maxLoanAmount - remainingBalance

  return maxCashout
}
