export type RefinanceMortgageFormValues = {
  fullName?: { firstName: string; lastName: string }
  zipCode?: string
  loanType: CplLoanType
  housePurchaseYear?: CplHomePurchaseYearRange
  propertyType?: CplPropertyType
  propertyEstimatedValueRange?: CplEstimatedValueRange
  firstMortgageBalanceRange?: CplMortgageBalanceRange
  firstMortgageRate?: CplFirstMortgageRate
  existingRateType?: CplRateType
  hasSecondMortgage?: CplYesOrNo
  creditProfileDescription?: CplCreditProfileDescription
  propertyUse?: CplPropertyUse
  additionalCashRange?: CplAdditionalCashRange
  hasFHALoan?: CplYesOrNo
  hasFiledBankruptcy?: CplYesOrNo
  hasLatePayments?: CplHasLatePayments
  employmentStatus?: CplEmploymentStatus
  averageMonthlyIncomeRange?: CplAverageMonthlyIncomeRange
  averageMonthlyExpensesRange?: CplAverageMonthlyExpensesRange
  hasProofIncome?: CplYesOrNo
}

export enum CplLoanType {
  Refinance = 'Home Refinance',
  Purchase = 'Home Purchase',
}

export enum CplHomePurchaseYearRange {
  TwentyTenToPresent = '2010 - 2022',
  TwoThousandToTwoThousandNine = '2000 - 2009',
  NinetyToNinetyNine = '1990 - 1999',
  BeforeNinety = 'Before 1990',
}

export enum CplPropertyType {
  SingleFamily = 'Single Family Home',
  MultiFamily = 'Multi-Family Home',
  Condominium = 'Condominium',
  TownHome = 'Townhome',
}

export enum CplEstimatedValueRange {
  EightyOrLess = '$80,000 or less',
  EightyFive = '$80,001 to $85,000',
  Ninety = '$85,001 to $90,000',
  NinetyFive = '$90,001 to $95,000',
  OneHundred = '$95,001 to $100,000',
  OneTwenty = '$100,001 to $120,000',
  OneForty = '$120,001 to $140,000',
  OneSixty = '$140,001 to $160,000',
  OneEighty = '$160,001 to $180,000',
  TwoHundred = '$180,001 to $200,000',
  TwoTwenty = '$200,001 to $220,000',
  TwoForty = '$220,001 to $240,000',
  TwoSixty = '$240,001 to $260,000',
  TwoEighty = '$260,001 to $280,000',
  ThreeHundred = '$280,001 to $300,000',
  ThreeTwenty = '$300,001 to $320,000',
  ThreeFourty = '$320,001 to $340,000',
  ThreeSixty = '$340,001 to $360,000',
  ThreeEighty = '$360,001 to $380,000',
  FourHundred = '$380,001 to $400,000',
  FourTwenty = '$400,001 to $420,000',
  FourFourty = '$420,001 to $440,000',
  FourSixty = '$440,001 to $460,000',
  FourEighty = '$460,001 to $480,000',
  FiveHundred = '$480,001 to $500,000',
  FiveTwenty = '$500,001 to $520,000',
  FiveFourty = '$520,001 to $540,000',
  FiveSixty = '$540,001 to $560,000',
  FiveEighty = '$560,001 to $580,000',
  SixHundred = '$580,001 to $600,000',
  SixTwenty = '$600,001 to $620,000',
  SixFourty = '$620,001 to $640,000',
  SixSixty = '$640,001 to $660,000',
  SixEighty = '$660,001 to $680,000',
  SevenHundred = '$680,001 to $700,000',
  SevenTwenty = '$700,001 to $720,000',
  SevenFourty = '$720,001 to $740,000',
  SevenSixty = '$740,001 to $760,000',
  SevenEighty = '$760,001 to $780,000',
  EightHundred = '$780,001 to $800,000',
  EightTwenty = '$800,001 to $820,000',
  EightFourty = '$820,001 to $840,000',
  EightSixty = '$840,001 to $860,000',
  EightEighty = '$860,001 to $880,000',
  NineHundred = '$880,001 to $900,000',
  NineTwenty = '$900,001 to $920,000',
  NineFourty = '$920,001 to $940,000',
  NineSixty = '$940,001 to $960,000',
  NineEighty = '$960,001 to $980,000',
  OneMillion = '$980,001 to $1,000,000',
  OneMillionOne = '$1,000,001 to $1,100,000',
  OneMillionTwo = '$1,100,001 to $1,200,000',
  OneMillionThree = '$1,200,001 to $1,300,000',
  OneMillionFour = '$1,300,001 to $1,400,000',
  OneMillionFive = '$1,400,001 to $1,500,000',
  OneMillionSix = '$1,500,001 to $1,600,000',
  OneMillionSeven = '$1,600,001 to $1,700,000',
  OneMillionEight = '$1,700,001 to $1,800,000',
  OneMillionNine = '$1,800,001 to $1,900,000',
  TwoMillion = '$1,900,001 to $2,000,000',
  OverTwoMillion = 'Over $2,000,000',
}

export enum CplMortgageBalanceRange {
  TenThousand = '0 to $10,000',
  TwentyThousand = '$10,001 to $20,000',
  ThirtyThousand = '$20,001 to $30,000',
  FourtyThousand = '$30,001 to $40,000',
  FiftyThousand = '$40,001 to $50,000',
  SixtyThousand = '$50,001 to $60,000',
  SeventyThousand = '$60,001 to $70,000',
  EightyThousand = '$70,001 to $80,000',
  NinetyThousand = '$80,001 to $90,000',
  OneHundred = '$90,001 to $100,000',
  OneTwenty = '$100,001 to $120,000',
  OneForty = '$120,001 to $140,000',
  OneSixty = '$140,001 to $160,000',
  OneEighty = '$160,001 to $180,000',
  TwoHundred = '$180,001 to $200,000',
  TwoTwenty = '$200,001 to $220,000',
  TwoForty = '$220,001 to $240,000',
  TwoSixty = '$240,001 to $260,000',
  TwoEighty = '$260,001 to $280,000',
  ThreeHundred = '$280,001 to $300,000',
  ThreeTwenty = '$300,001 to $320,000',
  ThreeFourty = '$320,001 to $340,000',
  ThreeSixty = '$340,001 to $360,000',
  ThreeEighty = '$360,001 to $380,000',
  FourHundred = '$380,001 to $400,000',
  FourTwenty = '$400,001 to $420,000',
  FourFourty = '$420,001 to $440,000',
  FourSixty = '$440,001 to $460,000',
  FourEighty = '$460,001 to $480,000',
  FiveHundred = '$480,001 to $500,000',
  FiveTwenty = '$500,001 to $520,000',
  FiveFourty = '$520,001 to $540,000',
  FiveSixty = '$540,001 to $560,000',
  FiveEighty = '$560,001 to $580,000',
  SixHundred = '$580,001 to $600,000',
  SixTwenty = '$600,001 to $620,000',
  SixFourty = '$620,001 to $640,000',
  SixSixty = '$640,001 to $660,000',
  SixEighty = '$660,001 to $680,000',
  SevenHundred = '$680,001 to $700,000',
  SevenTwenty = '$700,001 to $720,000',
  SevenFourty = '$720,001 to $740,000',
  SevenSixty = '$740,001 to $760,000',
  SevenEighty = '$760,001 to $780,000',
  EightHundred = '$780,001 to $800,000',
  EightTwenty = '$800,001 to $820,000',
  EightFourty = '$820,001 to $840,000',
  EightSixty = '$840,001 to $860,000',
  EightEighty = '$860,001 to $880,000',
  NineHundred = '$880,001 to $900,000',
  NineTwenty = '$900,001 to $920,000',
  NineFourty = '$920,001 to $940,000',
  NineSixty = '$940,001 to $960,000',
  NineEighty = '$960,001 to $980,000',
  OneMillion = '$980,001 to $1,000,000',
  OneMillionOne = '$1,000,001 to $1,100,000',
  OneMillionTwo = '$1,100,001 to $1,200,000',
  OneMillionThree = '$1,200,001 to $1,300,000',
  OneMillionFour = '$1,300,001 to $1,400,000',
  OneMillionFive = '$1,400,001 to $1,500,000',
  OneMillionSix = '$1,500,001 to $1,600,000',
  OneMillionSeven = '$1,600,001 to $1,700,000',
  OneMillionEight = '$1,700,001 to $1,800,000',
  OneMillionNine = '$1,800,001 to $1,900,000',
  TwoMillion = '$1,900,001 to $2,000,000',
  TwoMillionOne = '$2,000,001 to $2,100,000',
  TwoMillionTwo = '$2,100,001 to $2,200,000',
  TwoMillionThree = '$2,200,001 to $2,300,000',
  TwoMillionFour = '$2,300,001 to $2,400,000',
  TwoMillionFive = '$2,400,001 to $2,500,000',
  OverTwoMillionFive = 'Over $2,500,000',
}

export enum CplFirstMortgageRate {
  UnderTwo = 'less than 2%',
  Two = '2.00%',
  TwoTwoFive = '2.25%',
  TwoFiveZero = '2.50%',
  TwoSevenFive = '2.75%',
  Three = '3.00%',
  ThreeTwoFive = '3.25%',
  ThreeFiveZero = '3.50%',
  ThreeSevenFive = '3.75%',
  Four = '4.00%',
  FourTwoFive = '4.25%',
  FourFiveZero = '4.50%',
  FourSevenFive = '4.75%',
  Five = '5.00%',
  FiveTwoFive = '5.25%',
  FiveFiveZero = '5.50%',
  FiveSevenFive = '5.75%',
  Six = '6.00%',
  SixTwoTwoFive = '6.25%',
  SixTwoFiveZero = '6.50%',
  SixTwoSevenFive = '6.75%',
  Seven = '7.00%',
  SevenTwoTwoFive = '7.25%',
  SevenTwoFiveZero = '7.50%',
  SevenTwoSevenFive = '7.75%',
  Eight = '8.00%',
  EightTwoTwoFive = '8.25%',
  EightTwoFiveZero = '8.50%',
  EightTwoSevenFive = '8.75%',
  Nine = '9.00%',
  NineTwoTwoFive = '9.25%',
  NineTwoFiveZero = '9.50%',
  NineTwoSevenFive = '9.75%',
  Ten = '10.00%',
  TenTwoTwoFive = '10.25%',
  TenTwoFiveZero = '10.50%',
  TenTwoSevenFive = '10.75%',
  Eleven = '11.00%',
  ElevenTwoTwoFive = '11.25%',
  ElevenTwoFiveZero = '11.50%',
  ElevenTwoSevenFive = '11.75%',
  OverTwelve = '12% or more',
}

export enum CplRateType {
  Fixed = 'Fixed',
  Adjustable = 'Adjustable',
}

export enum CplYesOrNo {
  Yes = 'Yes',
  No = 'No',
}

export enum CplCreditProfileDescription {
  OverSevenFourty = '740+',
  SevenThirtyNine = '720 - 739',
  SevenNineteen = '700 - 719',
  SixNinetyNine = '680 - 699',
  SixSeventyNine = '660 - 679',
  SixFiftyNine = '640 - 659',
  SixThirtyNine = '620 - 639',
  SixNineteen = '580 - 619',
}

export enum CplPropertyUse {
  Primary = 'Primary Home',
  Secondary = 'Secondary Home',
  Rental = 'Rental Property',
}

export enum CplAdditionalCashRange {
  NoCash = '$0 (No Cash)',
  FiveThousand = '$0 to $5,000',
  TenThousand = '$5,001 to $10,000',
  FifteenThousand = '$10,001 to $15,000',
  TwentyThousand = '$15,001 to $20,000',
  TwentyFiveThousand = '$20,001 to $25,000',
  ThirtyThousand = '$25,001 to $30,000',
  ThirtyFiveThousand = '$30,001 to $35,000',
  FourtyThousand = '$35,001 to $40,000',
  FourtyFiveThousand = '$40,001 to $45,000',
  FiftyThousand = '$45,001 to $50,000',
  FiftyFiveThousand = '$50,001 to $55,000',
  SixtyThousand = '$55,001 to $60,000',
  SixtyFiveThousand = '$60,001 to $65,000',
  SeventyThousand = '$65,001 to $70,000',
  SeventyFiveThousand = '$70,001 to $75,000',
  EightyThousand = '$75,001 to $80,000',
  EightyFiveThousand = '$80,001 to $85,000',
  NinetyThousand = '$85,001 to $90,000',
  NinetyFiveThousand = '$90,001 to $95,000',
  OneHundred = '$95,001 to $100,000',
  OneTwenty = '$100,001 to $120,000',
  OneForty = '$120,001 to $140,000',
  OneSixty = '$140,001 to $160,000',
  OneEighty = '$160,001 to $180,000',
  TwoHundred = '$180,001 to $200,000',
  OverTwoHundred = 'Over $200,000',
}

export enum CplHasLatePayments {
  No = 'No',
  One = '1 Late Payment',
  OverTwo = '2+ LatePayments',
}

export enum CplEmploymentStatus {
  Employed = 'Employed',
  NotEmployed = 'Not Employed',
  SelfEmployed = 'Self Employed',
  Military = 'Military',
  Retired = 'Retired',
  Other = 'Other',
}

export enum CplAverageMonthlyIncomeRange {
  None = '$0',
  OneThousand = '$1 to $1,000',
  TwoThousand = '$1,001 to $2,000',
  ThreeThousand = '$2,001 to $3,000',
  FourThousand = '$3,001 to $4,000',
  FiveThousand = '$4,001 to $5,000',
  SevenThousandFive = '$5,001 to $7,500',
  TenThousand = '$7,501 to $10,000',
  TwelveThousandFive = '$10,001 to $12,500',
  FifteenThousand = '$12,501 to $15,000',
  TwentyThousand = '$15,001 to $20,000',
  TwentyFiveThousand = '$20,001 to $25,000',
  ThirtyThousand = '$25,001 to $30,000',
  ThirtyFiveThousand = '$30,001 to $35,000',
  FourtyThousand = '$35,001 to $40,000',
  FourtyFiveThousand = '$40,001 to $45,000',
  FiftyThousand = '$45,001 to $50,000',
  OverFiftyThousand = '$50,000 or More',
}

export enum CplAverageMonthlyExpensesRange {
  None = '$0',
  OneThousand = '$1 to $1,000',
  TwoThousand = '$1,001 to $2,000',
  ThreeThousand = '$2,001 to $3,000',
  FourThousand = '$3,001 to $4,000',
  FiveThousand = '$4,001 to $5,000',
  SevenThousandFive = '$5,001 to $7,500',
  TenThousand = '$7,501 to $10,000',
  TwelveThousandFive = '$10,001 to $12,500',
  FifteenThousand = '$12,501 to $15,000',
  TwentyThousand = '$15,001 to $20,000',
  TwentyFiveThousand = '$20,001 to $25,000',
  ThirtyThousand = '$25,001 to $30,000',
  ThirtyFiveThousand = '$30,001 to $35,000',
  FourtyThousand = '$35,001 to $40,000',
  FourtyFiveThousand = '$40,001 to $45,000',
  FiftyThousand = '$45,001 to $50,000',
  OverFiftyThousand = '$50,000 or More',
}
