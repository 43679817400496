export interface LoanCalculationsDecisioning {
  waiveEscrow: boolean
  escrowRollIn: boolean
  willRollInFees: boolean
}

export interface LoanCalculationsFinancials {
  cashOut: number
  totalFees: number
  totalEscrow: number
  lenderCredit?: number
  originationFee?: number
  taxAndPrepaids: number
  remainingBalance: number
}

export interface LoanCosts {
  lenderCredit: number
  totalFees: number
  finalClosingCosts: number
  principalCurtailment: number
  remainingBalance: number
  financedCostsDistribution: CostsDistribution
  financedCosts: number
  lockedLoanAmount: number
  cashToClose: number
}

export interface LenderCreditDistribution {
  totalFees: number
  taxAndPrepaids: number
  totalEscrow: number
  principalCurtailment: number
}

export interface CostsDistribution {
  total: number
  totalFees: number
  taxAndPrepaids: number
  totalEscrow: number
}

export class LoanCostsCalculator {
  // Decisioning
  private waiveEscrow: boolean
  private escrowRollIn: boolean
  private willRollInFees: boolean

  // Financials
  private cashOut: number
  private totalFees: number
  private totalEscrow: number
  private lenderCredit: number
  private originationFee: number
  private taxAndPrepaids: number
  private remainingBalance: number

  // Derived values
  private lenderCreditDistribution: LenderCreditDistribution
  private costsDistributions: Record<string, CostsDistribution>
  private finalClosingCosts: number

  constructor(
    loanCalcFinancials: LoanCalculationsFinancials,
    loanCalcDecisions: LoanCalculationsDecisioning
  ) {
    // Decisioning
    this.waiveEscrow = loanCalcDecisions.waiveEscrow
    this.escrowRollIn = loanCalcDecisions.escrowRollIn
    this.willRollInFees = loanCalcDecisions.willRollInFees

    // Financials
    this.cashOut = loanCalcFinancials.cashOut
    this.totalFees = loanCalcFinancials.totalFees
    this.totalEscrow = loanCalcFinancials.totalEscrow
    this.lenderCredit = loanCalcFinancials.lenderCredit ?? 0
    this.originationFee = loanCalcFinancials.originationFee ?? 0
    this.taxAndPrepaids = loanCalcFinancials.taxAndPrepaids
    this.remainingBalance = loanCalcFinancials.remainingBalance

    // Derived values (order of operations is important here)
    this.lenderCreditDistribution = this.distributeLenderCredit()
    this.costsDistributions = this.deriveCostsDistributions()
    this.finalClosingCosts = this.calcFinalClosingCosts()
  }

  public distributeLenderCredit(): LenderCreditDistribution {
    const { originationFee } = this
    let { lenderCredit, totalFees, totalEscrow, taxAndPrepaids } = this

    lenderCredit = Math.abs(lenderCredit)
    totalFees = totalFees - originationFee

    if (totalFees > 0) {
      const leftOverCredit = lenderCredit - totalFees
      totalFees = leftOverCredit < 0 ? totalFees - lenderCredit : 0
      lenderCredit = Math.max(0, leftOverCredit)
    }

    if (taxAndPrepaids > 0) {
      const leftOverCredit = lenderCredit - taxAndPrepaids
      taxAndPrepaids = leftOverCredit < 0 ? taxAndPrepaids - lenderCredit : 0
      lenderCredit = Math.max(0, leftOverCredit)
    }

    if (totalEscrow > 0) {
      const leftOverCredit = lenderCredit - totalEscrow
      totalEscrow = leftOverCredit < 0 ? totalEscrow - lenderCredit : 0
      lenderCredit = Math.max(0, leftOverCredit)
    }

    return {
      totalFees,
      taxAndPrepaids,
      totalEscrow,
      principalCurtailment: lenderCredit,
    }
  }

  public calcFinalClosingCosts() {
    const { originationFee } = this
    const { cash, rollIns } = this.costsDistributions
    const creditedClosingCosts = rollIns.total + cash.total

    // This can never be less than 0 since the final closing costs can't be any
    // less than (0 + the origination fee). Origination fees can't be
    // offset and must either be rolled-in or paid at closing
    const closingCostsPreOriginationFee = Math.max(
      creditedClosingCosts - originationFee,
      0
    )

    return closingCostsPreOriginationFee + originationFee
  }

  public deriveCostsDistributions(): Record<string, CostsDistribution> {
    const { originationFee } = this
    const { waiveEscrow, escrowRollIn, willRollInFees } = this

    // These are the amts after applying lender credit and origination fee
    const { totalFees, totalEscrow, taxAndPrepaids } =
      this.lenderCreditDistribution

    const costsDistributionMap = {
      rollIns: { total: 0, totalFees: 0, taxAndPrepaids: 0, totalEscrow: 0 },
      cash: { total: 0, totalFees: 0, taxAndPrepaids: 0, totalEscrow: 0 },
    }

    const { cash, rollIns } = costsDistributionMap

    if (!waiveEscrow) {
      if (escrowRollIn) {
        rollIns.totalEscrow = totalEscrow
        rollIns.total += totalEscrow
      } else {
        cash.totalEscrow = totalEscrow
        cash.total += totalEscrow
      }
    }

    if (willRollInFees) {
      rollIns.totalFees = totalFees + originationFee
      rollIns.taxAndPrepaids = taxAndPrepaids
      rollIns.total += totalFees + originationFee + taxAndPrepaids
    } else {
      cash.totalFees = totalFees + originationFee
      cash.taxAndPrepaids = taxAndPrepaids
      cash.total += totalFees + originationFee + taxAndPrepaids
    }

    return costsDistributionMap
  }

  public getLoanCosts(): LoanCosts {
    return {
      lenderCredit: this.lenderCredit,
      totalFees: this.totalFees,
      finalClosingCosts: this.finalClosingCosts,
      principalCurtailment: this.lenderCreditDistribution.principalCurtailment,
      remainingBalance: this.remainingBalance,
      financedCostsDistribution: this.costsDistributions.rollIns,
      financedCosts: this.costsDistributions.rollIns.total,
      lockedLoanAmount:
        this.remainingBalance +
        this.costsDistributions.rollIns.total +
        this.cashOut,
      cashToClose: this.costsDistributions.cash.total,
    }
  }
}
