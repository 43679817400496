/** AUTHENTICATION **/

export interface Auth0SignUpResponse {
  _id: string
  email_verified: boolean
  email: string
}

export interface Auth0LoginResponse {
  access_token: string
  token_type: string
  expires_in: number
}

export interface Auth0SignUpErrorResponse {
  response: {
    data: {
      code: string
    }
  }
}

export interface Auth0TokenData {
  token?: string
  lastUpdated?: Date
  ttlInSeconds?: number
  scopes?: string[]
}

export enum Auth0ApiScopes {
  ReadApplications = 'read:applications',
}

/** CUSTOM TEXT PROMPTS **/

export interface Auth0CustomTextData {
  signup: Auth0SignUpPrompt
  login: Auth0LoginPrompt
  'reset-password': Auth0PasswordResetPrompt
}

export interface Auth0SignUpPrompt {
  signup: Auth0SignUpScreen
}

export interface Auth0SignUpScreen {
  title?: string
  pageTitle?: string
  description?: string
  'email-in-use'?: string
  'username-taken'?: string
  'ip-blocked'?: string
  'ip-signup-blocked'?: string
}

export interface Auth0LoginPrompt {
  login: Auth0LoginScreen
}

export interface Auth0LoginScreen {
  title?: string
  pageTitle?: string
  description?: string
  separatorText?: string
  buttonText?: string
  federatedConnectionButtonText?: string
  signupActionLinkText?: string
  signupActionText?: string
  forgotPasswordText?: string
  passwordPlaceholder?: string
  usernamePlaceholder?: string
  emailPlaceholder?: string
  editEmailText?: string
  alertListTitle?: string
  'wrong-credentials'?: string
  'invalid-code'?: string
  'invalid-expired-code'?: string
  'invalid-email-format'?: string
  'wrong-email-credentials'?: string
  'custom-script-error-code'?: string
  'auth0-users-validation'?: string
  'authentication-failure'?: string
  'invalid-connection'?: string
  'ip-blocked'?: string
  'no-db-connection'?: string
  'password-breached'?: string
  'user-blocked'?: string
  'same-user-login'?: string
  'no-email'?: string
  'no-password'?: string
  'no-username'?: string
}

export interface Auth0PasswordResetPrompt {
  'reset-password-request': Auth0ResetPasswordRequestScreen
  'reset-password-success': Auth0ResetPasswordSuccessScreen
  'reset-password-error': Auth0ResetPasswordErrorScreen
}

export interface Auth0ResetPasswordRequestScreen {
  backToLoginLinkText: string
}

export interface Auth0ResetPasswordSuccessScreen {
  buttonText: string
}

export interface Auth0ResetPasswordErrorScreen {
  backToLoginLinkText: string
}
