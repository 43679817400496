import { AlfalfaConfig } from '@mortgage-pos/types'
import { jsonUtils } from './json'

function getAlfalfaConfiguration(): AlfalfaConfig {
  if (window?.localStorage) {
    const configJson = localStorage.getItem('alfalfaConfiguration')
    return jsonUtils.safeParse(configJson)
  }
}

export const alfalfaUtils = {
  getConfiguration: getAlfalfaConfiguration,
}
