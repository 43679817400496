import { Status } from '@mortgage-pos/types'

export const mapBSMStatusToStatusEnum = (
  mainStatus: string,
  subStatus: string
): Status | null => {
  switch (mainStatus) {
    case 'Start Application':
      return Status.BsmApplicationStarted

    case 'Create Application': {
      switch (subStatus) {
        case 'Import Waiting':
          return Status.BsmImportWaiting
        case 'Skipped Import Bank Account':
          return Status.BsmSkippedImportBankAccount
        default:
          return Status.BsmApplicationCreated
      }
    }

    case 'Verify Application': {
      switch (subStatus) {
        case 'Application Created':
          return Status.BsmApplicationCreated
        case 'Verified Income & Expenses':
          return Status.BsmVerifiedIncomeAndExpenses
        case 'Waiting for CoBorrower':
          return Status.BsmWaitingForCoBorrower
        default:
          return Status.BsmApplicationVerified
      }
    }

    case 'Loan Approval': {
      switch (subStatus) {
        case 'Pending Escalated Review':
          return Status.BsmPendingEscalatedReview
        case 'Submitted Application':
          return Status.BsmSubmittedApplication
        case 'Chose Loan':
          return Status.BsmChoseLoan
        default:
          return Status.BsmChoseLoan
      }
    }

    case 'Appraisal':
      return Status.BsmAppraisal

    case 'Underwriting':
      return Status.BsmUnderwriting

    case 'Closing':
      return Status.BsmClosing

    case 'Closed':
      return Status.BsmClosed

    case 'Declined':
      return Status.BsmDeclined

    case 'On Hold':
      return Status.BsmOnHold

    case 'Withdrawn':
      return Status.BsmWithdrawn

    case 'Canceled':
      return Status.BsmCanceled

    case 'Archived':
      return Status.BsmArchived

    case 'Pending Withdrawal':
      return Status.BsmPendingWithdrawal

    // BSM misspelled "Cancelation"
    case 'Pending Cancelation':
      return Status.BsmPendingCancellation

    default:
      return null
  }
}
