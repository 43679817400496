export const autoCompleteAddressQuestions = [
  {
    questionName: 'autoCompleteAddressFields',
    validations: [
      {
        rules: [
          {
            name: 'required',
            params: '["Address is required"]',
            component: 'ValidationRule',
          },
          {
            name: 'min',
            params: '[1, "Please enter a valid address"]',
            component: 'ValidationRule',
          },
          {
            name: 'fullAddressRequired',
            params: '["street", "Please enter your full address"]',
            component: 'ValidationRule',
          },
        ],
        component: 'Validation',
        condition: [
          {
            is: "ref === undefined || ref === '' ",
            component: 'Validation Condition',
            reference: 'street',
          },
        ],
        validatorType: 'string',
      },
    ],
  },
  {
    questionName: 'street',
    validations: [
      {
        rules: [
          {
            name: 'required',
            params: '["Street is required"]',
            component: 'ValidationRule',
          },
          {
            name: 'min',
            params: '[1, "Please enter a valid address"]',
            component: 'ValidationRule',
          },
        ],
        component: 'Validation',
        validatorType: 'string',
      },
    ],
  },
  {
    questionName: 'state',
    validations: [
      {
        rules: [
          {
            name: 'required',
            params: '["State is required"]',
            component: 'ValidationRule',
          },
        ],
        component: 'Validation',
        validatorType: 'string',
      },
    ],
  },
  {
    questionName: 'zipCode',
    validations: [
      {
        rules: [
          {
            name: 'required',
            params: '["Zipcode is required"]',
            component: 'ValidationRule',
          },
          {
            name: 'matches',
            params:
              '[{"regex": "^[0-9]{5}$"}, "Please enter a valid, 5 digit zip code."]',
            component: 'ValidationRule',
          },
        ],
        component: 'Validation',
        validatorType: 'string',
      },
    ],
  },
  {
    questionName: 'city',
    validations: [
      {
        rules: [
          {
            name: 'required',
            params: '["City is required"]',
            component: 'ValidationRule',
          },
          {
            name: 'min',
            params: '[1, "Please enter a valid city"]',
            component: 'ValidationRule',
          },
        ],
        component: 'Validation',
        validatorType: 'string',
      },
    ],
  },
]
