/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum FnmaTransactionType {
  NewImport = 'N',
  Resubmit = 'R',
}

export enum FnmaYesOrNo {
  Yes = 'Y',
  No = 'N',
}

export enum FnmaPropertyType {
  Detached = '01',
  Attached = '02',
  SingleFamily = '14',
  Condo4OrFewerStories = '04',
  Condo5OrMoreStories = '04',
  Coop = '13',
  MobileOrManufactured = '08',
  MultiFamily2Units = '15',
  MultiFamily3Units = '15',
  MultiFamily4Units = '15',
  Townhouse = '16',
  // No equivalent FNMA value for these
  Pud = '',
  Modular = '',
  Leasehold = '',
  NotSpecified = '',
}

export enum FnmaRateTypeName {
  AdjustableRate = 'AdjustableRate',
  FixedRate = 'FixedRate',
  GEM = 'GEM',
  GPM = 'GPM',
  Other = 'Other',
}

export enum FnmaRateType {
  AdjustableRate = '01',
  FixedRate = '05',
  GEM = '04',
  GPM = '06',
  Other = '13',
}

export enum FnmaPropertyUse {
  PrimaryResidence = '1',
  SecondaryOrVacation = '2',
  InvestmentOrRental = 'D',
  NotSpecified = '',
}

export enum FnmaCashOutDesignation {
  noCashOut = 'F1',
  cashOutOther = '01',
  cashOutHomeImprovement = '04',
  cashOutDebtConsolidation = '11',
  cashOutLimited = '13',
}

// ------- [Record - 01A] ------------- //
export enum FnmaLoanType {
  // Only using conventional loans for now
  Conventional = '01',
  VA = '02',
  FHA = '03',
  USDA = '04',
  Other = '07',
}

export enum FnmaPropertyLegalDescription {
  other = 'F1',
  metesAndBounds = '02',
}

// ------- [Record - 03A] ------------- //
export enum FnmaCoApplicantIndicator {
  primary = 'BW',
  coBorrower = 'QZ',
}

export enum FnmaApplicantMaritalStatus {
  Married = 'M',
  Separated = 'S',
  Unmarried = 'U',
}

export enum FnmaYearsSchooling {
  noHighSchoolDegree = 10,
  highSchoolOrGED = 12,
  twoYearsCollege = 14,
  fourYearsCollege = 16,
  mastersDegree = 18,
  advancedDegree = 20,
  default = 16,
}

// ------- [Record - 03C] ------------- //
export enum FnmaResidencyType {
  former = 'F4',
  present = 'ZG',
  mailing = 'BH',
}

export enum FnmaPresentOrProposedIndicator {
  PresentHousingExpense = '1',
  ProposedHousingExpense = '2',
}

export enum FnmaHousingPaymentTypeCode {
  Rent = '25',
  FirstMortgagePI = '26',
  OtherFinancingPI = '22',
  HazardInsurance = '01',
  RealEstateTaxes = '14',
  MortgageInsurance = '02',
  HOADues = '06',
  Other = '23',
}

// ------- [Record - PCH] ------------- //

export enum FnmaPaymentFrequencyCode {
  Monthly = '01',
  BiWeekly = '02',
}

// ------- [Record - PID] ------------- //

export enum FnmaProductDescription {
  StandardLCOR = 'Standard LCOR',
  AffordableLTV = 'Affordable LTV',
}

export enum FnmaProductPlanNumber {
  NegativeAmortization = 'NGAM',
  Oneyr1PercentAnnual = 'GEN1A',
  Oneyr2PercentAnnual = 'GEN1B',
  ThreeYr = 'GEN3',
  FiveYr = 'GEN5',
  SevenYr = 'GEN7',
  TenYr = 'GEN10',
  FHAOneYr = '251',
  FHAHybridARM = 'FHAHY',
  VA1yr = 'VA1YR',
  VAHybridARM = 'VAARM',
}

// ------- [Record - 02B] ------------- //
export enum FnmaPurposeOfLoan {
  refinance = '05',
  // Not mapping these values for MVP, hardcoded to 05
  purchase = '16',
  construction = '04',
  constructionPerm = '13',
  other = '15',
}

export enum FnmaEstateStatus {
  feeSimple = '1',
  // No leaseholds for MVP, hardcoded to 1
  leasehold = '2',
}

// ------- [Record - 05I] ------------- //
export enum FnmaIncomeType {
  w2 = '20',
  retired = '41',
  other = '45',

  // This are not used for MVP
  socialSecurityDisability = '42',
  realEstate = '30',
  trustIncome = 'F9',
  accessoryUnitIncome = 'AU',
  unemployment = 'M1',
  expenseAccountIncome = 'M2',
  fosterCare = 'M3',
  veteranBenefits = 'M4',
  nonBorrowerHouseholdIncome = 'NB',
  overtime = '09',
  bonuses = '08',
  commissions = '10',
  dividendsInterest = '17',
  subjectPropertyNetCashFlow = 'SI',
  housingChoiceVoucher = 'S8',
  netRentalIncome = '33',
  boarderIncome = 'BI',
  mortgageCreditCertificate = 'MC',
  capitalGains = 'CG',
  employmentRelatedAssets = 'EA',
  foreignIncome = 'FI',
  royaltyPayment = 'RP',
  seasonalIncome = 'SE',
  temporaryLeave = 'TL',
  tips = 'TI',
}

// ------- [Record - 08A] ------------- //
export enum FnmaCitizenshipDesignation {
  usCitizen = '01',
  permanentNonResident = '03',
  nonPermanentNonResident = '05',
}

export enum FnmaTitleHeldDesignation {
  solely = '01',
  jointWithSpouse = '25',
  jointWithOtherThanSpouse = '26',
}

// ------- [Record - 10A] ------------- //
export enum FnmaEthnicity {
  Hispanic = '1',
  NotHispanic = '2',
  InfoNotProvided = '3',
  Other = '4',
}

export enum FnmaGender {
  Male = 'M',
  Female = 'F',
  InfoNotProvided = 'I',
  Other = 'N',
}

// ------- [Record - 10R] ------------- //
export enum FnmaRace {
  AmericanIndianOrAlaskaNative = '1',
  Asian = '2',
  BlackOrAfricanAmerican = '3',
  NativeHawaiianOrOtherPacificIslander = '4',
  White = '5',
  InfoNotProvided = '6',
  NotApplicable = '7',
}
