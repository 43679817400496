export function convertYesNoToTrueFalse(answers): any {
  const reducerInitVal = Array.isArray(answers) ? [] : {}

  return Object.keys(answers).reduce((translatedAnswers, key) => {
    let value = answers[key]

    if (typeof value === 'string') {
      const comparisonValue = value.toLowerCase()

      if (['yes', 'no', 'idk', 'n', 'y'].includes(comparisonValue)) {
        value = comparisonValue === 'yes' || comparisonValue === 'y'
      }
    }

    // Make recursive to handle arrays and nested objects
    if (typeof value === 'object' && ![null, undefined].includes(value)) {
      value = convertYesNoToTrueFalse(value)
    }

    translatedAnswers[key] = value

    return translatedAnswers
  }, reducerInitVal)
}

export function convertTrueFalseToYesNo(answers): any {
  const reducerInitVal = Array.isArray(answers) ? [] : {}

  return Object.keys(answers).reduce((translatedAnswers, key) => {
    let value = answers[key]

    if (typeof value === 'boolean' && yesNoQuestionNames.includes(key)) {
      value = value ? 'Yes' : 'No'
    }

    // Make recursive to handle arrays and nested objects
    if (typeof value === 'object' && ![null, undefined].includes(value)) {
      value = convertTrueFalseToYesNo(value)
    }

    translatedAnswers[key] = value

    return translatedAnswers
  }, reducerInitVal)
}

const yesNoQuestionNames = [
  'isTakingCashOut',
  'isCurrentAddress',
  'hasCoBorrower',
  'doesCoBorrowerHaveSameAddress',
  'marriedToPrimary',
  'moreThanOneCoBorrower',
  'declarationOutstandingJudgements',
  'declarationBankrupt',
  'declarationForeclosed',
  'declarationLawsuit',
  'declarationForeclosedDirect',
  'declarationFederalDebt',
  'declarationAlimony',
  'declarationDownPaymentBorrowed',
  'declarationEndorserNote',
  'declarationCitizen',
  'declarationResident',
  'declarationMilitary',
  'declarationVALoan',
  'declarationFHALoan',
  'declarationPrimaryResidence',
  'declarationOwnership',
  'declarationRelationWithSeller',
  'undisclosedBorrowedFundsIndicator',
  'declarationNewCredit',
  'declarationSubjectToLien',
  'declarationConveyedTitle',
  'coBorrowerDeclarationOutstandingJudgements',
  'coBorrowerDeclarationBankrupt',
  'coBorrowerDeclarationForeclosed',
  'coBorrowerDeclarationLawsuit',
  'coBorrowerDeclarationForeclosedDirect',
  'coBorrowerDeclarationFederalDebt',
  'coBorrowerDeclarationAlimony',
  'coBorrowerDeclarationDownPaymentBorrowed',
  'coBorrowerDeclarationEndorserNote',
  'coBorrowerDeclarationCitizen',
  'coBorrowerDeclarationResident',
  'coBorrowerDeclarationMilitary',
  'coBorrowerDeclarationVALoan',
  'coBorrowerDeclarationFHALoan',
  'coBorrowerDeclarationPrimaryResidence',
  'coBorrowerDeclarationOwnership',
  'coBorrowerUndisclosedBorrowedFundsIndicator',
  'coBorrowerDeclarationNewCredit',
  'coBorrowerDeclarationSubjectToLien',
  'coBorrowerDeclarationConveyedTitle',
  'coBorrowerDeclarationRelationWithSeller',
  'homeBusiness',
  'haveDependents',
  'nonSpousePropertyRights',
  'employedByParty',
  'companyOwnership',
  'forbearance',
  'escrow',
  'rollInFees',
  'additionalIncome',
  'coBorrowerAdditionalIncome',
  'purchaseKnownAddress',
  'firstTimeHomeBuyer',
]
