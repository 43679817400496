import { HashObject } from '../Crypto'
import { AlfalfaConfig } from './Plugin'
import { AlfalfaAnswerSetEntity } from './AnswerSets'

export enum AlfalfaOutboundEvent {
  InitRequest = 'alfalfa.initRequest',
  SaveAnswers = 'alfalfa.saveAnswers',
  ImportAnswers = 'alfalfa.importAnswers',
  SaveAnswerSet = 'alfalfa.saveAnswerSet',
  SaveConfigReq = 'alfalfa.saveConfigReq',
  ExecAcctSignUp = 'alfalfa.execAcctSignUp',
  SkipToPricing = 'alfalfa.skipToPricing',

  // Tumbleweed Events (does not proxy msg to page)
  TwCaptureErrorHash = 'tumbleweed.captureErrorHash',
}

export enum AlfalfaInboundEvent {
  AcctPage = 'alfalfa.acctPage',
  AnswerSets = 'alfalfa.answerSets',
  FetchConfig = 'alfalfa.fetchConfig',
  InitResponse = 'alfalfa.initResponse',
  ExportAnswers = 'alfalfa.exportAnswers',
  SaveAnswerSetOutcome = 'alfalfa.saveAnswerSetOutcome',

  // Tumbleweed Events
  TwPushErrorHash = 'alfalfa.tw.pushErrorHash',
}

export type AlfalfaEvent =
  | AlfalfaInitResponseEvent
  | AlfalfaAcctPageEvent
  | AlfalfaAnswerSetsEvent
  | AlfalfaExportAnswersEvent
  | AlfalfaSaveAnswerSetOutcomeEvent
  | AlfalfaFetchConfigEvent
  | TwCaptureErrorHashEvent
  | AlfalfaSaveConfigEvent
  | AlfalfaAnswersEvent
  | AlfalfaImportAnswersEvent
  | AlfalfaSaveAnswerSetEvent
  | AlfalfaSkipToPricingEvent

// ----------[Inbound Events]---------- //
export interface AlfalfaInitResponseEvent {
  type: AlfalfaInboundEvent.InitResponse
  questionnaireSlug: string
  productName: string
}

export interface AlfalfaAcctPageEvent {
  type: AlfalfaInboundEvent.AcctPage
}

export interface AlfalfaAnswerSetsEvent {
  type: AlfalfaInboundEvent.AnswerSets
  answerSets: AlfalfaAnswerSetEntity[]
}

export interface AlfalfaExportAnswersEvent {
  type: AlfalfaInboundEvent.ExportAnswers
  answers: Record<string, any>
}

export interface AlfalfaSaveAnswerSetOutcomeEvent {
  type: AlfalfaInboundEvent.SaveAnswerSetOutcome
  wasSuccessful: boolean
  error?: string
}

export interface AlfalfaFetchConfigEvent {
  type: AlfalfaInboundEvent.FetchConfig
  config: AlfalfaConfig
}

// ----------[Outbound Events]---------- //

export interface TwCaptureErrorHashEvent {
  type: AlfalfaOutboundEvent.TwCaptureErrorHash
  hashObject: HashObject
}
export interface AlfalfaSaveConfigEvent {
  type: AlfalfaOutboundEvent.SaveConfigReq
  config: AlfalfaConfig
}

export interface AlfalfaAnswersEvent {
  type: AlfalfaOutboundEvent.SaveAnswers
  answers: AlfalfaAnswerSetEntity
}

export interface AlfalfaImportAnswersEvent {
  type: AlfalfaOutboundEvent.ImportAnswers
}

export interface AlfalfaSaveAnswerSetEvent {
  type: AlfalfaOutboundEvent.SaveAnswerSet
  answerSet: AlfalfaAnswerSetEntity
}

export interface AlfalfaSkipToPricingEvent {
  type: AlfalfaOutboundEvent.SkipToPricing
  answerSet?: AlfalfaAnswerSetEntity
}
