import { action, Action, Thunk, thunk } from 'easy-peasy'
import ApplicationClient from '@mortgage-pos/ui/services/ApplicationClient'
import { StoreModel } from './index'
import rollbar from '@mortgage-pos/ui/services/rollbar'
import newRelic from '@mortgage-pos/ui/services/newRelic'

export interface LicensedStatesModel {
  licensedStatesCodes: string[]
  setLicensedStatesCodes: Action<LicensedStatesModel, string[]>
  getLicensedStates: Thunk<LicensedStatesModel, null, null, StoreModel>
}

const licensedStates = (): LicensedStatesModel => {
  return {
    licensedStatesCodes: null,

    setLicensedStatesCodes: action((state, payload) => {
      state.licensedStatesCodes = payload
    }),

    getLicensedStates: thunk(async ({ setLicensedStatesCodes }, __, {}) => {
      let response

      try {
        newRelic.increment('get_licensed_states.attempt')
        response = await ApplicationClient.getLicensedStates()
      } catch (e) {
        newRelic.increment('get_licensed_states.failed')
        rollbar.error('Get Licensed States Failed', e)
        return
      }

      newRelic.increment('get_licensed_states.success')
      setLicensedStatesCodes(response.data.data.getLicensedStates)
    }),
  }
}

export default licensedStates
