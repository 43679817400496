import { gql } from 'apollo-boost'
import { graphQL } from '@mortgage-pos/ui/services/http'
import { getGraphQLString } from '@mortgage-pos/utils'
import {
  GoogleAutoCompleteResult,
  GoogleGeolocationAddressComponent,
  UserLocation,
} from '@mortgage-pos/types'
import newRelic from '@mortgage-pos/ui/services/newRelic'

export default class GoogleClient {
  static async getAddressDetails(autoCompleteAddress: string) {
    const addressParts = await this.getGeolocationData(autoCompleteAddress)

    if (!addressParts.length) {
      return {}
    }

    // Extract address chunks from API response
    let street = ''
    const street1 = this.findAddressPart(addressParts, 'street_number') ?? ''
    const street2 = this.findAddressPart(addressParts, 'route')
    const apt = this.findAddressPart(addressParts, 'subpremise')
    const city = this.findAddressPart(addressParts, 'locality')
    const state = this.findAddressPart(
      addressParts,
      'administrative_area_level_1'
    )
    const zipCode = this.findAddressPart(addressParts, 'postal_code')

    // Combine number and street name for street address
    if (street1 || street2) {
      street = `${street1} ${street2}`.trim()
    }

    return { street, apt, city, state, zipCode }
  }

  static async getGeolocationData(
    autoCompleteAddress: string
  ): Promise<Array<GoogleGeolocationAddressComponent>> {
    const geolocationResponse = await graphQL({
      query: zipCodeQuery,
      variables: { address: autoCompleteAddress },
    })
    newRelic.increment('geolocation.address_autocomplete.request')

    const results =
      geolocationResponse?.data?.data?.getGeolocationResults?.results

    // Only capture geolocation when there's just ONE matching item
    if (results?.length !== 1) {
      return []
    }

    return results?.[0]?.address_components || []
  }

  static findAddressPart(
    addressParts: GoogleGeolocationAddressComponent[],
    descriptor: string
  ) {
    return addressParts?.find((addressPart) =>
      addressPart?.types?.includes(descriptor)
    )?.short_name
  }

  static async fetchAddressAutoComplete(
    address: string,
    userLocation: UserLocation = {
      userLatitude: null,
      userLongitude: null,
    }
  ): Promise<GoogleAutoCompleteResult | null> {
    const autoCompleteResultsResponse = await graphQL({
      query: addressQuery,
      variables: {
        address,
        userLatitude: userLocation?.userLatitude,
        userLongitude: userLocation?.userLongitude,
      },
    })
    return autoCompleteResultsResponse?.data?.data
      ?.getAddressAutoCompleteResults
  }
}

export const zipCodeQuery = getGraphQLString(gql`
  query getGeolocationResults($address: String!) {
    getGeolocationResults(address: $address) {
      status
      results {
        address_components {
          long_name
          short_name
          types
        }
      }
    }
  }
`)

export const addressQuery = getGraphQLString(gql`
  query getAddressAutoCompleteResults(
    $address: String!
    $userLatitude: Float
    $userLongitude: Float
  ) {
    getAddressAutoCompleteResults(
      address: $address
      userLatitude: $userLatitude
      userLongitude: $userLongitude
    ) {
      status
      predictions {
        description
      }
    }
  }
`)
