import { FnmaRateTypeName } from '@mortgage-pos/types'

const DEFAULT_LOANTEK_RATE_CODE = 'F30'

interface LoantekRateMetadata {
  code: string
  description?: string
  term: number
  type: FnmaRateTypeName
}

function loantekFindPredicate(loantekRateCode: string) {
  return (loantekRateMetadata: LoantekRateMetadata) => {
    return loantekRateMetadata.code === loantekRateCode
  }
}

export function findLoantekRateTypeByCode(loantekRateCode: string) {
  const findByCode = loantekFindPredicate(loantekRateCode)
  const findByDefaultCode = loantekFindPredicate(DEFAULT_LOANTEK_RATE_CODE)

  return (
    loantekRateMetadataList.find(findByCode) ??
    loantekRateMetadataList.find(findByDefaultCode)
  )
}

export const loantekRateMetadataList: LoantekRateMetadata[] = [
  {
    code: 'F40',
    description: '40-year fixed',
    term: 40,
    type: FnmaRateTypeName.FixedRate,
  },
  {
    code: 'F30',
    description: '30-year fixed',
    term: 30,
    type: FnmaRateTypeName.FixedRate,
  },
  {
    code: 'F25',
    description: '25-year fixed',
    term: 25,
    type: FnmaRateTypeName.FixedRate,
  },
  {
    code: 'F20',
    description: '20-year fixed',
    term: 20,
    type: FnmaRateTypeName.FixedRate,
  },
  {
    code: 'F15',
    description: '15-year fixed',
    term: 15,
    type: FnmaRateTypeName.FixedRate,
  },
  {
    code: 'F10',
    description: '10-year fixed',
    term: 10,
    type: FnmaRateTypeName.FixedRate,
  },
  // ARM loans, not currently supported, will need to work with Seth to spec
  {
    code: 'A1_1',
    description: '1/1 ARM',
    term: 30,
    type: FnmaRateTypeName.AdjustableRate,
  },
  {
    code: 'A3_1',
    description: '3/1 ARM',
    term: 30,
    type: FnmaRateTypeName.AdjustableRate,
  },
  {
    code: 'A5_1',
    description: '5/1 ARM',
    term: 30,
    type: FnmaRateTypeName.AdjustableRate,
  },
  {
    code: 'A2_1',
    description: '2/1 ARM',
    term: 30,
    type: FnmaRateTypeName.AdjustableRate,
  },
  {
    code: 'A7_1',
    description: '7/1 ARM',
    term: 30,
    type: FnmaRateTypeName.AdjustableRate,
  },
  {
    code: 'A10_1',
    description: '10/1 ARM',
    term: 30,
    type: FnmaRateTypeName.AdjustableRate,
  },
  {
    code: 'A15_1',
    description: '15/1 ARM',
    term: 30,
    type: FnmaRateTypeName.AdjustableRate,
  },
  {
    code: 'A5_5',
    description: '5/5 ARM',
    term: 30,
    type: FnmaRateTypeName.AdjustableRate,
  },
]
