import Save from '@mortgage-pos/ui/assets/save-icon.svg'
import Trees from '@mortgage-pos/ui/assets/houses_compare.png'
import defaultSage from '@mortgage-pos/ui/assets/sageLogo.svg'
import TimeIcon from '@mortgage-pos/ui/assets/sage/7MIN_ICON.png'
import MoneyIcon from '@mortgage-pos/ui/assets/sage/$430_ICON.png'
import HelpfulIcon from '@mortgage-pos/ui/assets/sage-helpful-icon.png'
import sageLogoWhite from '@mortgage-pos/ui/assets/sage-logo-white.svg'
import FacebookIcon from '@mortgage-pos/ui/assets/sage/FacebookIcon.png'
import LinkedInIcon from '@mortgage-pos/ui/assets/sage/LinkedInIcon.png'
import InstagramIcon from '@mortgage-pos/ui/assets/sage/InstagramIcon.png'
import SageLoaderLogo from '@mortgage-pos/ui/assets/sage/Sage_Loader_A.gif'
import SageLoaderHouse from '@mortgage-pos/ui/assets/sage/SageLoaderHouse.gif'
import SageLoaderChecklist from '@mortgage-pos/ui/assets/sage/SageLoaderChecklist.gif'
import Summary_Credit_Icon from '@mortgage-pos/ui/assets/Summary_Credit_Icon.svg'
import Summary_Declarations_Icon from '@mortgage-pos/ui/assets/Summary_Declarations_Icon.svg'
import Summary_Personal_Icon from '@mortgage-pos/ui/assets/Summary_Personal_Icon.svg'
import Summary_Property_Icon from '@mortgage-pos/ui/assets/Summary_Property_Icon.svg'
import Equity_Icon from '@mortgage-pos/ui/assets/Equity_Icon.svg'

import Customization, {
  IconMap,
  CustomTextMap,
  LoaderMap,
  LogoMap,
} from '../../../types/customization'

const logos: LogoMap = {
  default: defaultSage,
  color: {
    white: sageLogoWhite,
  },
}

const loaders: LoaderMap = {
  default: SageLoaderLogo,
  house: SageLoaderHouse,
  checklist: SageLoaderChecklist,
}

const customText: CustomTextMap = {
  displayName: 'Sage',
  sageFullPartnerName: 'Sage Mortgage',
  sageBaseUrl: 'sagemortgage.com',
  valueProp1: {
    title: 'Access your equity',
    description: "We've helped our customers access $200M in home equity",
  },
  valueProp2: {
    title: '15 seconds',
    description:
      'How long it’ll take you to see your custom comparison options',
  },
}

const icons: IconMap = {
  money: MoneyIcon,
  time: TimeIcon,
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  linkedIn: LinkedInIcon,
  footer: Trees,
  save: Save,
  helpful: HelpfulIcon,
  creditIcon: Summary_Credit_Icon,
  declarationsIcon: Summary_Declarations_Icon,
  personalIcon: Summary_Personal_Icon,
  propertyIcon: Summary_Property_Icon,
  equityIcon: Equity_Icon,
}

export default {
  logos,
  loaders,
  customText,
  icons,
} as Customization
