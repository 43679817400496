export function redirectToOtherQuestionSet(questionSet) {
  // Handle edge case for IE so everything doesn't just break
  if (typeof URL !== 'function') {
    return
  }

  const baseUrl = window.location.href
  const urlObject = new URL(baseUrl)

  urlObject?.searchParams?.set('question-set', questionSet)

  const newUrl = urlObject.href

  if (baseUrl !== newUrl) {
    window.location.href = newUrl
  }
}
