/* eslint-disable @typescript-eslint/no-unsafe-declaration-merging */
import { CustomError } from '@mortgage-pos/error-handling'
import { serializeObject } from '@mortgage-pos/utils/error-handling'
import { removeEmptyValues } from '@mortgage-pos/utils/removeEmptyValues'

export interface IncenseError extends CustomError {
  errorHistory: Record<string, any>[]
}

export class IncenseError extends CustomError {
  constructor({ name, message, statusCode }: CustomError, previousError?: any) {
    super(name, message, statusCode)

    this.buildErrorHistory(previousError)

    if (
      Error.captureStackTrace &&
      typeof Error.captureStackTrace === 'function'
    ) {
      Error.captureStackTrace(this, CustomError)
    }
  }

  /**
   * @private buildErrorHistory
   *
   * Uses previously thrown error to continue build up the error history stack
   * in the `IncenseError` instance
   *
   * @param {any} previousError - previously thrown error to add to history
   */
  private buildErrorHistory(previousError?: any) {
    if (!previousError) {
      return
    }

    const prevWasObj = typeof previousError === 'object'
    const prevWasIncense = previousError instanceof IncenseError

    this.errorHistory = prevWasIncense ? previousError?.errorHistory : []

    const cleanPrevious = prevWasObj
      ? removeEmptyValues(serializeObject(previousError))
      : previousError

    if (cleanPrevious.stack) {
      delete cleanPrevious.stack
    }

    if (prevWasIncense) {
      delete cleanPrevious.errorHistory
    }

    this.errorHistory.push(cleanPrevious)
  }
}
