import { Environment } from './environment.d'

const isProdOrQA = ['prod', 'qa'].includes(process.env.APP_ENV)

export const environment: Environment = {
  appEnv: process.env.APP_ENV,
  productName: process.env.PRODUCT_NAME,
  production: process.env.APP_ENV === 'prod',
  questionnaireBaseUrl: isProdOrQA
    ? process.env.UI_BASE_URL
    : 'http://localhost:4200',
  apiBaseUrl: isProdOrQA ? process.env.API_BASE_URL : 'http://localhost:3333',
  sageApiBaseUrl: isProdOrQA
    ? process.env.SAGE_API_BASE_URL
    : 'http://localhost:3333',
  sageQuestionnaireBaseUrl: isProdOrQA
    ? process.env.SAGE_QUESTIONNAIRE_BASE_URL
    : 'http://localhost:4200',
  vgsBaseUrl: process.env.VGS_QUOTE_API_BASE_URL,
  borrowerPortal: {
    redirectEnabled: process.env.BP_REDIRECT_ENABLED === 'true',
    uiBaseUrl: isProdOrQA
      ? process.env.UI_BORROWER_PORTAL_URL
      : 'http://localhost:4201',
    apiBaseUrl: isProdOrQA
      ? process.env.API_BORROWER_PORTAL_BASE_URL
      : 'http://localhost:5555',
  },
  rollbar: {
    enabled: process.env.ROLLBAR_ENABLED,
    token: process.env.ROLLBAR_TOKEN_FE,
    environment: process.env.APP_ENV,
  },
  launchDarkly: {
    clientId: process.env.LAUNCH_DARKLY_CLIENT_ID,
  },
  auth0: {
    domain: process.env.AUTH0_DOMAIN,
    audience: process.env.AUTH0_AUDIENCE,
    clientId: process.env.AUTH0_CLIENT_ID_QUESTIONNAIRE,
  },
  besmarteeApiBaseUrl: process.env.BESMARTEE_API_BASE_URL,
  bankrateMortgageLeadFormBaseUrl:
    process.env.BANKRATE_MORTGAGE_LEAD_FORM_BASE_URL,
  bankrateAVMDisabled: process.env.BANKRATE_AVM_DISABLED,
  cryptoSecretKeyBrowser: isProdOrQA
    ? process.env.CRYPTO_SECRET_KEY_BROWSER
    : 'secretcryptokey',
  oneTrust: {
    dnsFormUrl: process.env.ONE_TRUST_DNS_FORM_URL,
  },
}
