export function isValidJson(item: any): boolean {
  try {
    if (typeof item !== 'string') {
      return false
    }

    JSON.parse(item)
    return true
  } catch (error) {
    return false
  }
}

export function jsonSafeParse(item: any): Record<string, any> {
  try {
    return JSON.parse(item) ?? {}
  } catch (error) {
    return {}
  }
}

export function jsonCompare(item1, item2) {
  return JSON.stringify(item1) === JSON.stringify(item2)
}

export function jsonClone(object: object) {
  if ([null, undefined].includes(object)) {
    return object
  }

  return jsonSafeParse(JSON.stringify(object))
}

export const jsonUtils = {
  isValid: isValidJson,
  safeParse: jsonSafeParse,
  compare: jsonCompare,
  clone: jsonClone,
}
