import { rateCodesMap } from '@mortgage-pos/data'
import { Rate } from '@mortgage-pos/types'

export interface MonthlyPaymentInputs {
  rate: number
  loanAmount: number
  termInMonths: number
}

export function calcMonthlyPayment(inputs: MonthlyPaymentInputs): number {
  const { rate, termInMonths, loanAmount } = inputs
  const monthlyInterestRate = rate / 100 / 12
  const rateFactor = Math.pow(1 + monthlyInterestRate, termInMonths)

  return (loanAmount * rateFactor * monthlyInterestRate) / (rateFactor - 1)
}

/**
 * Extrapolates a term length in months, based on the given bankrate product
 *
 * @param bankrateProductSelection - string with product name
 * @returns term length (in months) for the given product
 */
export function deriveLoanTermInMonths(bankrateProductSelection: string) {
  return parseInt(bankrateProductSelection) * 12 || -1
}

export function calcRemainingCostOfLoan({
  remainingBalance,
  currentMortgageBalance,
  currentLoanTerm,
  currentMortgageRate,
}: CurrentMortgageData) {
  const rate = currentMortgageRate * 100

  let remainingCostOfLoan = 0
  let remainingBalanceIterated = remainingBalance

  const monthlyPayment = calcMonthlyPayment({
    rate,
    termInMonths: currentLoanTerm,
    loanAmount: currentMortgageBalance,
  })
  const monthlyInterestRate = rate / 100 / 12

  do {
    if (monthlyPayment > remainingBalanceIterated) {
      remainingCostOfLoan += Math.min(monthlyPayment, remainingBalanceIterated)
      break
    }

    const monthlyInterest = remainingBalanceIterated * monthlyInterestRate
    const monthlyPrinciple = monthlyPayment - monthlyInterest

    remainingBalanceIterated -= monthlyPrinciple
    remainingCostOfLoan += monthlyPayment
  } while (remainingBalanceIterated > 0)

  return Math.round(remainingCostOfLoan)
}

export function calcNewCostOfLoan(newRateData: Rate) {
  const { rate, loanAmount, loanEstimateSections, term } = newRateData
  const rateTerm = rateCodesMap[term]
  const termInMonths = parseInt(rateTerm) * 12

  const newMonthlyPayment = calcMonthlyPayment({
    rate,
    termInMonths,
    loanAmount,
  })

  // Fees include Loan Origination Fee, Services you can shop for, Services you cannot shop for
  const fees =
    (loanEstimateSections?.['A']?.total ?? 0) +
    (loanEstimateSections?.['B']?.total ?? 0) +
    (loanEstimateSections?.['C']?.total ?? 0)

  // Finding Lender credit
  const loanFeesAndCredits = loanEstimateSections?.['J']?.fees ?? []

  const lenderCredit = loanFeesAndCredits.reduce((lenderCredit, fee) => {
    if (fee.description === 'Lender Credit') {
      lenderCredit = fee.amount
    }

    return lenderCredit
  }, 0)

  return Math.round(newMonthlyPayment * termInMonths + fees + lenderCredit)
}

export function calcRollInFees(rate: Rate) {
  const { loanEstimateSections } = rate
  const originationFees = loanEstimateSections?.['A']?.total ?? 0

  const servicesCannotShopFor = loanEstimateSections?.['B']?.total ?? 0
  const servicesShopFor = loanEstimateSections?.['C']?.total ?? 0
  const taxesAndGovtFees = loanEstimateSections?.['E']?.total ?? 0

  const loanFeesAndCredits = loanEstimateSections?.['J']?.fees ?? []
  const lenderCredit = loanFeesAndCredits.reduce((credit, fee) => {
    if (fee.description === 'Lender Credit') {
      credit = fee.amount
    }
    return credit
  }, 0)

  const nonOriginationFees =
    servicesCannotShopFor + servicesShopFor + taxesAndGovtFees + lenderCredit

  const rollInFees = originationFees + Math.max(nonOriginationFees, 0)

  return rollInFees
}

export interface CurrentMortgageData {
  remainingBalance: number
  currentMortgageBalance: number
  currentLoanTerm: number
  currentMortgageRate: number
}
