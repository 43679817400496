// 1M times in 550ms
export function fasterButCodeQLComplainsCheapUUID() {
  return `${Date.now()}.${Math.round(Math.random() * 100000)}`
}

// 1M times in 5135ms
export function cheapUUID() {
  const randomValue = crypto.getRandomValues(new Uint32Array(1))[0]
  return `${Date.now()}.${randomValue}`
}
