export const fixDecimals = (value, digits = 3) =>
  parseFloat(value).toFixed(digits)

export const toCurrency = (
  value,
  { decimals = 0, includeSymbol = true } = {}
) =>
  (includeSymbol ? '$' : '') +
  fixDecimals(value, decimals).replace(/(\d)(?=(\d{3})+(?![\d]))/g, '$1,')

export const toTitleCase = (phrase) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ')
}

export const parseCamelCase = (camelCaseString) => {
  const splitString = camelCaseString.replace(/([A-Z])/g, ' $1')
  return splitString.toLowerCase()
}
