import React, { useEffect } from 'react'
import rollbar from '@mortgage-pos/ui/services/rollbar'
import { useNavigate, useLocation } from 'react-router-dom'
import { CustomError } from '@mortgage-pos/error-handling'

export const RedirectRoute = ({
  pathName,
  withQuery = false,
  withLogging = false,
  referringRoute = '',
}) => {
  const navigate = useNavigate()
  const query = useLocation().search

  useEffect(() => {
    if (withLogging) {
      rollbar.error(
        new CustomError(
          'RedirectRouteError',
          'RedirectRouteError - Attempt to visit invalid route'
        ),
        {
          referrer: document.referrer,
          referringFrom: referringRoute,
          redirectingTo: pathName,
        }
      )
    }

    navigate({
      pathname: pathName,
      search: withQuery ? query : '',
    })
  }, [navigate, query])

  return <></>
}
