/**
 *
 * @param {num} number to convert to a dollar string
 * @returns {string} the converted number to $0.00 or ($1.00)
 */
export const formatNumberToDollarAmount = (
  num?: number,
  decimalPlaces = 2
): string => {
  if (num === undefined || num === null) {
    return ''
  }

  return new Intl.NumberFormat('en-US', {
    notation: 'standard',
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: decimalPlaces,
    currencySign: 'accounting',
  }).format(num)
}
