import { SectionFees } from '@mortgage-pos/types'

export function extractFeeByDescription(
  fees: SectionFees[],
  description: string
) {
  const matched = fees?.find((fee) => fee.description === description)

  if (!matched) return 0

  return matched.amount ?? 0
}
