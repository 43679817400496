import { SECTIONS } from './types'

// placement constants
export const LEFT_COL_START = 15
export const LEFT_COL_VALUE_TEXT = 175
export const LEFT_COL_END = 285

export const RIGHT_COL_START = 310
export const RIGHT_COL_VALUE_TEXT = 485
export const RIGHT_COL_END = 597

export const MIN_CONTENT_HEIGHT = 50
export const CONTENT_Y_START = 265
export const COLUMN_BOTTOM_START = 680

export const DEFAULT_HOI_MONTHS = 2

// color values
export const COLORS = {
  BACKGROUND: '#F1F8F5',
  HEADING: '#4E7B57',
  BLACK: 'black',
  STROKE: '#dddddd',
}

export const derivedCalculations = {
  'Outstanding Balance': ([loanAmount, cashOut, rollInFees]) =>
    loanAmount - cashOut - rollInFees,
  'Property Taxes (2 mths)': (num) => Math.round(num * 2),
  'Lender Credit': (sections) => {
    const lenderCredit = sections.find(
      ({ description }) => description === 'Lender Credit'
    )
    if (lenderCredit && lenderCredit.amount < 0) {
      return lenderCredit.amount
    }
    return 0
  },
  'Sage Closing Credit Offer': (sections) => {
    const sageClosingCreditOffer = sections.find(
      ({ description }) => description === 'Sage Closing Credit Offer'
    )
    if (sageClosingCreditOffer && sageClosingCreditOffer.amount < 0) {
      return sageClosingCreditOffer.amount
    }
    return 0
  },
}

// field mappings to build sections
export const FieldMap = {
  [SECTIONS.ProposedTerms]: {
    'Loan Amount': 'rate.loanAmount',
    'Loan Type': 'rate.type',
    'Loan Term': 'rate.term',
    'Loan Product': 'rate.family',
    'Loan Class': 'rate.productClass',
    'Interest Rate': 'rate.rate',
    LTV: 'rate.loanToValue',
    APR: 'rate.apr',
  },
  [SECTIONS.Monthly]: {
    'Principal & Interest': 'rate.principleAndInterestPayment',
    "Homeowner's Insurance": 'rate.monthlyInsurance',
    PMI: 'rate.mortgageInsurancePremium.monthlyDollarPremium',
    'Property taxes': 'rate.monthlyPropertyTax',
  },
  [SECTIONS.Prepaid]: {
    'Items Required by Lender to be Paid in Advance': null,
    'Daily Int. 15 Days': `rate.loanEstimateSections['F'].total`,
    'Reserves Deposited with Lender': null,
    "Homeowner's Ins (2 mths)": 'rate.monthlyInsurance',
    'Property Taxes (2 mths)': 'rate.monthlyPropertyTax',
  },
  [SECTIONS.SummaryOfCC]: {
    'Outstanding Balance': [
      'rate.loanAmount',
      'rate.cashOut',
      'rate.rollInFees',
    ],
    'Total Est. Payoffs and Payments': null, //calculated in buildContentSections
    'Loan Amount': null,
  },
}
