/**
 * Takes a revision object and removes null and undefined values if not
 * part of the exceptionKeys array.
 * ANY NAMED exceptionKeys WILL NOT BE REMOVED FROM THE REVISION
 * @param revision Revision Object
 * @param exceptionKeys Array of revision keys (db field name ie. ['first_name', 'last_name'])

 * @returns Clean revision object with removed key:value pairs.
 */
export function removeEmptyValues<T>(
  revision: T,
  exceptionKeys: string[] = []
): Partial<T> {
  if (!revision) {
    return null
  }
  return Object.keys(revision).reduce<Partial<T>>((cleanRevision, key) => {
    const value = revision[key]
    if (
      !exceptionKeys.includes(key) &&
      (value === null || value === undefined)
    ) {
      return cleanRevision
    }

    cleanRevision[key] = value

    return cleanRevision
  }, {} as T)
}

export function recursivelyRemoveEmptyValues<T>(revision: T): Partial<T> {
  if (!revision) {
    return {}
  }

  if (typeof revision !== 'object') {
    return revision
  }

  return Object.entries(revision)
    .filter(([_, value]) => value !== null && value !== undefined)
    .reduce<Partial<T>>((cleanRevision, [key, value]) => {
      if (Array.isArray(value)) {
        cleanRevision[key] = value.map((item) => {
          return recursivelyRemoveEmptyValues(item)
        })
      } else if (typeof value === 'object') {
        cleanRevision[key] = recursivelyRemoveEmptyValues(value)
      } else {
        cleanRevision[key] = value
      }
      return cleanRevision
    }, {} as T)
}
