export const rateCodesMap = {
  F40: '40-year fixed',
  F30: '30-year fixed',
  F25: '25-year fixed',
  F20: '20-year fixed',
  F15: '15-year fixed',
  F10: '10-year fixed',
  A1_1: '1/1 ARM',
  A3_1: '3/1 ARM',
  A5_1: '5/1 ARM',
  A2_1: '2/1 ARM',
  A7_1: '7/1 ARM',
  A10_1: '10/1 ARM',
  A15_1: '15/1 ARM',
  A5_5: '5/5 ARM',
}

export const loanTermToMonthsMap = {
  F30: 360,
  F25: 300,
  F20: 240,
  F15: 180,
  F10: 120,
  A5_6: 360,
  A7_6: 360,
  A10_6: 360,
  ALL: 360,
}
