import { StoreModel } from '.'
import { computed, Computed } from 'easy-peasy'

export interface VisibleIfConditionsModel {
  isUnlicensedState: Computed<VisibleIfConditionsModel, boolean, StoreModel>
  leDataExtractFailed: Computed<VisibleIfConditionsModel, boolean, StoreModel>
  mortgageStatementExtractFailed: Computed<
    VisibleIfConditionsModel,
    boolean,
    StoreModel
  >
  missingCurrentMortgageInfo: Computed<
    VisibleIfConditionsModel,
    boolean,
    StoreModel
  >
  missingCreditScore: Computed<VisibleIfConditionsModel, boolean, StoreModel>
  failedZipCodeStateFetch: Computed<
    VisibleIfConditionsModel,
    boolean,
    StoreModel
  >
  failedMaxCashoutCalculation: Computed<
    VisibleIfConditionsModel,
    boolean,
    StoreModel
  >
  maxCashoutEquity: Computed<VisibleIfConditionsModel, boolean, StoreModel>
  maxCashoutNonEquity: Computed<VisibleIfConditionsModel, boolean, StoreModel>
}

/*
  Prebuilt visible ifs can be either functions (actions/thunks) or booleans (computed)
  Make sure the name and the returned value make sense when being passed to 'isQuestionVisible',
  e.g. for the state stop page it should be visible if the state isn't licensed,
  so we name the visibleIf 'isUnlicensedState' which will return true from 'isQuestionVisible'
  if the selected state isn't in our licensed list
*/
const visibleIfConditions = (): VisibleIfConditionsModel => ({
  isUnlicensedState: computed(
    [
      (state, storeState) => storeState.licensedStates.licensedStatesCodes,
      (state, storeState) => storeState.answers.mergedAnswers.state,
    ],
    (licensedStatesCodes, selectedStateCode) => {
      return licensedStatesCodes
        ? !licensedStatesCodes.includes(selectedStateCode)
        : false
    }
  ),

  leDataExtractFailed: computed(
    [(_, storeState) => storeState.answers.mergedAnswers],
    (mergedAnswers) => {
      return requiredQuestionsForLeComparison.some((questionName) =>
        questionIsUnanswered(mergedAnswers, questionName)
      )
    }
  ),

  mortgageStatementExtractFailed: computed(
    [(_, storeState) => storeState.answers.mergedAnswers],
    (mergedAnswers) => {
      return requiredQuestionsForMortgageStatement.some((questionName) => {
        questionIsUnanswered(mergedAnswers, questionName)
      })
    }
  ),

  missingCurrentMortgageInfo: computed(
    [
      (_, storeState) => storeState.answers.mergedAnswers,
      (_, storeState) => storeState.bankrateCompare.mortgageRateEstimate,
    ],
    (mergedAnswers, mortgageRateEstimate) => {
      const hasRemainingBalance =
        !!mergedAnswers.remainingBalance ||
        !!mortgageRateEstimate?.currentBalance

      const hasMonthlyPayment =
        !!mergedAnswers.leRate?.[0].principleAndInterestPayment ||
        !!mortgageRateEstimate?.paymentDueAmount

      const hasMonthsLeft =
        !!mortgageRateEstimate?.openedDate &&
        !!mortgageRateEstimate?.paymentScheduleMonthCount

      return !hasRemainingBalance || !hasMonthlyPayment || !hasMonthsLeft
    }
  ),

  missingCreditScore: computed(
    [(_, storeState) => storeState.leComparison.wasCreditPullSuccessful],
    (wasCreditPullSuccessful) => !wasCreditPullSuccessful
  ),

  failedZipCodeStateFetch: computed(
    [(_, storeState) => storeState.answers.mergedAnswers],
    (mergedAnswers) => {
      const stateIsNull = !!(mergedAnswers.state === null)
      const purchaseStageContract = mergedAnswers.purchaseStage === 'contract'

      return stateIsNull && !purchaseStageContract
    }
  ),

  // if AVM fails to get property data or the user is eligible for less than $10k cash out, we want to show them the default screen
  failedMaxCashoutCalculation: computed(
    [(_, storeState) => storeState.answers],
    (answers) => {
      const noMaxCashoutValue = !!(answers.maxCashoutValue < 10000)
      return noMaxCashoutValue
    }
  ),

  // if we have a value above $10k for max cash out and the user has selected "access my equity" as their refi reason, we want to show them the CashOutEquity screen
  maxCashoutEquity: computed(
    [(_, storeState) => storeState.answers],
    (answers) => {
      const maxCashoutExists = !!(answers.maxCashoutValue > 10000)
      const refiReasonCashout = !!(
        answers.mergedAnswers.refiReason === 'cashOut'
      )
      return maxCashoutExists && refiReasonCashout
    }
  ),
  //if we have a value above $10k for max cash out and the user has selected something other than "access my equity" as their refi reason, we want to show them the CashOutNonEquity screen
  maxCashoutNonEquity: computed(
    [(_, storeState) => storeState.answers],
    (answers) => {
      const maxCashoutExists = !!(answers.maxCashoutValue > 10000)
      const refiReasonNonCashout = !!(
        answers.mergedAnswers.refiReason !== 'cashOut'
      )
      return maxCashoutExists && refiReasonNonCashout
    }
  ),
})

export default visibleIfConditions

const requiredQuestionsForLeComparison = [
  'firstName',
  'lastName',
  'estimatedValue',
  'remainingBalance',
  'state',
  'zipCode',
]

const requiredQuestionsForMortgageStatement = [
  'firstName',
  'lastName',
  'state',
  'zipCode',
]

function questionIsUnanswered(allAnswers, questionName) {
  const answer = allAnswers[questionName]
  return answer === undefined || answer === null || answer === ''
}
