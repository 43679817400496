import { QuestionSet } from '@mortgage-pos/types'
import { productConfig } from './productConfig'

export const questionSet: QuestionSet = {
  title: 'Sage Mortgage',
  otherPages: [
    {
      body: "You'll need to create an account to save your progress, and we'll collect some more information on your current mortgage, income, expenses and credit to help you lock in your loan.",
      title: "One second... <br />We're taking you to your full application",
      component: 'RedirectInterstitial',
    },
    {
      body: 'Thank you for your interest in Sage Mortgage. We are reviewing your information and a member from our team will be in contact with you shortly.',
      name: 'thankYou',
      title: 'Thank you!',
      component: 'ErrorPage',
    },
    {
      body: "We're sorry, it appears the lenders we work with have no rates for your loan scenario at this time. Check back soon!",
      name: 'noRates',
      title: 'No rates',
      component: 'ErrorPage',
    },
    {
      component: 'RateSelectPage',
      variations: [
        {
          variation: 'default',
          title: 'Save your rate to continue',
          subTitle:
            'These are a few rates we can provide you, but there are many more options to choose from. Select the one that fits you best to save your progress and complete your application. Once your application is complete, your Loan Officer can help you make your final selection to lock in your rate.',
          cta: 'SAVE MY PROGRESS',
        },
        {
          variation: 'pricing-takeover',
          title: 'Select your rate',
          subTitle:
            " Based on your application info, these are the custom loan options we've been able to fine. Now, just select the one that you like and your loan officer will reach out shortly to confirm your info, answer any questions you may have, and help you lock in your rate.",
          cta: 'SELECT THIS RATE',
        },
      ],
    },
    {
      component: 'SuccessPage',
      name: 'complete',
      title: 'Application Complete!',
      body: 'Your application is complete!',
    },
  ],
  pages: [
    {
      name: 'welcomeScreen',
      type: 'page',
      title: 'Get your Sage quote in less than a minute!',
      subTitle: '',
      component: 'Page',
      questions: [],
      visibleIf: '',
      description:
        'Answer just a few questions to get your personalized rates.',
    },
    {
      name: 'addressAutoComplete',
      type: 'page',
      title: "Tell us about the property you're refinancing",
      subTitle: '',
      component: 'Page',
      questions: [
        {
          questionName: { name: 'autoCompleteAddress' },
          title: '',
          content: '',
          subTitle: 'Address',
          component: 'Question-AddressAutoComplete',
          infoTitle: '',
          maxLength: '',
          infoContent: '',
          placeholder: 'Enter a location',
          validations: [],
        },
      ],
      visibleIf: '',
      description:
        'This will help us find accurate rates and maybe discounts, too.',
    },
    {
      name: 'propertyAddress',
      type: 'page',
      title:
        'Please confirm the full address of the property you are refinancing',
      subTitle: '',
      component: 'Page',
      questions: [
        {
          questionName: { name: 'street' },
          title: '',
          content: '',
          subTitle: 'Address',
          component: 'Question-Input',
          infoTitle: '',
          inputType: 'text',
          maxLength: '',
          infoContent: '',
          placeholder: 'Enter a location',
          validations: [
            {
              rules: [
                {
                  name: 'required',
                  params: '["Street address required"]',
                  component: 'ValidationRule',
                },
                {
                  name: 'min',
                  params: '[4, "Please enter a valid address"]',
                  component: 'ValidationRule',
                },
              ],
              component: 'Validation',
              validatorType: 'string',
            },
          ],
        },
        {
          questionName: { name: 'apt' },
          title: '',
          content: '',
          subTitle: 'Apt/Unit',
          component: 'Question-Input',
          infoTitle: '',
          inputType: 'text',
          maxLength: '',
          infoContent: '',
          placeholder: '',
          validations: [
            {
              rules: [],
              component: 'Validation',
              validatorType: 'string',
            },
          ],
        },
        {
          questionName: { name: 'city' },
          title: '',
          content: '',
          subTitle: 'City',
          component: 'Question-Input',
          infoTitle: '',
          inputType: 'text',
          maxLength: '',
          infoContent: '',
          placeholder: '',
          validations: [
            {
              rules: [
                {
                  name: 'required',
                  params: '["City required"]',
                  component: 'ValidationRule',
                },
                {
                  name: 'min',
                  params: '[3, "Please enter a valid city"]',
                  component: 'ValidationRule',
                },
              ],
              component: 'Validation',
              validatorType: 'string',
            },
          ],
        },
        {
          questionName: { name: 'state' },
          title: 'State',
          content: '',
          options: [
            {
              text: 'Texas',
              value: 'TX',
              component: 'Option',
            },
            {
              text: 'Florida',
              value: 'FL',
              component: 'Option',
            },
            {
              text: 'North Carolina',
              value: 'NC',
              component: 'Option',
            },
            {
              text: 'South Carolina',
              value: 'SC',
              component: 'Option',
            },
            {
              text: 'Oklahoma',
              value: 'OK',
              component: 'Option',
            },
            {
              text: 'Oregon',
              value: 'OR',
              component: 'Option',
            },
            {
              text: 'Other',
              value: 'Other',
              component: 'Option',
            },
          ],
          subTitle: 'State',
          component: 'Question-Select',
          infoTitle: '',
          infoContent: '',
          validations: [
            {
              rules: [
                {
                  name: 'required',
                  params: '["State required"]',
                  component: 'ValidationRule',
                },
              ],
              component: 'Validation',
              validatorType: 'string',
            },
          ],
        },
        {
          questionName: { name: 'zipCode' },
          title: '',
          content: '',
          subTitle: 'Zip Code',
          component: 'Question-Input',
          infoTitle: '',
          inputType: 'text',
          maxLength: '',
          infoContent: '',
          placeholder: '',
          validations: [
            {
              rules: [
                {
                  name: 'required',
                  params: '["Zipcode required"]',
                  component: 'ValidationRule',
                },
                {
                  name: 'min',
                  params: '[5, "Please enter a valid zipcode"]',
                  component: 'ValidationRule',
                },
              ],
              component: 'Validation',
              validatorType: 'string',
            },
          ],
        },
      ],
      visibleIf: JSON.stringify({
        operator: '||',
        conditions: [
          'autoCompleteAddress = {{notAnswered}}',
          'street = {{notAnswered}}',
          'city = {{notAnswered}}',
          'state = {{notAnswered}}',
          'zipCode = {{notAnswered}}',
        ],
      }),
      description:
        'We had trouble validating your address, please enter here to see your custom pricing quotes.',
    },
    {
      name: 'stateStop',
      type: 'stop',
      title: 'Check back soon!',
      subTitle: '',
      component: 'Page',
      questions: [],
      visibleIf: JSON.stringify({
        operator: '&&',
        conditions: [
          { question: 'state', conditional: '!=', answer: 'TX' },
          { question: 'state', conditional: '!=', answer: 'FL' },
          { question: 'state', conditional: '!=', answer: 'NC' },
          { question: 'state', conditional: '!=', answer: 'SC' },
          { question: 'state', conditional: '!=', answer: 'OK' },
          { question: 'state', conditional: '!=', answer: 'OR' },
        ],
      }),
      description:
        "Sorry! We're currently only moving forward with loans in North Carolina, South Carolina, Florida and Oklahoma. Please check back soon!",
    },
    {
      name: 'propertyInfo',
      type: 'page',
      title: '',
      subTitle: '',
      component: 'Page',
      questions: [
        {
          questionName: { name: 'propertyUse' },
          title: 'How do you use this home?',
          content: '',
          options: [
            {
              text: 'Primary residence',
              value: 'PrimaryResidence',
              component: 'Option',
            },
            {
              text: 'Second home',
              value: 'SecondaryOrVacation',
              component: 'Option',
            },
            {
              text: 'Investment property',
              value: 'InvestmentOrRental',
              component: 'Option',
            },
          ],
          subTitle: '',
          component: 'Question-Radio',
          infoTitle: '',
          infoContent: '',
          validations: [
            {
              rules: [
                {
                  name: 'required',
                  params: '["Please select an option"]',
                  component: 'ValidationRule',
                },
              ],
              component: 'Validation',
              validatorType: 'string',
            },
          ],
        },
        {
          questionName: { name: 'propertyType' },
          title: 'What type of property is this?',
          content: '',
          options: [
            {
              text: 'Single Family',
              value: 'SingleFamily',
              component: 'Option',
            },
            {
              text: 'Townhouse or Condo',
              value: 'Townhouse',
              component: 'Option',
            },
            {
              text: '2 to 4 units',
              value: 'MultiFamily2Units',
              component: 'Option',
            },
            {
              text: 'Other',
              value: 'other',
              component: 'Option',
            },
          ],
          subTitle: '',
          component: 'Question-Radio',
          infoTitle: '',
          infoContent: '',
          validations: [
            {
              rules: [
                {
                  name: 'required',
                  params: '["Please select an option"]',
                  component: 'ValidationRule',
                },
              ],
              component: 'Validation',
              validatorType: 'string',
            },
          ],
        },
      ],
      visibleIf: '',
      description: '',
    },
    {
      name: 'propertyContinued',
      type: 'page',
      title: '',
      subTitle: '',
      component: 'Page',
      questions: [
        {
          questionName: { name: 'estimatedValue' },
          title: 'What is the estimated property value?',
          content: '',
          subTitle: '',
          component: 'Question-Input',
          infoTitle: '',
          inputType: 'currency',
          maxLength: '',
          infoContent: '',
          placeholder: '',
          validations: [
            {
              rules: [
                {
                  name: 'required',
                  params: '["Estimated property value required"]',
                  component: 'ValidationRule',
                },
                {
                  name: 'min',
                  params: '[165000, "Please enter a value above $165,000"]',
                  component: 'ValidationRule',
                },
              ],
              component: 'Validation',
              validatorType: 'number',
            },
          ],
        },
        {
          questionName: { name: 'remainingBalance' },
          title: "What's the remaining balance on your mortgage?",
          content: '',
          subTitle: '',
          component: 'Question-Input',
          infoTitle: '',
          inputType: 'currency',
          maxLength: '',
          infoContent: '',
          placeholder: '',
          validations: [
            {
              rules: [
                {
                  name: 'required',
                  params: '["Estimated property value required"]',
                  component: 'ValidationRule',
                },
                {
                  name: 'min',
                  params: '[165000, "Please enter a value above $165,000"]',
                  component: 'ValidationRule',
                },
              ],
              component: 'Validation',
              validatorType: 'number',
            },
          ],
        },
        {
          questionName: { name: 'cashQuestion' },
          title: 'Do you want to take cash out?',
          content: '',
          toggled: 'false',
          subTitle: '',
          component: 'Question-Toggle',
          infoTitle: '',
          infoContent: '',
          validations: [],
        },
      ],
      visibleIf: '',
      description: '',
    },
    {
      name: 'cashAmount',
      type: 'page',
      title: '',
      subTitle: '',
      component: 'Page',
      questions: [
        {
          questionName: { name: 'cashOut' },
          title: 'How much cash would you like to take out of your home?',
          content: '',
          subTitle:
            'The amount of cash out you input may affect the products and pricing options available to you. ',
          component: 'Question-Input',
          infoTitle: '',
          inputType: 'currency',
          maxLength: '',
          infoContent: '',
          placeholder: '',
          validations: [
            {
              rules: [
                {
                  name: 'required',
                  params: '["Please enter an amount"]',
                  component: 'ValidationRule',
                },
              ],
              component: 'Validation',
              validatorType: 'number',
            },
          ],
        },
      ],
      visibleIf: 'cashQuestion = true',
      description: '',
    },
    {
      name: 'personalInfo',
      type: 'page',
      title: "Let's take a peek at your credit (this won't affect your score)",
      subTitle: '',
      component: 'Page',
      questions: [
        {
          questionName: { name: 'firstName' },
          title: '',
          content: '',
          subTitle: 'First Name',
          component: 'Question-Input',
          infoTitle: '',
          inputType: 'text',
          maxLength: '',
          infoContent: '',
          placeholder: '',
          validations: [
            {
              rules: [
                {
                  name: 'required',
                  params: '["First name required"]',
                  component: 'ValidationRule',
                },
              ],
              component: 'Validation',
              validatorType: 'string',
            },
          ],
        },
        {
          questionName: { name: 'lastName' },
          title: '',
          content: '',
          subTitle: 'Last Name',
          component: 'Question-Input',
          infoTitle: '',
          inputType: 'text',
          maxLength: '',
          infoContent: '',
          placeholder: '',
          validations: [
            {
              rules: [
                {
                  name: 'required',
                  params: '["Last name required"]',
                  component: 'ValidationRule',
                },
              ],
              component: 'Validation',
              validatorType: 'string',
            },
          ],
        },
        {
          questionName: { name: 'phone' },
          title: '',
          content: '',
          subTitle: 'Mobile phone',
          component: 'Question-Input',
          infoTitle: '',
          inputType: 'tel',
          maxLength: '',
          infoContent: '',
          placeholder: '',
          validations: [
            {
              rules: [
                {
                  name: 'required',
                  params: '["Mobile phone number required"]',
                  component: 'ValidationRule',
                },
                {
                  name: 'phone',
                  params: '["Please enter a valid phone number"]',
                  component: 'ValidationRule',
                },
              ],
              component: 'Validation',
              validatorType: 'string',
            },
          ],
        },
        {
          questionName: { name: 'email' },
          title: '',
          content: '',
          subTitle: 'Email',
          component: 'Question-Input',
          infoTitle: '',
          inputType: 'text',
          maxLength: '',
          infoContent: '',
          placeholder: '',
          validations: [
            {
              rules: [
                {
                  name: 'required',
                  params: '["Email address required"]',
                  component: 'ValidationRule',
                },
                {
                  name: 'email',
                  params: '["Please enter a valid email address"]',
                  component: 'ValidationRule',
                },
              ],
              component: 'Validation',
              validatorType: 'string',
            },
          ],
        },
        {
          questionName: { name: 'lastFourSSN' },
          title: '',
          content: '',
          subTitle: 'Last 4 digits SSN',
          component: 'Question-Input',
          infoTitle: '',
          inputType: 'password',
          maxLength: '4',
          infoContent:
            'If your credit is frozen with Transunion, Experian, or Equifax, now is a good time to contact them to unfreeze your accounts.',
          placeholder: '',
          validations: [
            {
              rules: [
                {
                  name: 'required',
                  params: '["Last 4 digits SSN required"]',
                  component: 'ValidationRule',
                },
                {
                  name: 'length',
                  params: '[4, "Please enter the last 4 digits of your SSN"]',
                  component: 'ValidationRule',
                },
              ],
              component: 'Validation',
              validatorType: 'number',
            },
          ],
        },
        {
          questionName: {
            name: 'termsOfUsePrivacyPolicyAndElectronicConsentAgreement',
          },
          title: '',
          checked: 'false',
          content:
            'I authorize Sage Mortgage to verify my credit. I\'ve also read and agreed to Sage Mortgage\'s \u003ca href="https://sagemortgage.com/terms.html"  class="text-theme-highlight underline" target="_blank" rel="noopener noreferrer"\u003eTerms of Use\u003c/a\u003e, \u003ca href="https://sagemortgage.com/privacy.html" class="text-theme-highlight underline" target="_blank" rel="noopener noreferrer"\u003ePrivacy Policy\u003c/a\u003e, and \u003ca href="https://sagemortgage.com/pdfs/e-sign-consent.pdf" class="text-theme-highlight underline" target="_blank" rel="noopener noreferrer"\u003eConsent to Receive Electronic Loan Documents\u003c/a\u003e.',
          subTitle: '',
          component: 'Question-Checkbox',
          infoTitle: '',
          infoContent: '',
          validations: [
            {
              rules: [
                {
                  name: 'truthy',
                  params: '["Please agree to continue"]',
                  component: 'ValidationRule',
                },
              ],
              component: 'Validation',
              validatorType: 'boolean',
            },
          ],
        },
        {
          questionName: { name: 'tcpaDisclosure' },
          title: '',
          checked: 'false',
          content: `By checking this box, I consent to ${productConfig?.name} calling and/or texting me at the number I provided above. Consent isn't required for Sage's services, but it helps our loan officers communicate with you quickly and effectively as needed throughout the refinance process. If you do not want to provide consent, please email us at hello@sagemortgage.com.`,
          subTitle: '',
          component: 'Question-Checkbox',
          infoTitle: '',
          infoContent: '',
          validations: [
            {
              rules: [
                {
                  name: 'truthy',
                  params: '["Please agree to continue"]',
                  component: 'ValidationRule',
                },
              ],
              component: 'Validation',
              validatorType: 'boolean',
            },
          ],
        },
        {
          questionName: { name: 'affiliatedBusinessArrangementDisclosure' },
          title: '',
          checked: 'false',
          content:
            'By checking this box, I acknowledge that I have read the \u003ca href="https://sagemortgage.com/abad.html"  class="text-theme-highlight underline" target="_blank" rel="noopener noreferrer"\u003eaffiliated business arrangement disclosure.\u003c/a\u003e',
          subTitle: '',
          component: 'Question-Checkbox',
          infoTitle: '',
          infoContent: '',
          validations: [
            {
              rules: [
                {
                  name: 'truthy',
                  params: '["Please agree to continue"]',
                  component: 'ValidationRule',
                },
              ],
              component: 'Validation',
              validatorType: 'boolean',
            },
          ],
        },
      ],
      visibleIf: '',
      description:
        "We'll do a soft credit pull so we can customize our pricing options for you, but it won't lower your score at all. ",
    },
  ],
}
