import { Status } from '@mortgage-pos/types'

export enum StatusSource {
  Sage = 'sage',
  BeSmartee = 'bsm',
  Homepoint = 'homepoint',
  Quicken = 'quicken',
  UWM = 'uwm',
  Q = 'q',
  PennyMac = 'pennymac',
  AmeriSave = 'amerisave',
  Encompass = 'encompass',
  Weslend = 'weslend',
}

type StatusSourceMap = {
  [status in Status]: StatusSource
}

export const statusSourceMap: StatusSourceMap = {
  leadReceived: StatusSource.Sage,
  applicationCreated: StatusSource.Sage,
  sageQRatesDisplayed: StatusSource.Sage,
  sageQNoRatesDisplayed: StatusSource.Sage,
  sageQBSMTakeoverRatesDisplayed: StatusSource.Sage,
  sageQBSMTakeoverNoRatesDisplayed: StatusSource.Sage,
  sageQRateSelected: StatusSource.Sage,
  inboundCallAnswered: StatusSource.Sage,
  inboundCallUnanswered: StatusSource.Sage,
  prePricingFlowTouched: StatusSource.Q,
  prePricingFlowCompleted: StatusSource.Q,
  applyFlowTouched: StatusSource.Q,
  applyFlowCompleted: StatusSource.Q,
  alfieCompleted: StatusSource.Q,
  phoneFlowCompleted: StatusSource.Q,
  phoneFlowTouched: StatusSource.Q,
  prePricingRatesDisplayed: StatusSource.Q,
  prePricingNoRatesDisplayed: StatusSource.Q,
  QPropertyInfoSectionTouched: StatusSource.Q,
  QPersonalInfoSectionTouched: StatusSource.Q,
  QEmploymentSectionTouched: StatusSource.Q,
  QExpensesSectionTouched: StatusSource.Q,
  QDeclarationsSectionTouched: StatusSource.Q,
  QCreateAccountSectionTouched: StatusSource.Q,
  QFormCompleted: StatusSource.Q,
  QRatesDisplayed: StatusSource.Q,
  NTLRatesDisplayed: StatusSource.Q,
  QNoRatesDisplayed: StatusSource.Q,
  QRateSelected: StatusSource.Q,
  callRequested: StatusSource.Q,
  lockRequested: StatusSource.Q,
  bsmImportWaiting: StatusSource.BeSmartee,
  bsmSkippedImportBankAccount: StatusSource.BeSmartee,
  bsmApplicationStarted: StatusSource.BeSmartee,
  bsmApplicationVerified: StatusSource.BeSmartee,
  bsmApplicationCreated: StatusSource.BeSmartee,
  bsmVerifiedIncomeAndExpenses: StatusSource.BeSmartee,
  bsmWaitingForCoBorrower: StatusSource.BeSmartee,
  bsmPendingEscalatedReview: StatusSource.BeSmartee,
  bsmSubmittedApplication: StatusSource.BeSmartee,
  bsmChoseLoan: StatusSource.BeSmartee,
  bsmAppraisal: StatusSource.BeSmartee,
  bsmUnderwriting: StatusSource.BeSmartee,
  bsmClosing: StatusSource.BeSmartee,
  bsmClosed: StatusSource.BeSmartee,
  bsmDeclined: StatusSource.BeSmartee,
  bsmOnHold: StatusSource.BeSmartee,
  bsmArchived: StatusSource.BeSmartee,
  bsmPendingWithdrawal: StatusSource.BeSmartee,
  bsmPendingCancellation: StatusSource.BeSmartee,
  bsmWithdrawn: StatusSource.BeSmartee,
  bsmCanceled: StatusSource.BeSmartee,
  lenderConditionallyApproved: StatusSource.Quicken,
  lenderWithdrawn: StatusSource.Quicken,
  lenderClosingOrSigningHasBeenScheduled: StatusSource.Quicken,
  lenderWaitingForFullPackage: StatusSource.Quicken,
  lenderFinalClosingDisclosureInReview: StatusSource.Quicken,
  lenderFinalClosingDisclosureReviewComplete: StatusSource.Quicken,
  lenderLoanDisbursedByLender: StatusSource.Quicken,
  lenderClosedPackageReceivedFromTitle: StatusSource.Quicken,
  lenderSuspended: StatusSource.Quicken,
  lenderClearToClose: StatusSource.Quicken,
  lenderSubmittedToUnderwriting: StatusSource.Quicken,
  lenderIntentToProceedReviewCompleted: StatusSource.Quicken,
  lenderFullPackageReceivedIncomplete: StatusSource.Quicken,
  lenderLoanRegistered: StatusSource.Quicken,
  lenderDenied: StatusSource.Quicken,
  lenderAdditionalInfoNeeded: StatusSource.Quicken,
  lenderFullPackageReceivedAwaitingReview: StatusSource.Quicken,
  lenderDocsOutToSettlementAgent: StatusSource.Quicken,
  lenderFileClosedForIncompleteness: StatusSource.Quicken,
  lenderApplicationApprovedNotAccepted: StatusSource.Quicken,
  lenderLoanSubmitted: StatusSource.Quicken,
  lenderLoanRescinded: StatusSource.Quicken,
  lenderUnknownStatus: StatusSource.Quicken,
  lenderFileImported: StatusSource.UWM,
  lenderApprovedWithConditions: StatusSource.UWM,
  lenderCollateralSentToWarehouse: StatusSource.UWM,
  lenderWholesaleClearToClose: StatusSource.UWM,
  lenderFunded: StatusSource.UWM,
  lenderRejected: StatusSource.UWM,
  lenderFileImportedProblemUpload: StatusSource.UWM,
  lenderApprovedWithConditionsNewConditions: StatusSource.UWM,
  lenderUnderwriting: StatusSource.UWM,
  lenderClosing: StatusSource.UWM,
  lenderSetup: StatusSource.UWM,
  lenderClosingPackageReceived: StatusSource.UWM,
  lenderUWMSuspended: StatusSource.UWM,
  lenderRequestForDisbursement: StatusSource.UWM,
  lenderRequestClosingDocs: StatusSource.UWM,
  lenderApproved: StatusSource.Homepoint,
  lenderAdverse: StatusSource.Homepoint,
  lenderCollateralReview: StatusSource.Homepoint,
  lenderDocsOut: StatusSource.Homepoint,
  lenderFundingComplete: StatusSource.Homepoint,
  lenderRegistered: StatusSource.Homepoint,
  lenderStarted: StatusSource.Homepoint,
  lockRejected: StatusSource.Sage,
  lenderCompletion: StatusSource.Homepoint,
  lenderPostClosing: StatusSource.Homepoint,
  lenderDiligenceReview: StatusSource.Homepoint,
  lenderLoanCreated: StatusSource.PennyMac,
  lenderCreditPackageSubmitted: StatusSource.PennyMac,
  lenderIncompleteSubmission: StatusSource.PennyMac,
  lenderDisclosureSubmitted: StatusSource.PennyMac,
  lenderCreditReview: StatusSource.PennyMac,
  lenderConditionalApproval: StatusSource.PennyMac,
  lenderFinalApproval: StatusSource.PennyMac,
  lenderClosingDisclosureSent: StatusSource.PennyMac,
  lenderPMDocsOut: StatusSource.PennyMac,
  lenderPMFunded: StatusSource.PennyMac,
  lenderPMSuspended: StatusSource.PennyMac,
  lenderCICPending: StatusSource.PennyMac,
  lenderCancelled: StatusSource.PennyMac,
  lenderDeclined: StatusSource.PennyMac,
  lenderInquiryOnly: StatusSource.PennyMac,
  lenderLEOnlyDisclosure: StatusSource.PennyMac,
  lenderCancelledDuplicate: StatusSource.AmeriSave,
  lenderCancelledOther: StatusSource.AmeriSave,
  lenderInClosing: StatusSource.AmeriSave,
  lenderInPreclear: StatusSource.AmeriSave,
  lenderApplicationLocked: StatusSource.AmeriSave,
  lenderClosed: StatusSource.AmeriSave,
  lenderRegistrationIncomplete: StatusSource.AmeriSave,
  lenderUnprocessedCancellation: StatusSource.AmeriSave,
  lenderUnprocessedDecline: StatusSource.AmeriSave,
  lenderPreclearQueue: StatusSource.AmeriSave,
  lenderApprovedPendingLock: StatusSource.AmeriSave,
  lenderClosingConditionsAvailable: StatusSource.AmeriSave,
  lenderEscalatedReview: StatusSource.AmeriSave,
  lenderFullConditionsSignOff: StatusSource.AmeriSave,
  reApp: StatusSource.Sage,
  migrated: StatusSource.Sage,
  lenderPartialConditionsSignOff: StatusSource.AmeriSave,
  lenderRescinded: StatusSource.AmeriSave,
  loanOpen: StatusSource.Weslend,
  preQual: StatusSource.Weslend,
  registered: StatusSource.Weslend,
  processing: StatusSource.Weslend,
  preProcessing: StatusSource.Weslend,
  documentCheck: StatusSource.Weslend,
  documentCheckFailed: StatusSource.Weslend,
  submitted: StatusSource.Weslend,
  preUnderwriting: StatusSource.Weslend,
  inUnderwriting: StatusSource.Weslend,
  preApproved: StatusSource.Weslend,
  approved: StatusSource.Weslend,
  conditionReview: StatusSource.Weslend,
  finalUnderwriting: StatusSource.Weslend,
  preDocQC: StatusSource.Weslend,
  clearToClose: StatusSource.Weslend,
  docsOrdered: StatusSource.Weslend,
  docsDrawn: StatusSource.Weslend,
  docsOut: StatusSource.Weslend,
  docsBack: StatusSource.Weslend,
  finalDocs: StatusSource.Weslend,
  funded: StatusSource.Weslend,
  clearToPurchase: StatusSource.Weslend,
  readyForSale: StatusSource.Weslend,
  loanShipped: StatusSource.Weslend,
  investorConditions: StatusSource.Weslend,
  investorConditionsSent: StatusSource.Weslend,
  additionalConditionsSent: StatusSource.Weslend,
  loanSold: StatusSource.Weslend,
  counterOfferApproved: StatusSource.Weslend,
  loanOnHold: StatusSource.Weslend,
  loanCanceled: StatusSource.Weslend,
  loanSuspended: StatusSource.Weslend,
  loanDenied: StatusSource.Weslend,
  loanWithdrawn: StatusSource.Weslend,
  recorded: StatusSource.Weslend,
  loanClosed: StatusSource.Weslend,
  loanPurchased: StatusSource.Weslend,
  loanRejected: StatusSource.Weslend,
}
