export const ssnQuestions = [
  {
    name: 'ssn',
    title: '',
    content: '',
    subTitle: 'Social Security number',
    component: 'Question-Input',
    infoTitle: '',
    inputType: 'ssn',
    maxLength: '',
    visibleIf: '',
    infoContent: '',
    infoWarning: false,
    placeholder: '',
    validations: [
      {
        rules: [
          {
            name: 'required',
            params: '["SSN required"]',
            component: 'ValidationRule',
          },
          {
            name: 'length',
            params: '[9, "Please enter a valid 9 digit SSN"]',
            component: 'ValidationRule',
          },
          {
            name: 'matches',
            params:
              '[{"regex": "^[0-9]{9}$"}, "Non-numeric characters not allowed"]',
            component: 'ValidationRule',
          },
        ],
        component: 'Validation',
        condition: [],
        validatorType: 'string',
      },
    ],
    questionName: {
      name: 'ssn',
    },
    modifierClass: '--hide-ssn-security-blurb',
    visibleIfConstructor: [],
  },
  {
    name: 'coBorrowerSsn',
    title: '',
    content: '',
    subTitle: 'Co-borrower social security number',
    component: 'Question-Input',
    infoTitle: '',
    inputType: 'ssn',
    maxLength: '',
    visibleIf: 'hasCoBorrower = Yes',
    infoContent: '',
    infoWarning: false,
    placeholder: '',
    validations: [
      {
        rules: [
          {
            name: 'required',
            params: '["Co-borrower SSN required"]',
            component: 'ValidationRule',
          },
          {
            name: 'length',
            params: '[9, "Please enter a valid 9 digit SSN"]',
            component: 'ValidationRule',
          },
          {
            name: 'matches',
            params:
              '[{"regex": "^[0-9]{9}$"}, "Non-numeric characters not allowed"]',
            component: 'ValidationRule',
          },
        ],
        component: 'Validation',
        condition: [],
        validatorType: 'string',
      },
    ],
    questionName: {
      name: 'coBorrowerSsn',
    },
    modifierClass: '--hide-ssn-security-blurb',
    visibleIfConstructor: [
      {
        answer: 'Yes',
        question: 'hasCoBorrower',
        component: 'VisibleIfSingleCondition',
        conditional: '===',
      },
    ],
  },
  {
    name: 'termsAndCreditDisclosure',
    title: '',
    checked: false,
    content: '',
    subTitle: '',
    component: 'Question-Checkbox',
    infoTitle: '',
    visibleIf: '',
    infoContent: '',
    infoWarning: false,
    validations: [
      {
        rules: [
          {
            name: 'truthy',
            params: '["Please agree to continue"]',
            component: 'ValidationRule',
          },
        ],
        component: 'Validation',
        condition: [],
        validatorType: 'boolean',
      },
    ],
    questionName: {
      name: 'termsAndCreditDisclosure',
    },
    modifierClass: '--tight --no-underline',
    visibleIfConstructor: [],
  },
  {
    name: 'coBorrowerTermsAndCreditDisclosure',
    title: '',
    checked: false,
    content: '',
    subTitle: '',
    component: 'Question-Checkbox',
    infoTitle: '',
    visibleIf: '',
    infoContent: '',
    infoWarning: false,
    validations: [
      {
        rules: [
          {
            name: 'truthy',
            params: '["Please agree to continue"]',
            component: 'ValidationRule',
          },
        ],
        component: 'Validation',
        condition: [],
        validatorType: 'boolean',
      },
    ],
    questionName: {
      name: 'coBorrowerTermsAndCreditDisclosure',
    },
    modifierClass: '--tight --no-underline',
    visibleIfConstructor: [
      {
        answer: 'Yes',
        question: 'hasCoBorrower',
        component: 'VisibleIfSingleCondition',
        conditional: '===',
      },
    ],
  },
]
