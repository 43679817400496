export function calculateRefinanceLoanToValue(
  remainingBalance: number,
  propertyValue: number,
  cashOut = 0
): number {
  if (!propertyValue || !remainingBalance) {
    return 0
  }
  if (isNaN(propertyValue) || isNaN(remainingBalance) || isNaN(cashOut)) {
    return 0
  }

  return Number(
    (((remainingBalance + cashOut) / propertyValue) * 100).toFixed(2)
  )
}

export function calculatePurchaseLoanToValue(
  purchasePrice: number,
  downPayment: number
): number {
  if (!purchasePrice || !downPayment) return 100
  if (isNaN(purchasePrice) || isNaN(downPayment)) return 100

  return Math.round((1 - downPayment / purchasePrice) * 100)
}
