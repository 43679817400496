export function sumValues(values: any[]): number {
  return values?.reduce((sum, value) => {
    const numericValue = Number(value) || 0
    return sum + numericValue
  }, 0)
}

// Returns a random integer between min(included) and max(excluded)
export function getRandomInt(min, max): number {
  return Math.floor(Math.random() * (max - min)) + min
}
