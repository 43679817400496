import { gql } from 'apollo-boost'
import { StoreModel } from './index'
import http, {
  borrowerPortalRequest,
  graphQL,
} from '@mortgage-pos/ui/services/http'
import { getGraphQLString } from '@mortgage-pos/utils'
import { environment } from '@mortgage-pos/ui/env'
import incense from '@mortgage-pos/ui/incense'
import { action, Action, computed, Computed, thunk, Thunk } from 'easy-peasy'
import {
  AddressType,
  LoanPurpose,
  AvmPropertyDataResult,
} from '@mortgage-pos/types'
import { variation } from '@mortgage-pos/ui/services/featureFlags'
import { FEATURE_FLAG_TYPES } from '@mortgage-pos/data'
import newRelic from '@mortgage-pos/ui/services/newRelic'

interface AVMMetadata {
  addressJson: string
  fetchedAt: number // Timestamp from Date.now() is a number
}

export interface PropertyInfoModel {
  avmEstimatedValue: number | null
  avmSaleDate: string | null
  avmMonthlyPropertyTax: number | null
  lastFetchedAvmMetadata: AVMMetadata
  shouldFetchAvm: Computed<PropertyInfoModel, boolean, StoreModel>
  getAVMData: Thunk<PropertyInfoModel, null, object, StoreModel>
  setAVMEstimatedValue: Action<PropertyInfoModel, number>
  setAVMSaleDate: Action<PropertyInfoModel, string>
  setAVMMetadata: Action<PropertyInfoModel, AVMMetadata>
  setAVMMonthlyPropertyTax: Action<PropertyInfoModel, number>
  getRefiHomeLiability: Thunk<null, null, null, StoreModel>
  inferMoveInDate: Thunk<PropertyInfoModel, null, null, StoreModel>
  getPrequalMaxPurchasePrice: Thunk<PropertyInfoModel, null, null, StoreModel>
}

const propertyInfo = (): PropertyInfoModel => {
  return {
    avmEstimatedValue: null,
    avmSaleDate: null,
    avmMonthlyPropertyTax: null,

    lastFetchedAvmMetadata: null,

    shouldFetchAvm: computed(
      [
        (state) => state,
        (state, storeState) => storeState.answers.mergedAnswers,
      ],
      (propertyInfoStore, mergedAnswers) => {
        try {
          const { lastFetchedAvmMetadata } = propertyInfoStore
          const { street, apt, city, state, zipCode } = mergedAnswers
          const addressFields = { street, apt, city, state, zipCode }

          if (environment.bankrateAVMDisabled === 'true') {
            return false
          }

          if (
            !lastFetchedAvmMetadata?.fetchedAt ||
            !lastFetchedAvmMetadata?.addressJson
          ) {
            return true
          }

          const { addressJson, fetchedAt } = lastFetchedAvmMetadata

          const hasAddressChanged =
            addressJson !== JSON.stringify(addressFields)

          // Check if last fetched AVM was out of the time window
          const now = Date.now()
          const hoursUntilInvalid = 24
          const msUntilInvalid = hoursUntilInvalid * 60 * 60 * 1000
          const timeWhenInvalid = fetchedAt + msUntilInvalid
          const isLastFetchOutOfDate = now > timeWhenInvalid

          return isLastFetchOutOfDate || hasAddressChanged
        } catch (error) {
          const avmMetadata = propertyInfoStore?.lastFetchedAvmMetadata

          incense(error)
            .details({
              name: 'ShouldFetchAvmError',
              message: 'Failed to determine if should fetch Corelogic data',
            })
            .sensitive({
              answers: mergedAnswers,
              avmMetadata,
            })
            .error()

          return false
        }
      }
    ),

    getAVMData: thunk(
      async (
        {
          setAVMEstimatedValue,
          setAVMMetadata,
          setAVMSaleDate,
          setAVMMonthlyPropertyTax,
        },
        __,
        { getStoreState, getStoreActions }
      ) => {
        const { answer } = getStoreActions().questionnaire
        const { inferMoveInDate } = getStoreActions().propertyInfo
        const { isSplitFlow } = getStoreState().questionnaire
        const { applicationId } = getStoreState().application
        const { mergedAnswers } = getStoreState().answers
        const { street, apt, city, state, zipCode } = mergedAnswers

        const isBankrateCompareEnabled = await variation(
          FEATURE_FLAG_TYPES.BANKRATE_COMPARE_ENABLED
        )

        let response

        try {
          response = await graphQL({
            query: getAVMDataQuery,
            variables: {
              request: {
                applicationId: null,
                street,
                apt,
                city,
                state,
                zipCode,
              },
            },
          })

          setAVMMetadata({
            addressJson: JSON.stringify({ street, apt, city, state, zipCode }),
            fetchedAt: Date.now(),
          })
        } catch (e) {
          incense(e)
            .details({
              name: 'GetAVMDataThunkError',
              message: 'Failed during GQL query execution',
            })
            .safe({ applicationId })
            .sensitive({ street, apt, city, state, zipCode, response })
            .error()

          newRelic.increment('get_avm_property_value.error')
          return
        }

        if (response.data.errors && response.data.errors.length) {
          incense(response.data.errors)
            .details({
              name: 'GetAVMDataThunkError',
              message: 'Failed due to errors in GQL response',
            })
            .safe({ applicationId })
            .sensitive({ street, apt, city, state, zipCode, response })
            .error()

          newRelic.increment('get_avm_property_value.error')
          return
        }

        if (response.data?.data?.getAndSaveAVMData) {
          const {
            avmPropertyValue,
            avmSaleDate,
            avmMonthlyPropertyTax,
            avmPropertyType,
          }: AvmPropertyDataResult = response.data.data.getAndSaveAVMData

          setAVMEstimatedValue(avmPropertyValue)
          setAVMSaleDate(avmSaleDate)
          setAVMMonthlyPropertyTax(avmMonthlyPropertyTax)

          await inferMoveInDate()

          if (isSplitFlow) {
            await answer({
              estimatedValue: avmPropertyValue,
              expensePropertyTax: avmMonthlyPropertyTax,
              propertyType: avmPropertyType,
            })
          }

          if (isBankrateCompareEnabled) {
            await answer({ estimatedValue: avmPropertyValue })
          }

          newRelic.increment('get_avm_property_value.success')
          return avmPropertyValue
        } else {
          newRelic.increment('get_avm_property_value.error')
          return
        }
      }
    ),

    setAVMMetadata: action((state, lastFetchedAvmMetadata) => {
      state.lastFetchedAvmMetadata = lastFetchedAvmMetadata
    }),

    setAVMEstimatedValue: action((state, avmEstimatedValue) => {
      state.avmEstimatedValue = avmEstimatedValue
    }),

    setAVMSaleDate: action((state, avmSaleDate) => {
      state.avmSaleDate = avmSaleDate
    }),

    setAVMMonthlyPropertyTax: action((state, avmMonthlyPropertyTax) => {
      state.avmMonthlyPropertyTax = avmMonthlyPropertyTax
    }),

    getRefiHomeLiability: thunk(async (_, __, { getStoreState }) => {
      /*
        This functionality will be used when we make the extra call to CoreLogic
        to get address for liabilities
      */

      let response
      const errorMsg = 'Get Refi Home Liability failed'
      const { applicationId } = getStoreState().application

      try {
        response = await graphQL({
          query: getRefiHomeLiabilityQuery,
        })
      } catch (e) {
        incense(e)
          .details({
            name: 'GetRefiHomeLiabilityThunkError',
            message: errorMsg,
          })
          .safe({ applicationId })
          .error()

        return
      }

      if (response.data.errors && response.data.errors.length) {
        incense(response.data.errors)
          .details({
            name: 'GetRefiHomeLiabilityThunkError',
            message: errorMsg,
          })
          .safe({ applicationId })
          .error()

        return
      }

      const refiHomeLiability = response.data.data.getRefiHomeLiability

      return refiHomeLiability ?? null
    }),

    inferMoveInDate: thunk(
      async (_, __, { getState, getStoreState, getStoreActions }) => {
        const { avmSaleDate } = getState()
        const { answers } = getStoreState().answers
        const { answer } = getStoreActions().questionnaire

        if (
          !avmSaleDate ||
          !answers.isCurrentAddress ||
          answers.loanPurpose !== LoanPurpose.Refinance
        )
          return

        const currentAddress = answers?.addresses?.find(
          ({ type }) => type === AddressType.Current
        )
        const moveInDate = answers?.moveInDate || avmSaleDate

        if (currentAddress) {
          await answer({
            moveInDate,
            addresses: [
              {
                ...currentAddress,
                moveInDate,
              },
            ],
          })
        }
      }
    ),
    getPrequalMaxPurchasePrice: thunk(async (_, __, { getStoreState }) => {
      const { applicationId } = getStoreState().application
      const { mergedAnswers } = getStoreState().answers

      const {
        propertyType,
        propertyUse,
        totalAssets: assetsAmount,
        grossAnnualIncome: annualGrossIncome,
        zipCode,
        loanPurpose,
        state: stateAbbreviation,
        city,
      } = mergedAnswers || {}

      const body = {
        applicationId,
        assetsAmount,
        propertyType: propertyType || 'SingleFamily',
        propertyUse: propertyUse || 'PrimaryResidence',
        annualGrossIncome,
        zipCode: Number(zipCode),
        loanPurpose,
        stateAbbreviation,
        city,
        saveRevision: true,
        source: 'Alfie',
      }

      let prequalResponse

      try {
        prequalResponse = await http.post(
          `${environment.apiBaseUrl}/api/max-purchase-price`,
          body,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )

        const skipBorrowerPortal = await variation(
          FEATURE_FLAG_TYPES.SKIP_BORROWER_PORTAL
        )

        if (!skipBorrowerPortal) {
          borrowerPortalRequest('/upload-prequalify-document').then(
            () => {},
            (reason) => {
              incense()
                .details({
                  name: 'GetPrequalMaxPurchasePrice',
                  message: 'Error uploading prequalify document',
                })
                .safe({ applicationId })
                .sensitive({ reason })
                .error()
            }
          )
        }
      } catch (error) {
        if (error?.response?.status !== 500) {
          incense(error)
            .details({
              name: 'GetPrequalMaxPurchasePrice',
              message: 'Error retrieving max purchase price',
            })
            .safe({ applicationId })
            .sensitive(body)
            .error()
        }

        logPrequalFailures(error?.response)
      }

      const purchasePrice = prequalResponse?.data?.purchasePrice
      if (purchasePrice) {
        newRelic.increment('max_purchase_price.success')
      }

      return purchasePrice
    }),
  }
}

export default propertyInfo

export const getAVMDataQuery = getGraphQLString(gql`
  mutation getAndSaveAVMData($request: AvmPropertyDataRequest!) {
    getAndSaveAVMData(request: $request) {
      avmPropertyValue
      avmSaleDate
      avmMonthlyPropertyTax
      avmPropertyType
    }
  }
`)

export const getRefiHomeLiabilityQuery = getGraphQLString(gql`
  query getRefiHomeLiability {
    getRefiHomeLiability {
      monthly_expense
      remaining_term_months
      total_term_months
      unpaid_balance
    }
  }
`)

const logPrequalFailures = (error) => {
  const tags = []

  if (error?.status === 500) {
    // No credit report log
    if (error?.data?.indexOf('No credit report') > -1) {
      tags.push({
        key: 'reason',
        value: 'No Corelogic Report',
      })

      // Rates ran recursively until purchasePrice was below $100,000 log
    } else if (error?.data?.indexOf('getRates ran until') > -1) {
      tags.push({
        key: 'reason',
        value: 'Purchase Price dipped below threshold',
      })
    }
  }
  newRelic.increment('max_purchase_price.failure', tags)
}
