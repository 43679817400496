const MAX_RECURSION_DEPTH = 6

export function serializeObject(originalValue: any) {
  if (typeof originalValue !== 'object' || originalValue === null) {
    return originalValue
  }

  const replacedValue = Array.isArray(originalValue) ? [] : {}
  const currentRecursionDepth = 0

  return destroyCircularRefs(
    originalValue,
    replacedValue,
    currentRecursionDepth
  )
}

export function destroyCircularRefs(
  originalValue: any,
  replacedValue: any,
  currentRecursionDepth: number,
  circularCache?: WeakSet<any>
) {
  circularCache = circularCache ?? new WeakSet([originalValue])

  const commonErrorProperties = ['name', 'message', 'stack']
  const ownProps = Object.getOwnPropertyNames(originalValue)

  ownProps.forEach((propName) => {
    const currentChildValue = originalValue[propName]

    if (typeof currentChildValue === 'object' && currentChildValue !== null) {
      // Stop serializing after reaching a certain depth to prevent extraneous payload sizes
      if (currentRecursionDepth >= MAX_RECURSION_DEPTH) {
        replacedValue[propName] = '[Redacted]'
        return
      }

      if (circularCache.has(currentChildValue)) {
        replacedValue[propName] = '[Circular]'
        return
      }

      circularCache.add(currentChildValue)

      const replacedChildValue = Array.isArray(currentChildValue) ? [] : {}

      replacedValue[propName] = destroyCircularRefs(
        currentChildValue,
        replacedChildValue,
        currentRecursionDepth + 1,
        circularCache
      )

      return
    } else {
      replacedValue[propName] = currentChildValue
    }
  })

  // Iterate common non-enumerable props found inside of error objects
  for (const propName of commonErrorProperties) {
    const valueFromError = originalValue[propName]

    if (typeof valueFromError === 'string') {
      replacedValue[propName] = valueFromError
    }
  }

  return replacedValue
}

export function getDecryptUrl(sensitiveUuid: string) {
  const ELLO_DECRYPT_PATH = `${process.env.RETOOL_URL}/apps/dev/Error%20Handling`

  return `${ELLO_DECRYPT_PATH}?sensitive-uuid=${sensitiveUuid}`
}
