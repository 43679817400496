import { TextValuePair } from '@mortgage-pos/types'

export const stateTextValuePairs: TextValuePair[] = [
  {
    text: 'Alabama',
    value: 'AL',
  },
  {
    text: 'Alaska',
    value: 'AK',
  },
  {
    text: 'Arizona',
    value: 'AZ',
  },
  {
    text: 'Arkansas',
    value: 'AR',
  },
  {
    text: 'California',
    value: 'CA',
  },
  {
    text: 'Colorado',
    value: 'CO',
  },
  {
    text: 'Connecticut',
    value: 'CT',
  },
  {
    text: 'Delaware',
    value: 'DE',
  },
  {
    text: 'District Of Columbia',
    value: 'DC',
  },
  {
    text: 'Florida',
    value: 'FL',
  },
  {
    text: 'Georgia',
    value: 'GA',
  },
  {
    text: 'Hawaii',
    value: 'HI',
  },
  {
    text: 'Idaho',
    value: 'ID',
  },
  {
    text: 'Illinois',
    value: 'IL',
  },
  {
    text: 'Indiana',
    value: 'IN',
  },
  {
    text: 'Iowa',
    value: 'IA',
  },
  {
    text: 'Kansas',
    value: 'KS',
  },
  {
    text: 'Kentucky',
    value: 'KY',
  },
  {
    text: 'Louisiana',
    value: 'LA',
  },
  {
    text: 'Maine',
    value: 'ME',
  },
  {
    text: 'Maryland',
    value: 'MD',
  },
  {
    text: 'Massachusetts',
    value: 'MA',
  },
  {
    text: 'Michigan',
    value: 'MI',
  },
  {
    text: 'Minnesota',
    value: 'MN',
  },
  {
    text: 'Mississippi',
    value: 'MS',
  },
  {
    text: 'Missouri',
    value: 'MO',
  },
  {
    text: 'Montana',
    value: 'MT',
  },
  {
    text: 'Nebraska',
    value: 'NE',
  },
  {
    text: 'Nevada',
    value: 'NV',
  },
  {
    text: 'New Hampshire',
    value: 'NH',
  },
  {
    text: 'New Jersey',
    value: 'NJ',
  },
  {
    text: 'New Mexico',
    value: 'NM',
  },
  {
    text: 'New York',
    value: 'NY',
  },
  {
    text: 'North Carolina',
    value: 'NC',
  },
  {
    text: 'North Dakota',
    value: 'ND',
  },
  {
    text: 'Ohio',
    value: 'OH',
  },
  {
    text: 'Oklahoma',
    value: 'OK',
  },
  {
    text: 'Oregon',
    value: 'OR',
  },
  {
    text: 'Pennsylvania',
    value: 'PA',
  },
  {
    text: 'Rhode Island',
    value: 'RI',
  },
  {
    text: 'South Carolina',
    value: 'SC',
  },
  {
    text: 'South Dakota',
    value: 'SD',
  },
  {
    text: 'Tennessee',
    value: 'TN',
  },
  {
    text: 'Texas',
    value: 'TX',
  },
  {
    text: 'Utah',
    value: 'UT',
  },
  {
    text: 'Vermont',
    value: 'VT',
  },
  {
    text: 'Virginia',
    value: 'VA',
  },
  {
    text: 'Washington',
    value: 'WA',
  },
  {
    text: 'West Virginia',
    value: 'WV',
  },
  {
    text: 'Wisconsin',
    value: 'WI',
  },
  {
    text: 'Wyoming',
    value: 'WY',
  },
]

export const stateAbbrNameMap = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}

export const stateFipsCodeMap = {
  AK: '02',
  AL: '01',
  AR: '05',
  AS: '60',
  AZ: '04',
  CA: '06',
  CO: '08',
  CT: '09',
  DC: '11',
  DE: '10',
  FL: '12',
  GA: '13',
  GU: '66',
  HI: '15',
  IA: '19',
  ID: '16',
  IL: '17',
  IN: '18',
  KS: '20',
  KY: '21',
  LA: '22',
  MA: '25',
  MD: '24',
  ME: '23',
  MI: '26',
  MN: '27',
  MO: '29',
  MS: '28',
  MT: '30',
  NC: '37',
  ND: '38',
  NE: '31',
  NH: '33',
  NJ: '34',
  NM: '35',
  NV: '32',
  NY: '36',
  OH: '39',
  OK: '40',
  OR: '41',
  PA: '42',
  PR: '72',
  RI: '44',
  SC: '45',
  SD: '46',
  TN: '47',
  TX: '48',
  UT: '49',
  VA: '51',
  VI: '78',
  VT: '50',
  WA: '53',
  WI: '55',
  WV: '54',
  WY: '56',
}
