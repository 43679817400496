/* eslint-disable @typescript-eslint/no-unsafe-declaration-merging */
export interface CustomError extends Error {
  statusCode: number
}

// Creates an error with a custom name and optional status code
export class CustomError extends Error {
  public metadata?: Record<string, any>

  constructor(
    name: string,
    message: string,
    statusCode: number = null,
    metadata = null
  ) {
    super(message)
    this.name = name
    this.statusCode = statusCode
    this.metadata = metadata

    if (
      Error.captureStackTrace &&
      typeof Error.captureStackTrace === 'function'
    ) {
      Error.captureStackTrace(this, CustomError)
    }
  }
}
