export enum NtlDisqualifiedReason {
  DTI = 'Debt-to-income ratio is too high',
  EMPLOYMENT = 'Has disqualifying employment',
  HAS_FORBEARANCE = 'Has forbearance',
  INCORRECT_QUESTIONNAIRE_TYPE = 'Questionnaire type must be "NTL"',
  INELIGIBLE_LO = 'LO not eligible for NTL',
  MORTGAGE_DEBT = 'Mortgage debt failure',
  NO_APPLICATION = 'Application/revision not found',
  NO_PREQUAL = 'No prequal scores present for application',
  NO_TRIMERGE = 'No tri-merge scores present for application',
  NOT_BANKRATE = 'Must have a rate selected from Bankrate',
  TEXAS_CASHOUT = 'Texas cashout loan not allowed',
  UNSURE_FEES_ESCROW = 'Borrower not sure whether to roll in fees and/ or escrow',
  LOCK_DESK_CLOSED = 'The lock-desk is currently closed',
  NO_RATES = 'No rates found',
  PURCHASE = 'Purchase leads not eligible',
  SPLIT_FLOW_MULTIPLE_SESSIONS = `Split flow wasn't completed in a single session`,
  TOO_OLD = 'Application is older than accepted time limit',
}

export type NtlSoftQualStatus = {
  isSoftQualified: boolean
  reason?: string
}
