import isEqual from 'lodash/isEqual'
import { StoreModel } from '@mortgage-pos/ui/store'
import { thunk, Thunk } from 'easy-peasy'
import incense from '@mortgage-pos/ui/incense'
import { ApplicationModel } from '@mortgage-pos/ui/store/application'
import ApplicationClient from '@mortgage-pos/ui/services/ApplicationClient'
import { checkIsReoEmpty } from '@mortgage-pos/ui/utils/customPageValidations'
import { buildPreviousAddresses } from '@mortgage-pos/ui/utils/questionHelpers'
import { DisclosureInformation } from '@mortgage-pos/types'

import {
  AddressType,
  DisclosureType,
  LoanPurpose,
  PropertyType,
} from '@mortgage-pos/types'

import {
  attachRecordIds,
  addYearsInProfession,
  removeUnwantedAnswers,
  removeUnwantedCoBorrowerAnswers,
  payloadHasCoBorrowerInfo,
  extractPrimaryBorrowerAddress,
} from '@mortgage-pos/ui/utils/applicationHelpers'

type Save = () => Thunk<ApplicationModel, any, null, StoreModel>

export const save: Save = () =>
  thunk(
    async (
      { setCoBorrowerId },
      payload,
      { getState, getStoreState, getStoreActions }
    ) => {
      let fillPayload
      const { applicationId, coBorrowerId } = getState()
      const { isSplitFlow, pages, active } = getStoreState().questionnaire
      const { isLeComparisonApp } = getStoreState().leComparison
      const { mergedAnswers: answers, maxCashoutValue } =
        getStoreState().answers
      const { shouldFetchAvm } = getStoreState().propertyInfo
      const { getAVMData } = getStoreActions().propertyInfo
      const { setAnswers } = getStoreActions().answers

      let stepAnswers = { ...payload }

      try {
        stepAnswers = handleCashOut({
          stepAnswers,
          maxCashoutValue,
          setAnswers,
        })

        handleRefinance({ answers, setAnswers })

        const newCoBorrowerId = await handleCoBorrowerInfo({
          stepAnswers,
          setCoBorrowerId,
          applicationId,
          coBorrowerId,
        })

        await handleCoborrowerIncome({
          stepAnswers,
          applicationId,
          coBorrowerId,
          setAnswers,
        })

        await handleCoBorrowerOtherIncome({
          stepAnswers,
          applicationId,
          setAnswers,
          coBorrowerId,
        })

        await handleCoBorrowerAddress({
          stepAnswers,
          answers,
          coBorrowerId,
          newCoBorrowerId,
          setAnswers,
          applicationId,
        })

        await handleCoBorrowerDemographics({
          stepAnswers,
          coBorrowerId,
          newCoBorrowerId,
          setAnswers,
          applicationId,
        })

        await handleCoBorrowerOtherLiabilities({
          stepAnswers,
          coBorrowerId,
          newCoBorrowerId,
          applicationId,
        })

        await handleRealEstateOwned({
          answers,
          setAnswers,
          stepAnswers,
          applicationId,
        })

        await handleIncomes({
          stepAnswers,
          applicationId,
          setAnswers,
        })

        await handleOtherIncomes({
          stepAnswers,
          applicationId,
          setAnswers,
        })

        await handleAddresses({
          stepAnswers,
          answers,
          setAnswers,
          applicationId,
          shouldFetchAvm,
          getAVMData,
          isLeComparisonApp,
          isSplitFlow,
        })

        await handleDemographics({
          stepAnswers,
          setAnswers,
          applicationId,
        })

        await handleOtherLiabilities({
          stepAnswers,
          applicationId,
        })

        await handleDisclosures({
          stepAnswers,
          active,
        })

        await handleCoBorrowerDisclosures({
          stepAnswers,
          active,
        })

        const conditionalAnswers = stepAnswers.autoCompleteAddress
          ? {
              ...stepAnswers,
              street: answers.street,
              apt: answers.apt,
              city: answers.city,
              state: answers.state,
              zipCode: answers.zipCode,
            }
          : { ...stepAnswers }

        fillPayload = { ...conditionalAnswers }
        fillPayload = { ...removeUnwantedAnswers(fillPayload) }

        await ApplicationClient.fill(applicationId, fillPayload)
      } catch (e) {
        incense(e)
          .details({
            name: 'SaveThunkError',
            message: 'Failed to save and fill application answers',
          })
          .safe({ isLeComparisonApp })
          .sensitive({ mergedAnswers: answers, stepAnswers, fillPayload })
          .error()
      }
    }
  )

const handleCashOut = ({ stepAnswers, maxCashoutValue, setAnswers }) => {
  if (stepAnswers.isTakingCashOut === 'No') {
    stepAnswers = { ...stepAnswers, cashOut: 0 }
    setAnswers(stepAnswers)
  }
  if (stepAnswers.cashOutOptions === 'Max') {
    stepAnswers = {
      ...stepAnswers,
      cashOut: maxCashoutValue,
      isTakingCashOut: 'Yes',
    }
    setAnswers(stepAnswers)
  }
  if (stepAnswers.cashOutOptions === 'OtherValue') {
    stepAnswers = {
      ...stepAnswers,
      isTakingCashOut: 'Yes',
    }
    setAnswers(stepAnswers)
  }
  if (stepAnswers.cashOutOptions === 'None') {
    stepAnswers = {
      ...stepAnswers,
      cashOut: 0,
      isTakingCashOut: 'No',
    }
    setAnswers(stepAnswers)
  }
  return stepAnswers
}

const disclosureQuestionNameMap = {
  affiliatedBusinessArrangementDisclosure: [
    DisclosureType.AffiliatedBusinessArrangement,
  ],
  termsAndCreditAuthorization: [DisclosureType.CreditAuthorization],
  termsOfUsePrivacyPolicyAndElectronicConsentAgreement: [
    DisclosureType.ElectronicConsent,
    DisclosureType.PrivacyPolicy,
    DisclosureType.TermsOfUse,
  ],
  tcpaDisclosure: [DisclosureType.Tcpa],
  coBorrowerTcpaDisclosure: [DisclosureType.Tcpa],
  coBorrowerSoftCreditConsentDisclosure: [DisclosureType.SoftCreditConsent],
  coBorrowerTermsOfUsePrivacyPolicyAndElectronicConsentAgreement: [
    DisclosureType.ElectronicConsent,
    DisclosureType.PrivacyPolicy,
    DisclosureType.TermsOfUse,
  ],
  softCreditConsentDisclosure: [DisclosureType.SoftCreditConsent],
  bankrateProAgentSharingConsent: [
    DisclosureType.BankrateProAgentSharingConsent,
  ],
}

function extractDisclosureInfo({
  stepAnswers,
  active,
}): DisclosureInformation[] {
  const disclosuresInfo: DisclosureInformation[] = []

  Object.keys(disclosureQuestionNameMap).forEach((disclosureQuestionName) => {
    if (stepAnswers[disclosureQuestionName]) {
      // Finds question, gets content, strips any html tags
      const disclosureText = active.questions
        .find(
          (question) => question.questionName.name === disclosureQuestionName
        )
        ?.content?.replace(/(<([^>]+)>)/gi, '')

      disclosureQuestionNameMap[disclosureQuestionName].forEach(
        (disclosureType: DisclosureType) => {
          disclosuresInfo.push({
            disclosureType,
            disclosureText,
          } as DisclosureInformation)
        }
      )
    }
  })

  return disclosuresInfo
}

const handleDisclosures = async ({ stepAnswers, active }) => {
  if (!active || payloadHasCoBorrowerInfo(stepAnswers)) return

  const disclosuresInfo = extractDisclosureInfo({ stepAnswers, active })

  try {
    if (disclosuresInfo?.length) {
      await ApplicationClient.fillDisclosures(disclosuresInfo)
      return
    }
  } catch (error) {
    incense(error)
      .details({
        name: 'handleDisclosures',
        message: 'Failed calling ApplicationClient fillDisclosures',
      })
      .sensitive({ stepAnswers, disclosuresInfo })
      .error()
  }
  return
}

const handleCoBorrowerDisclosures = async ({ stepAnswers, active }) => {
  if (!active || !payloadHasCoBorrowerInfo(stepAnswers)) return

  const disclosuresInfo = extractDisclosureInfo({ stepAnswers, active })

  try {
    if (disclosuresInfo?.length) {
      await ApplicationClient.fillCoBorrowerDisclosures(disclosuresInfo)
      return
    }
  } catch (error) {
    incense(error)
      .details({
        name: 'handleDisclosures',
        message: 'Failed calling ApplicationClient fillDisclosures',
      })
      .sensitive({ stepAnswers, disclosuresInfo })
      .error()
  }
  return
}

const handleRefinance = ({ answers, setAnswers }) => {
  if (answers.loanPurpose === LoanPurpose.Refinance) {
    const {
      street,
      apt,
      city,
      state,
      zipCode,
      propertyOwners,
      reoPropertyOwners: reoPropOwners,
    } = answers

    // If subject property is in REO array, use the propertyOwners field below
    const subjectProperty = answers?.realEstateOwned?.find(
      (reo) => reo.street === street
    )

    // Remove the refi subject property so that it's not duplicated on the
    // additional REO page
    const filteredReo = answers?.realEstateOwned?.filter(
      (reo) => reo.street !== street
    )

    // If reoPropertyOwners has been set already, use that, otherwise use
    // the subject property or answers value
    const reoPropertyOwners = reoPropOwners
      ? reoPropOwners
      : subjectProperty?.propertyOwners
      ? subjectProperty.propertyOwners
      : propertyOwners

    setAnswers({
      reoStreet: street,
      reoApt: apt,
      reoCity: city,
      reoState: state,
      reoZip: zipCode,
      reoPropertyOwners,
      realEstateOwned: filteredReo,
    })
  }
}

const handleCoBorrowerInfo = async ({
  stepAnswers,
  setCoBorrowerId,
  applicationId,
  coBorrowerId,
}) => {
  if (payloadHasCoBorrowerInfo(stepAnswers)) {
    stepAnswers = {
      ...removeUnwantedCoBorrowerAnswers(stepAnswers),
    }
    const newCoBorrowerId = await ApplicationClient.fillCoBorrower(
      applicationId,
      coBorrowerId,
      stepAnswers
    )

    if (newCoBorrowerId) {
      setCoBorrowerId(newCoBorrowerId)
    }
    return newCoBorrowerId
  }
}

const handleCoborrowerIncome = async ({
  stepAnswers,
  applicationId,
  coBorrowerId,
  setAnswers,
}) => {
  if (stepAnswers.coBorrowerIncomeSources) {
    const coBorrowerIncomeSources = addYearsInProfession(
      stepAnswers.coBorrowerIncomeSources
    )

    const coBorrowerIncomeIds = await ApplicationClient.fillCoBorrowerIncome(
      applicationId,
      coBorrowerId,
      coBorrowerIncomeSources
    )

    stepAnswers.coBorrowerIncomeSources = attachRecordIds(
      coBorrowerIncomeIds,
      stepAnswers.coBorrowerIncomeSources
    )

    setAnswers(stepAnswers)
  }
}

const handleCoBorrowerOtherIncome = async ({
  stepAnswers,
  applicationId,
  coBorrowerId,
  setAnswers,
}) => {
  if (stepAnswers.coBorrowerOtherIncomes) {
    const coBorrowerIncomeIds = await ApplicationClient.fillCoBorrowerIncome(
      applicationId,
      coBorrowerId,
      stepAnswers.coBorrowerOtherIncomes
    )

    stepAnswers.coBorrowerOtherIncomes = attachRecordIds(
      coBorrowerIncomeIds,
      stepAnswers.coBorrowerOtherIncomes
    )

    setAnswers(stepAnswers)
  }
}

const handleCoBorrowerAddress = async ({
  stepAnswers,
  answers,
  coBorrowerId,
  newCoBorrowerId,
  setAnswers,
  applicationId,
}) => {
  if (
    stepAnswers.doesCoBorrowerHaveSameAddress ||
    stepAnswers.coBorrowerAddresses ||
    stepAnswers?.coBorrowerMoveInDate
  ) {
    let coBorrowerAddresses = stepAnswers.coBorrowerAddresses || []

    // If co-borrower has same address as primary, make sure answers reflect that
    if (answers?.doesCoBorrowerHaveSameAddress === 'Yes') {
      const currentAddress = extractPrimaryBorrowerAddress(answers)

      currentAddress.moveInDate = stepAnswers?.coBorrowerMoveInDate

      if (Array.isArray(coBorrowerAddresses)) {
        const addressIsNotSaved = coBorrowerAddresses.every(
          (address) => !isEqual(address, currentAddress)
        )

        if (addressIsNotSaved) {
          coBorrowerAddresses.push(currentAddress)
        }
      } else {
        coBorrowerAddresses = [currentAddress]
      }
    }

    const coBorrowerAddressIds =
      await ApplicationClient.fillCoBorrowerAddresses(
        applicationId,
        coBorrowerId || newCoBorrowerId,
        coBorrowerAddresses
      )

    stepAnswers.coBorrowerAddresses = attachRecordIds(
      coBorrowerAddressIds,
      coBorrowerAddresses
    )

    setAnswers(stepAnswers)
  }
}

const handleCoBorrowerDemographics = async ({
  stepAnswers,
  applicationId,
  coBorrowerId,
  newCoBorrowerId,
  setAnswers,
}) => {
  if (stepAnswers.coBorrowerDemographics) {
    // TEMP: Remove else block once new demographics questions are live
    if (Array.isArray(stepAnswers.coBorrowerDemographics)) {
      const [coBorrowerDemographics] = stepAnswers.coBorrowerDemographics

      const coBorrowerDemographicRecordId =
        await ApplicationClient.fillCoBorrowerDemographics(
          applicationId,
          coBorrowerId ?? newCoBorrowerId,
          coBorrowerDemographics
        )

      stepAnswers.coBorrowerDemographics = [
        {
          id: coBorrowerDemographicRecordId,
          ...coBorrowerDemographics,
        },
      ]
    } else {
      const coBorrowerDemographicRecordId =
        await ApplicationClient.fillCoBorrowerDemographics(
          applicationId,
          coBorrowerId ?? newCoBorrowerId,
          stepAnswers.coBorrowerDemographics
        )

      stepAnswers.coBorrowerDemographics = {
        id: coBorrowerDemographicRecordId,
        ...stepAnswers.coBorrowerDemographics,
      }
    }

    setAnswers(stepAnswers)
  }
}

const handleCoBorrowerOtherLiabilities = async ({
  stepAnswers,
  applicationId,
  coBorrowerId,
  newCoBorrowerId,
}) => {
  if (stepAnswers.coBorrowerOtherLiabilitiesGroup) {
    const liabilityIds = await ApplicationClient.fillCoBorrowerLiabilities(
      applicationId,
      coBorrowerId ?? newCoBorrowerId,
      stepAnswers.coBorrowerOtherLiabilitiesGroup
    )

    stepAnswers.coBorrowerOtherLiabilitiesGroup = attachRecordIds(
      liabilityIds,
      stepAnswers.coBorrowerOtherLiabilitiesGroup
    )
  }
}

const handleRealEstateOwned = async ({
  stepAnswers,
  applicationId,
  answers,
  setAnswers,
}) => {
  if (answers.realEstateOwned?.length > 0) {
    // If there's REO array in answers and no reoStreet, it's a purchase loan returning
    // user and we need to set the first REO to the reoStreet reoApt etc questions and
    // remove it from the array
    if (!answers.reoStreet) {
      const { street, apt, city, state, zipCode, propertyOwners } =
        answers.realEstateOwned.splice(0, 1)[0]

      setAnswers({
        reoStreet: street,
        reoApt: apt,
        reoCity: city,
        reoState: state,
        reoZip: zipCode,
        reoPropertyOwners: propertyOwners,
        realEstateOwned: answers.realEstateOwned,
      })
    }

    // If there's REO left in the array, the user has more than 1 owned property
    // setting yes for the binary button for return users/back users.
    if (answers.realEstateOwned.length > 0) {
      setAnswers({
        additionalREO: 'Yes',
      })
    }
  }

  if (stepAnswers.realEstateOwned || stepAnswers.reoStreet) {
    // Pass in existing record ids so we can delete them
    const { realEstateOwnedIds: reoIds } = answers
    const { reoStreet, reoApt, reoCity, reoState, reoZip, reoPropertyOwners } =
      stepAnswers // This is the primary refi property or first REO for purchase

    const firstReoProperty = {
      street: reoStreet,
      apt: reoApt,
      city: reoCity,
      state: reoState,
      zipCode: reoZip,
      propertyOwners: reoPropertyOwners,
      numberOfUnits: 1,
    }

    // Adjust numberOfUnits to refi property if multi family
    if (answers.loanPurpose === LoanPurpose.Refinance) {
      const numberOfUnits = () => {
        switch (answers.propertyType) {
          case PropertyType.MultiFamily2Units:
            return 2
          case PropertyType.MultiFamily3Units:
            return 3
          case PropertyType.MultiFamily4Units:
            return 4
          default:
            return 1
        }
      }

      firstReoProperty.numberOfUnits = numberOfUnits()
    }

    // When making fill call, we want to include the first REO property
    // which is split out from the answers.realEstateOwned array
    const reoToFill = [
      ...(!checkIsReoEmpty(firstReoProperty) ? [firstReoProperty] : []),
      ...(stepAnswers.realEstateOwned
        ? stepAnswers.realEstateOwned.filter((reo) => !checkIsReoEmpty(reo))
        : []),
    ]

    const { realEstateOwnedIds } = await ApplicationClient.fillReo({
      applicationId,
      realEstateOwned: reoToFill,
      realEstateOwnedIds: reoIds,
    })

    // We do not want to set the first REO in the array here because this causes duplicates
    // to be shown to return users and back button users.
    setAnswers({
      realEstateOwned: stepAnswers.realEstateOwned,
      realEstateOwnedIds,
    })
  }
}

const handleIncomes = async ({ stepAnswers, applicationId, setAnswers }) => {
  if (stepAnswers.incomeSources) {
    const incomeSources = addYearsInProfession(stepAnswers.incomeSources)

    const incomeSourceIds = await ApplicationClient.fillIncome(
      applicationId,
      incomeSources
    )

    stepAnswers.incomeSources = attachRecordIds(
      incomeSourceIds,
      stepAnswers.incomeSources
    )

    setAnswers(stepAnswers)
  }
}

const handleOtherIncomes = async ({
  stepAnswers,
  applicationId,
  setAnswers,
}) => {
  if (stepAnswers.otherIncomes) {
    const incomeSourceIds = await ApplicationClient.fillIncome(
      applicationId,
      stepAnswers.otherIncomes
    )

    stepAnswers.otherIncomes = attachRecordIds(
      incomeSourceIds,
      stepAnswers.otherIncomes
    )

    setAnswers(stepAnswers)
  }
}

// we're mutating the object in the thunk, which is an anti pattern.
const handleAddresses = async ({
  stepAnswers,
  answers,
  setAnswers,
  applicationId,
  shouldFetchAvm,
  getAVMData,
  isLeComparisonApp,
  isSplitFlow,
}) => {
  const shouldSaveCurrent = shouldSaveCurrentAddress({
    stepAnswers,
    isSplitFlow,
    answers,
  })

  const shouldUpdateCurrent = shouldUpdateCurrentAddress({
    stepAnswers,
    answers,
  })

  if (shouldUpdateCurrent || shouldSaveCurrent) {
    const recordToUpdate = answers?.addresses?.find(
      ({ type }) => type === AddressType.Current
    )

    const currentAddress = {
      apt: answers.apt,
      city: answers.city,
      id: shouldUpdateCurrent && recordToUpdate ? recordToUpdate.id : null,
      moveInDate: stepAnswers?.moveInDate,
      state: answers.state,
      street: answers.street,
      type: 'current',
      zipCode: answers.zipCode,
    }

    if (Array.isArray(stepAnswers.addresses)) {
      const addressIsNotSaved = stepAnswers.addresses.every(
        (address) => !isEqual(address, currentAddress)
      )

      if (addressIsNotSaved) {
        stepAnswers.addresses.unshift(currentAddress)
      }
    } else {
      stepAnswers.addresses = [currentAddress]
    }

    setAnswers(stepAnswers)
  }

  const containsAddressDetails = stepAnswers?.street?.length > 0
  const addressAutoComplete = stepAnswers?.autoCompleteAddressFields?.length > 0

  // LE Compare flow specific logic here to run AVM again if (1) the OCR
  // address parsing failed OR (2) the applicant entered their address manually
  if (
    !stepAnswers.addresses &&
    (addressAutoComplete || containsAddressDetails) &&
    isLeComparisonApp &&
    shouldFetchAvm
  ) {
    await getAVMData()
  }

  if (stepAnswers.addresses) {
    // add current address to previous addresses
    const currentAddress = stepAnswers?.addresses?.find(
      ({ type }) => type === AddressType.Current
    )

    const previousAddresses = buildPreviousAddresses({
      stepAnswers,
    })

    const queryAddresesses = [currentAddress, ...previousAddresses].filter(
      Boolean
    )
    const addressIds = await ApplicationClient.fillAddress(applicationId, {
      addresses: queryAddresesses,
    })

    stepAnswers.addresses = attachRecordIds(addressIds, queryAddresesses)

    setAnswers(stepAnswers)

    // we call AVM in a LoaderWithAPICalls page for split flows
    if (shouldFetchAvm && !isSplitFlow) {
      await getAVMData()
    }
  }
}

const shouldUpdateCurrentAddress = ({ answers, stepAnswers }): boolean => {
  if (answers?.moveInDate === undefined && stepAnswers?.moveInDate) return true
  return false
}

const shouldSaveCurrentAddress = ({
  stepAnswers,
  isSplitFlow,
  answers,
}): boolean => {
  const isCurrentAddressPresent: boolean =
    stepAnswers?.isCurrentAddress === 'Yes'
  const isMoveInDatePresent: boolean =
    stepAnswers?.moveInDate ||
    stepAnswers.hasOwnProperty.call(
      stepAnswers,
      'hasResidedCurrentAddressTwoYears'
    )

  if (isCurrentAddressPresent && isMoveInDatePresent) {
    return true
  }

  // split before flows don't have the above checks for the address for Refi, but they do have a street address!
  const isAddressFieldPresent = Boolean(stepAnswers?.street)

  if (
    isSplitFlow &&
    stepAnswers.isCurrentAddress === 'Yes' &&
    answers.loanPurpose !== LoanPurpose.Purchase &&
    isAddressFieldPresent
  ) {
    return true
  }

  // else return false
  return false
}

const handleDemographics = async ({
  stepAnswers,
  applicationId,
  setAnswers,
}) => {
  if (stepAnswers.demographics) {
    // TEMP: Remove else block once new demographics questions are live
    if (Array.isArray(stepAnswers.demographics)) {
      const [demographics] = stepAnswers.demographics
      const demographicRecordId = await ApplicationClient.fillDemographics(
        applicationId,
        demographics
      )

      stepAnswers.demographics = [
        {
          id: demographicRecordId,
          ...demographics,
        },
      ]
    } else {
      const demographicRecordId = await ApplicationClient.fillDemographics(
        applicationId,
        stepAnswers.demographics
      )

      stepAnswers.demographics = {
        id: demographicRecordId,
        ...stepAnswers.demographics,
      }
    }

    setAnswers(stepAnswers)
  }
}

const handleOtherLiabilities = async ({ stepAnswers, applicationId }) => {
  if (stepAnswers.otherLiabilitiesGroup) {
    const liabilityIds = await ApplicationClient.fillLiabilities(
      applicationId,
      stepAnswers.otherLiabilitiesGroup
    )

    stepAnswers.otherLiabilitiesGroup = attachRecordIds(
      liabilityIds,
      stepAnswers.otherLiabilitiesGroup
    )
  }
}
