import { Status } from '@mortgage-pos/types'

export const invalidApplicationStatuses = [
  Status.LenderRejected,
  Status.LenderWithdrawn,
  Status.LenderApplicationApprovedNotAccepted,
  Status.LenderDenied,
  Status.LenderFileClosedForIncompleteness,
  Status.LenderAdverse,
  Status.LenderLoanDisbursedByLender,
  Status.LenderFunded,
  Status.LenderFundingComplete,
  Status.LenderLoanRescinded,
  Status.LenderPostClosing,
  Status.LenderCollateralReview,
  Status.LenderDiligenceReview,
  Status.LenderClosedPackageReceivedFromTitle,
]
