import { gql } from 'apollo-boost'
import { getGraphQLString } from '@mortgage-pos/utils'
import { StoreModel } from './index'
import { Action, action, computed, Computed, thunk, Thunk } from 'easy-peasy'
import { graphQL, graphQLSage } from '@mortgage-pos/ui/services/http'
import {
  RatesRequest,
  LoanPurpose,
  BankrateRate,
  Rate,
} from '@mortgage-pos/types'
import incense from '@mortgage-pos/ui/incense'
import newRelic from '@mortgage-pos/ui/services/newRelic'
import { MortgageEstimate } from '@mortgage-pos/types'
import {
  filterAndPositionOffers,
  determineLoanTerm,
} from '@mortgage-pos/ui/utils/BankrateCompare'

interface SageLead {
  rate: BankrateRate
  cashout?: number
}

export interface BankrateCompareModel {
  bankrateRates: BankrateRate[]
  sortedBankrateRates: Computed<
    BankrateCompareModel,
    BankrateRate[],
    StoreModel
  >
  currentMortgageDetails: Computed<
    BankrateCompareModel,
    MortgageEstimate,
    StoreModel
  >
  equityInHome: Computed<BankrateCompareModel, number, StoreModel>
  selectedBankrateRate: BankrateRate
  setBankrateRates: Action<BankrateCompareModel, BankrateRate[]>
  setSelectedBankrateRate: Action<BankrateCompareModel, BankrateRate>
  requestBankrateRates: Thunk<
    BankrateCompareModel,
    number | undefined,
    object,
    StoreModel
  >
  submitSageLead: Thunk<BankrateCompareModel, SageLead, null, StoreModel>
  isRequestingRates: boolean
  mortgageRateEstimate: MortgageEstimate
  setIsRequestingRates: Action<BankrateCompareModel, boolean>
  setMortgageRateEstimate: Action<BankrateCompareModel, MortgageEstimate>
}

const bankrateCompare = (): BankrateCompareModel => {
  return {
    bankrateRates: [],

    selectedBankrateRate: null,

    isRequestingRates: false,

    mortgageRateEstimate: null,

    setBankrateRates: action((state, payload) => {
      state.bankrateRates = payload
    }),

    setMortgageRateEstimate: action((state, payload) => {
      state.mortgageRateEstimate = payload
    }),

    sortedBankrateRates: computed(
      [
        (_, storeState) => storeState.answers.mergedAnswers,
        (_, storeState) => storeState.bankrateCompare.bankrateRates,
        (_, storeState) => storeState.bankrateCompare.currentMortgageDetails,
      ],
      (mergedAnswers, bankrateRates, currentMortgageDetails) => {
        const filteredOffers = new filterAndPositionOffers(
          mergedAnswers?.refiReason,
          bankrateRates,
          currentMortgageDetails
        )

        return filteredOffers.getDisplayOffers()
      }
    ),

    currentMortgageDetails: computed(
      [
        (_, storeState) => storeState.answers.mergedAnswers,
        (_, storeState) => storeState.bankrateCompare.mortgageRateEstimate,
      ],
      (mergedAnswers, mortgageRateEstimate) => {
        const currentMortgageDetails: MortgageEstimate = {
          paymentDueAmount:
            mergedAnswers.leRate?.[0]?.principleAndInterestPayment ||
            mergedAnswers?.monthlyPayment ||
            mortgageRateEstimate?.paymentDueAmount,
          estimatedInterestRate:
            mergedAnswers.leRate?.[0]?.rate ||
            mortgageRateEstimate?.estimatedInterestRate,
          openedDate:
            mergedAnswers.currentLoanStartDate ||
            mortgageRateEstimate?.openedDate,
          paymentScheduleMonthCount:
            parseInt(mergedAnswers.leRate?.[0]?.term?.substring(1)) * 12 ||
            mergedAnswers.currentLoanTerm * 12 ||
            mortgageRateEstimate?.paymentScheduleMonthCount,
          currentBalance:
            mergedAnswers.remainingBalance ||
            mortgageRateEstimate?.currentBalance,
        }

        return currentMortgageDetails
      }
    ),

    equityInHome: computed(
      [
        (_, storeState) => storeState.answers.mergedAnswers,
        (_, storeState) => storeState.bankrateCompare.mortgageRateEstimate,
      ],
      (mergedAnswers, mortgageRateEstimate) => {
        const remainingBalance =
          mergedAnswers.remainingBalance || mortgageRateEstimate?.currentBalance

        const maxCashoutValue = mergedAnswers.estimatedValue * 0.79
        const equityInHome =
          Math.floor((maxCashoutValue - remainingBalance) / 1000) * 1000

        return equityInHome
      }
    ),

    setSelectedBankrateRate: action((state, payload) => {
      state.selectedBankrateRate = payload
    }),

    setIsRequestingRates: action((state, payload) => {
      state.isRequestingRates = payload
    }),

    requestBankrateRates: thunk(
      async (
        { setIsRequestingRates, setBankrateRates },
        cashOut,
        { getState, getStoreState }
      ) => {
        const { applicationId } = getStoreState().application
        const { mergedAnswers } = getStoreState().answers
        const { currentMortgageDetails } = getState()

        let answers = mergedAnswers
        if (cashOut) {
          answers = { ...answers, cashOut }
        }
        setIsRequestingRates(true)
        let resp

        try {
          resp = await getBankrateRates(
            answers,
            applicationId,
            currentMortgageDetails
          )
        } catch (error) {
          setIsRequestingRates(false)

          incense(error)
            .details({
              name: 'BANKRATECOMPARE request rates failure',
              message: 'Failed to request rates',
            })
            .sensitive({
              mergedAnswers,
              currentMortgageDetails,
            })
            .rethrow()
        }

        if (resp.data.errors && resp.data.errors.length) {
          setIsRequestingRates(false)

          incense()
            .details({
              name: 'BANKRATECOMPARE request rates GQL failure',
              message: 'Failed to request rates via GraphQL',
            })
            .sensitive({
              mergedAnswers,
              currentMortgageDetails,
              errors: resp.data.errors,
            })
            .rethrow()
        }

        const rates = resp.data.data.getBankrateRates
        if (rates?.length) {
          newRelic.increment('br_compare_rates_request.sucesss')
        } else {
          newRelic.increment('br_compare_rates_request.failure')
        }

        setBankrateRates(rates)
        setIsRequestingRates(false)
        return rates
      }
    ),

    submitSageLead: thunk(async (_, payload, { getStoreState }) => {
      const { answers } = getStoreState().answers
      const { applicationGuid } = getStoreState().application

      const leadInfo = {
        sourceLeadId: applicationGuid || 'testid',
        leadSource: 'BankrateAlfie',
        firstName: answers.firstName,
        lastName: answers.lastName,
        street: answers.street,
        apt: answers.apt,
        city: answers.city,
        state: answers.state,
        zipCode: answers.zipCode,
        propertyUse: answers.propertyUse,
        propertyType: answers.propertyType,
        loanPurpose: answers.loanPurpose,
        estimatedValue: answers.estimatedValue,
        remainingBalance: answers.remainingBalance,
        propertyPurchasePrice: answers.propertyPurchasePrice,
        propertyDownPayment: answers.propertyDownPayment,
        cashOut: answers.cashOut ?? payload?.cashout,
        rate: payload?.rate
          ? convertBankrateRateToSageRate(payload.rate)
          : undefined,
      }

      let response
      try {
        response = await graphQLSage({
          query: submitBRCompareLeadMutation,
          variables: { leadInfo },
        })
      } catch (error) {
        incense(error)
          .details({
            name: 'BANKRATECOMPARE submitBRCompareLeadFailure',
            message: 'Error submitting new lead',
          })
          .sensitive({
            leadInfo,
          })
          .rethrow()
      }

      return response.data.data.submitBRCompareLead.applicationGuid
    }),
  }
}

export default bankrateCompare

const convertBankrateRateToSageRate = (rate: BankrateRate): Rate => {
  return {
    apr: rate?.apr,
    lenderName: rate.bankrateAdvertiser?.name,
    discountPoints: rate?.discountPoints,
    principleAndInterestPayment: rate?.principleAndInterestPayment,
    rate: rate?.rate,
    term: rate?.term,
  }
}

const getBankrateRates = async (
  answers,
  applicationId,
  currentMortgageDetails
) => {
  const loanTerm = determineLoanTerm(answers.refiReason, currentMortgageDetails)

  const request: RatesRequest = {
    loanPurpose: answers.loanPurpose ?? LoanPurpose.Refinance,
    loanAmount:
      answers.remainingBalance ?? currentMortgageDetails.currentBalance,
    propertyValue: answers.estimatedValue,
    propertyType: answers.propertyType,
    propertyUse: answers.propertyUse,
    zipCode: answers.zipCode,
    applicationId: applicationId,
    loanTerm: loanTerm,
    creditScore: answers.creditScore || 740,
    cashOut: answers.cashOut,
  }

  return graphQL({
    query: getBankrateRatesQuery,
    variables: { request },
  })
}

export const getBankrateRatesQuery = getGraphQLString(gql`
  query getBankrateRates($request: RatesRequest) {
    getBankrateRates(request: $request) {
      apr
      rate
      closingCost
      term
      costOfLoan8Years
      principleAndInterestPayment
      discountPoints
      trackingLink
      bankrateAdvertiser {
        id
        name
        nmlsLicense
        logo {
          small
          medium
        }
        specials
      }
    }
  }
`)

export const submitBRCompareLeadMutation = getGraphQLString(gql`
  mutation submitBRCompareLead($leadInfo: BankrateCompareLeadInformation) {
    submitBRCompareLead(leadInfo: $leadInfo) {
      applicationGuid
      success
    }
  }
`)
