export * from './PropertyDetails'

export enum BorrowerClassification {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

export enum CreditReportType {
  XML = 'xml',
  Score = 'score',
  Trimerge = 'trimerge',
  Full = 'full',
  Prequal = 'prequal',
}

export enum CreditReportRequestType {
  Joint = 'joint',
  Individual = 'individual',
}

export enum CreditLoanType {
  Agriculture = 'Agriculture',
  Airplane = 'Airplane',
  ApplianceOrFurniture = 'ApplianceOrFurniture',
  AttorneyFees = 'AttorneyFees',
  AutoLease = 'AutoLease',
  AutoLoanEquityTransfer = 'AutoLoanEquityTransfer',
  Automobile = 'Automobile',
  AutoRefinance = 'AutoRefinance',
  BiMonthlyMortgageTermsInYears = 'BiMonthlyMortgageTermsInYears',
  Boat = 'Boat',
  Business = 'Business',
  BusinessCreditCard = 'BusinessCreditCard',
  Camper = 'Camper',
  ChargeAccount = 'ChargeAccount',
  CheckCreditOrLineOfCredit = 'CheckCreditOrLineOfCredit',
  ChildSupport = 'ChildSupport',
  Collection = 'Collection',
  CollectionAttorney = 'CollectionAttorney',
  Comaker = 'Comaker',
  CombinedCreditPlan = 'CombinedCreditPlan',
  CommercialCreditObligation = 'CommercialCreditObligation',
  CommercialLineOfCredit = 'CommercialLineOfCredit',
  CommercialMortgage = 'CommercialMortgage',
  ConditionalSalesContract = 'ConditionalSalesContract',
  ConditionalSalesContractRefinance = 'ConditionalSalesContractRefinance',
  Consolidation = 'Consolidation',
  ConstructionLoan = 'ConstructionLoan',
  ConventionalRealEstateMortgage = 'ConventionalRealEstateMortgage',
  CreditCard = 'CreditCard',
  CreditLineSecured = 'CreditLineSecured',
  DebitCard = 'DebitCard',
  DebtCounselingService = 'DebtCounselingService',
  DeferredStudentLoan = 'DeferredStudentLoan',
  DepositRelated = 'DepositRelated',
  Educational = 'Educational',
  Employment = 'Employment',
  FactoringCompanyAccount = 'FactoringCompanyAccount',
  FamilySupport = 'FamilySupport',
  FarmersHomeAdministrationFHMA = 'FarmersHomeAdministrationFHMA',
  FederalConsolidatedLoan = 'FederalConsolidatedLoan',
  FHAComakerNotBorrower = 'FHAComakerNotBorrower',
  FHAHomeImprovement = 'FHAHomeImprovement',
  FHARealEstateMortgage = 'FHARealEstateMortgage',
  FinanceStatement = 'FinanceStatement',
  GovernmentBenefit = 'GovernmentBenefit',
  GovernmentEmployeeAdvance = 'GovernmentEmployeeAdvance',
  GovernmentFeeForService = 'GovernmentFeeForService',
  GovernmentFine = 'GovernmentFine',
  GovernmentGrant = 'GovernmentGrant',
  GovernmentMiscellaneousDebt = 'GovernmentMiscellaneousDebt',
  GovernmentOverpayment = 'GovernmentOverpayment',
  GovernmentSecuredDirectLoan = 'GovernmentSecuredDirectLoan',
  GovernmentSecuredGuaranteeLoan = 'GovernmentSecuredGuaranteeLoan',
  GovernmentUnsecuredDirectLoan = 'GovernmentUnsecuredDirectLoan',
  GovernmentUnsecuredGuaranteeLoan = 'GovernmentUnsecuredGuaranteeLoan',
  HomeEquity = 'HomeEquity',
  HomeEquityLineOfCredit = 'HomeEquityLineOfCredit',
  HomeImprovement = 'HomeImprovement',
  HouseholdGoods = 'HouseholdGoods',
  HouseholdGoodsAndOtherCollateralAuto = 'HouseholdGoodsAndOtherCollateralAuto',
  HouseholdGoodsSecured = 'HouseholdGoodsSecured',
  InstallmentLoan = 'InstallmentLoan',
  InstallmentSalesContract = 'InstallmentSalesContract',
  InsuranceClaims = 'InsuranceClaims',
  Lease = 'Lease',
  LenderPlacedInsurance = 'LenderPlacedInsurance',
  ManualMortgage = 'ManualMortgage',
  ManufacturedHome = 'ManufacturedHome',
  MedicalDebt = 'MedicalDebt',
  MobileHome = 'MobileHome',
  MobilePhone = 'MobilePhone',
  Mortgage = 'Mortgage',
  NoteLoan = 'NoteLoan',
  NoteLoanWithComaker = 'NoteLoanWithComaker',
  Other = 'Other',
  PartiallySecured = 'PartiallySecured',
  PersonalLoan = 'PersonalLoan',
  RealEstateJuniorLiens = 'RealEstateJuniorLiens',
  RealEstateLoanEquityTransfer = 'RealEstateLoanEquityTransfer',
  RealEstateMortgageWithoutOtherCollateral = 'RealEstateMortgageWithoutOtherCollateral',
  RealEstateSpecificTypeUnknown = 'RealEstateSpecificTypeUnknown',
  Recreational = 'Recreational',
  RecreationalVehicle = 'RecreationalVehicle',
  Refinance = 'Refinance',
  RefundAnticipationLoan = 'RefundAnticipationLoan',
  RentalAgreement = 'RentalAgreement',
  ResidentialLoan = 'ResidentialLoan',
  ReturnedCheck = 'ReturnedCheck',
  RevolvingBusinessLines = 'RevolvingBusinessLines',
  SecondMortgage = 'SecondMortgage',
  Secured = 'Secured',
  SecuredByCosigner = 'SecuredByCosigner',
  SecuredCreditCard = 'SecuredCreditCard',
  SecuredHomeImprovement = 'SecuredHomeImprovement',
  SemiMonthlyMortgagePayment = 'SemiMonthlyMortgagePayment',
  SinglePaymentLoan = 'SinglePaymentLoan',
  SpouseSupport = 'SpouseSupport',
  SummaryOfAccountsWithSameStatus = 'SummaryOfAccountsWithSameStatus',
  TimeSharedLoan = 'TimeSharedLoan',
  Title1Loan = 'Title1Loan',
  UnknownLoanType = 'UnknownLoanType',
  Unsecured = 'Unsecured',
  UtilityCompany = 'UtilityCompany',
  VeteransAdministrationLoan = 'VeteransAdministrationLoan',
  VeteransAdministrationRealEstateMortgage = 'VeteransAdministrationRealEstateMortgage',
}

export enum CreditReportRequestAction {
  Submit = 'Submit',
  Reissue = 'Reissue',
}

export enum CreditScoreSource {
  Equifax = 'Equifax',
  Experian = 'Experian',
  TransUnion = 'TransUnion',
}

export type CoreLogicOAuthResponse = {
  access_token: string
  expires_in: string
  token_type: string
}

export type CreditReportRequest = {
  type: CreditReportType
  use_vgs_proxy?: boolean
  action?: CreditReportRequestAction
  credit_report_identifier?: string
  loan_identifier: number
  borrowers: CreditReportBorrower[]
  forceProduction?: boolean
}

export type CreditReportBorrower = {
  classification_type: BorrowerClassification
  marital_status: string
  first_name: string
  last_name: string
  birth_date: string
  street_address: string
  city: string
  state: string
  country: string
  zip: string
  ssn: string
  suffix?: string
}

export type CreditReportBorrowers = {
  borrowers: CreditReportBorrower[]
  runJointly: boolean
}

export type CreditResponseBorrower = CreditReportBorrower & {
  credit_report_id?: number
  scores?: CreditScoreSources
  liabilities?: any
}

export type CreditReportError = {
  id: number
  application_id: number
  credit_report_id: number
  code: string
  source_type: string
  text: string
}

export interface PropertyInformation {
  address: string
  city: string
  state: string
  zip: string
}

export interface AVMPropertyInformation extends PropertyInformation {
  existingRecordId?: number
}

export type AVMReturnDetails = {
  avmResponse: AVMResponse
  corelogicPropertyId: string
}

export type AVMResponse = {
  corelogicPropertyId: string
  summary: AVMSummary
}

export type AVMSummary = {
  estimatedValue: number
  lowValue: number
  highValue: number
  processedDate: string
  forecastStandardDeviation: number
  confidenceScore: number
}

export type PropertyResponse = {
  data: [PropertyIdResult]
}

export type PropertyIdResult = {
  corelogicPropertyId: string
}

export type CreditScoreSources = {
  [CreditScoreSource.Equifax]: number
  [CreditScoreSource.Experian]: number
  [CreditScoreSource.TransUnion]: number
}

export type CreditTrimergeResponse = {
  scores: {
    [BorrowerClassification.Primary]: CreditScoreSources
    [BorrowerClassification.Secondary]: CreditScoreSources
  }
}
