import { graphQL } from './http'
import { gql } from 'apollo-boost'
import { EventTag } from '@mortgage-pos/types'
import { getGraphQLString } from '@mortgage-pos/utils'
import { globalTags } from '@mortgage-pos/ui/utils/newRelicGlobalTags'

let newRelicEventData = {}

export async function increment(name: string, tags?: EventTag[]) {
  if (process.env.NODE_ENV === 'test') {
    return recordNewRelicEvent('increment', { name, tags })
  }

  return graphQL({
    query: newRelicIncrementMutation,
    variables: {
      name,
      tags: tags ? [...tags, ...globalTags()] : globalTags(),
    },
  })
}

export async function timing(name: string, value: number, tags?: EventTag[]) {
  if (process.env.NODE_ENV === 'test') {
    return recordNewRelicEvent('timing', { name, value, tags })
  }

  return graphQL({
    query: newRelicTimingMutation,
    variables: {
      name,
      value,
      tags: tags ? [...tags, ...globalTags()] : globalTags(),
    },
  })
}

const recordNewRelicEvent = (eventType, payload) => {
  newRelicEventData[eventType] = newRelicEventData[eventType] || []
  newRelicEventData[eventType].push(payload)
}

export const newRelicIncrementMutation = getGraphQLString(gql`
  mutation newRelicIncrement($name: String!, $tags: [EventTag]) {
    newRelicIncrement(name: $name, tags: $tags)
  }
`)

export const newRelicTimingMutation = getGraphQLString(gql`
  mutation newRelicTiming($name: String!, $value: Int!, $tags: [EventTag]) {
    newRelicTiming(name: $name, value: $value, tags: $tags)
  }
`)

export default {
  increment,
  timing,
}

/** Testing utilities */

export function newRelicEvents(key = null) {
  if (key) {
    return newRelicEventData[key]
  }

  return newRelicEventData
}

export function resetNewRelicEvents() {
  newRelicEventData = {}
}
