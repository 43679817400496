import { IncenseError } from './Incense.error'
import { CustomError } from '@mortgage-pos/error-handling'
import { serializeObject } from '@mortgage-pos/utils/error-handling'

import {
  IncenseClient,
  ErrorMessageProps,
  StagedIncenseMetadata,
} from './Incense.base-types'
import { cheapUUID } from '@mortgage-pos/utils'

/**
 * @class Incense - a far less stanky way of error handling
 *
 * @public {IncenseClient} client - error reporting client (such as `Rollbar`)
 * @private {CustomError} errorObject - CustomError capturing details for the `Incense` error
 * @private {StagedMetadata} metadata - metadata staged to be gathered and sent
 */
export class Incense {
  public client: IncenseClient
  public sensitiveLogId: string
  private errorObject: CustomError
  private stagedMetadata: StagedIncenseMetadata

  /**
   * @param {IncenseClient} client - DI for error reporting client (such as `Rollbar`)
   * @param {any} previousError - previously thrown error
   */
  constructor(client: IncenseClient, previousError?: any) {
    this.client = client

    this.stagedMetadata = {
      safe: {},
      sensitive: {},
      error: previousError,
    }
  }

  /**
   * @public details
   *
   * Accepts details to build `Incense`'s internal error object. This uses
   * the `CustomError` constructor internally so params should mirror the
   * constructor params used for that class:
   *
   * @example
   * .details({
   *   name: 'PapaStefError',
   *   message: 'You failed',
   *   statusCode: 561,
   * })
   *
   * @param {ErrorMessageProps} props - props to build a CustomError
   */
  public details({ name, message, statusCode }: ErrorMessageProps) {
    this.errorObject = new CustomError(name, message, statusCode)

    return this
  }

  /**
   * @public safe
   *
   * Accepts any safe (non-PII) metadata details which can be logged in our
   * error reporter for debugging purposes
   *
   * @param {Object} safeData - arbitrary metadata object to send via client
   *
   * @example
   * incense.safe({
   *   applicationId: 'abc123',
   *   questionnaireSlug: 'ntl', // Perfectly safe to send in plain text
   * })
   */
  public safe(safeData: Record<string, any>) {
    this.stagedMetadata.safe = safeData

    return this
  }

  /**
   * @public sensitive
   *
   * Accepts any sensitive or restricted metadata to pass for debugging. This
   * data will be stored in `sensitive` and encrypted before transport
   *
   * @param {Object} sensitiveData - sensitive metadata needing encryption
   *
   * @example
   * // Unsafe data, store as `sensitive` which will be encrypted prior to sending
   * incense.sensitive({ ssn: '333-222-111' })
   */
  public sensitive(sensitiveData: Record<string, any>) {
    this.sensitiveLogId = cheapUUID()
    this.stagedMetadata.sensitive = {
      ...sensitiveData,
      sensitiveUuid: this.sensitiveLogId,
    }

    return this
  }

  /**
   * @public error
   *
   * Final step, this sends the finalized error details to your external
   * reporting client (i.e. `Rollbar`) via `client.error()`
   */
  public error() {
    this.client.error(this.errorObject, this.gatherMetadata())

    return this
  }

  /**
   * @public log
   *
   * Final step, this sends the finalized error details to your external
   * reporting client (i.e. `Rollbar`) via `client.log()`
   */
  public log() {
    this.client.log(this.errorObject, this.gatherMetadata())

    return this
  }

  /**
   * @public warn
   *
   * Final step, this sends the finalized error details to your external
   * reporting client (i.e. `Rollbar`) via `client.warn()`
   */
  public warn() {
    this.client.warn(this.errorObject, this.gatherMetadata())

    return this
  }

  /**
   * @public info
   *
   * Final step, this sends the finalized error details to your external
   * reporting client (i.e. `Rollbar`) via `client.info()`
   */
  public info() {
    this.client.info(this.errorObject, this.gatherMetadata())

    return this
  }

  /**
   * @public debug
   *
   * Final step, this sends the finalized error details to your external
   * reporting client (i.e. `Rollbar`) via `client.debug()`
   */
  public debug() {
    this.client.debug(this.errorObject, this.gatherMetadata())

    return this
  }

  /**
   * @public rethrow
   *
   * If wanting to bubble up the error stack, call this method to rethrow
   * with an `IncenseError` that captures `errorStack` detail
   *
   * @note This method serializes any prototypal `Error` objects to plain JS
   * objects before binding them to the `errorStack`
   */
  public rethrow() {
    const currentError = this.errorObject
    const previousError = this.stagedMetadata.error

    if (!previousError) {
      throw new IncenseError(currentError)
    }

    throw new IncenseError(currentError, previousError)
  }

  /**
   * @private gatherMetadata
   *
   * Compiles correctly formed metadata object to be sent in our final output
   * to the external reporting client
   *
   * @note
   * The shape of the metadata object should look like this:
   *
   * @example
   * {
   *   applicationId: 'abc123',
   *   questionnaireSlug: 'ntl',
   *   sensitive: {
   *     error, // Previously thrown error stored via `.error()`
   *     ssn: '333-222-111',
   *   }
   * }
   */
  private gatherMetadata(): Record<string, any> {
    const sensitive: Record<string, any> = this.stagedMetadata.sensitive

    if (this.stagedMetadata.error) {
      sensitive.error = this.stagedMetadata.error
    }

    return {
      ...this.stagedMetadata.safe,
      ...{ sensitive: serializeObject(sensitive) },
    }
  }
}
